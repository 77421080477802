import Vue from 'vue'
import Vuex from 'vuex'
import { getToken, setToken, setCookie, getCookie, removeToken } from '@/utils/auth.js'

import { loginByCode, login, logout, deleteById } from '@/api/login.js'
import { close, connectWebSocket } from '@/assets/utils/WebIM/webSocket'
import { WEBSOCKET_URL } from '@/api/request'
import router from '@/router'

// 应用vuex插件
Vue.use(Vuex)

// 创建并暴露store
export default new Vuex.Store({
  // 数据,相当于data
  state: {
    token: getToken(),
    userInfo: getCookie('saveUserInfo') == '' || getCookie('saveUserInfo') == 'undefined' || getCookie('saveUserInfo') == undefined ? {} : JSON.parse(getCookie('saveUserInfo')),
    IMlogin: false,
    userInfos: {},
    headBarActive: '1',
    appConfig: [],
    chatShow: false,
  },
  //准备getters——用于将state中的数据进行加工
  getters: {
    // state => state.token,
    // userInfo => state.userInfo,
  },
  //准备mutations——用于操作数据（state）
  mutations: {
    saveToken(state, value) {
      state.token = value
    },
    saveUserInfo(state, value) {
      state.userInfo = value
    },
    setHeadBarActive(state, value) {
      state.headBarActive = value
    },
    setAppConfig(state, value) {
      state.appConfig = value
    },
    setChatShow(state, value) {
      state.chatShow = value
    },
  },
  //准备actions——用于响应组件中的动作
  actions: {
    login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo)
          .then((response) => {
            const { result, code } = response
            if (code == 200) {
              commit('saveToken', result.token)
              commit('saveUserInfo', result.user)
              setToken(result.token)
              setCookie('saveUserInfo', JSON.stringify(result.user))
              close(true)
              connectWebSocket(WEBSOCKET_URL, result.user.id, getToken)
              resolve()
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    loginOut({ commit }) {
      commit('saveToken', '')
      commit('saveUserInfo', 'undefined')
      removeToken()
      setCookie('saveUserInfo', '')
      logout()
      close()
      // router.push('/login/login')
    },
    deleteUser({ commit }) {
      deleteById().then((res) => {
        commit('saveToken', '')
        commit('saveUserInfo', 'undefined')
        removeToken()
        setCookie('saveUserInfo', '')
        close()
        Vue.prototype.message(res.result, 'success')
        router.push('/login/login')
      })
    },
    loginCode({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        loginByCode(userInfo)
          .then((response) => {
            console.log(response)
            const { result, code } = response
            if (code == 200) {
              commit('saveToken', result.token)
              commit('saveUserInfo', result.user)
              setToken(result.token)
              setCookie('saveUserInfo', JSON.stringify(result.user))
              resolve(result.user)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    queryBalance(context) {
      let { queryBalance } = require('@/api/setting')
      queryBalance({}).then((res) => {
        context.state.userInfo.earningsBalance = res.result.earningsBalance
        context.state.userInfo.diamondBalance = res.result.diamondBalance
      })
    },
    getUserInfo(context, id) {
      let userInfo = context.state.userInfos[id]
      return new Promise((resolve, reject) => {
        let result = () => {
          resolve(userInfo)
        }
        if (userInfo) {
          result()
        } else {
          //调用接口查询
          let { queryUserInfoById } = require('@/api/index.js')
          queryUserInfoById({ id: id }).then((res) => {
            userInfo = context.state.userInfos[id] = res
            result()
          })
        }
      })
    },
  },
  modules: {},
})
