<template>
  <div style="background: #f8f8f8; padding: 20px; box-sizing: border-box">
    <div class="content">
      <div class="box1">
        <div class="title">订单详情</div>
        <div @click="back">返回</div>
      </div>
      <div class="box2">
        <div class="right" :class="'stepStatus' + stepStatus">{{ statusText }}</div>
        <div class="border"></div>
        <el-steps :space="300" :active="step" finish-status="success" style="width: 900px" align-center>
          <el-step v-for="(row, i) in stepList" :key="i" :title="row.text" :description="row.val()"></el-step>
        </el-steps>
      </div>
    </div>
    <div class="content1">
      <div class="oneBox">
        <div class="title">订单信息</div>
        <div v-clipboard:copy="orderId" v-clipboard:success="onCopy" class="orderNo">订单编号：{{ orderId }}</div>
        <div v-clipboard:copy="goods.id" v-clipboard:success="onCopy" class="orderNo">商品编号：{{ goods.id }}</div>
      </div>
      <div class="twoBox">
        <div class="imgBox">
          <el-image style="height: 164px; border-radius: 4px; flex-shrink: 0" :src="goods.cover" fit="cover"></el-image>
        </div>
        <div class="orderMsg">
          <div class="msgTop">
            <span class="orderType" :class="{ orderType1: goods.deliveryType == 2 }">{{ goods.deliveryType_dictText }}</span>
            <span class="orderName" @click="gotoDetail">{{ goods.title }}.</span>
          </div>
          <div class="msgLI">游戏区服： {{ goods.districtName }}</div>
          <div class="msgLI">商品类型：{{ goods.commodityTypeName }}</div>
        </div>
        <div class="numbox">
          <span>￥</span>
          {{ goods.price }}
        </div>
      </div>
    </div>
    <div class="content2">
      <div class="top" v-if="orderResult.insureId">
        <div class="title">购买保险套餐</div>
        <div class="bottom" style="font-size: 16px; color: #999999">
          <div>套餐名称</div>
          <div>套餐费</div>
          <div>赔付比例</div>
          <div>套餐说明</div>
        </div>
        <div class="bottom" style="font-size: 16px; color: #1a1a1a">
          <div>{{ insures[choose].title }}</div>
          <div>{{ insures[choose].rate }}%</div>
          <div>{{ insures[choose].paidRate }}%</div>
          <div>{{ insures[choose].content }}</div>
        </div>
      </div>
      <div class="msgList">
        <div class="title">数量</div>
        <div style="display: flex; justify-content: space-between; align-items: end">
          <div class="num">购买数量</div>
          <div class="num1">{{ orderResult.num }}</div>
        </div>
      </div>
      <div class="msgList">
        <div class="title">订单信息</div>
        <div style="display: flex; justify-content: space-between; align-items: end" v-for="(row, i) in orderFieldList" :key="i">
          <div class="num">{{ row.name }}</div>
          <div class="money">{{ orderFieldValueList[i].content }}</div>
        </div>
      </div>
      <!--			<div class="msgList">-->
      <!--				<div class="title">商品</div>-->
      <!--				<div style="display: flex; justify-content: space-between; align-items: end">-->
      <!--					<div class="num">商品成交价</div>-->
      <!--					<div class="money">￥{{ orderResult.totalPrice }}</div>-->
      <!--				</div>-->
      <!--			</div>-->

      <div style="display: flex; justify-content: space-between; align-items: end; padding: 20px 30px; box-sizing: border-box; line-height: 50px">
        <div class="bottomNum">实付金额</div>
        <div class="bottomMoney">￥{{ orderResult.totalPrice }}</div>
      </div>
    </div>
  </div>
</template>
<script>
  import { commonProblemList, gameCommodityQueryById, insureList, orderFieldList, queryDetail } from '@/api'
  import { getServicerList } from '@/api/message'

  export default {
    components: {},
    data() {
      return {
        step: 1,
        goods: {},
        insures: [
          {
            id: '',
            title: '不购买(无赔付)',
            content: '',
            rate: 0,
            money: 0,
            paidRate: 0,
          },
        ],
        helpDoc: [],
        num: 1,
        orderFieldList: [],
        orderFieldValueList: [],
        servicerList: [],
        orderResult: {},
        orderId: '',
        choose: 0,
        stepStatus: 0,
        stepList: [
          {
            text: '提交订单',
            val: () => {
              return this.orderResult.createTime
            },
          },
          {
            text: '待付款',
            val: () => {
              return this.orderResult.payTime
            },
          },
          {
            text: '交易中',
            val: () => {
              return this.orderResult.deliveryTime
            },
          },
          {
            text: '已完成',
            val: () => {
              return this.orderResult.successTime
            },
          },
        ],
        statusText: '',
      }
    },
    watch: {},
    created() {
      this.goods.id = this.$route.params.goodsId
      this.orderId = this.$route.params.id
      this.loadGoodsInfo().then((res) => {
        let p1 = this.loadInsureList()
        let p2 = this.loadOrderFieldList()
        // let p3 = this.loadServicerList()
        Promise.all([p1, p2]).then(() => {
          this.loadOrderInfo()
        })
        // this.loadProblemList()
      })
    },
    methods: {
      //选择保险
      selectInsure(index) {
        this.choose = index
        this.goods.insureId = this.insures[index].id
      },
      //加载商品信息
      loadGoodsInfo() {
        return gameCommodityQueryById({ id: this.goods.id }).then((res) => {
          res = res.result
          this.goods = res
          this.goods.publish = res.createTime.substring(0, 10).replaceAll('-', '.')
          if (res.pic) {
            this.goods.pic = res.pic.split(',')
            this.goods.cover = this.goods.pic[0]
          }
          if (this.goods.deliveryType == 1) {
            this.goods.deliveryType_dictText = '卖家发货'
          } else if (this.goods.deliveryType == 2) {
            this.goods.deliveryType_dictText = '平台发货'
          }
        })
      },
      //加载保险套餐
      loadInsureList() {
        return insureList({
          gameTypeId: this.goods.commodityTypeId,
        }).then((res) => {
          this.insures.push(...res.result)
          // this.calcPrice();
          this.selectInsure(0)
        })
      },
      //加载订单字段
      loadOrderFieldList() {
        return orderFieldList({
          gameTypeId: this.goods.commodityTypeId,
          orderFieldType: 1,
          districtId: this.goods.districtId,
        }).then((res) => {
          this.orderFieldList = res.result
          let arr = []
          for (let item of res.result) {
            arr.push({
              form: item.form,
              content: '',
              title: item.name,
              sort: item.sort,
              fieldId: item.id,
              orderId: 1,
              isRequired: item.isRequired,
            })
          }
          this.orderFieldValueList = arr
        })
      },
      //加载客服列表
      loadServicerList() {
        return getServicerList({ gameId: this.goods.gameId }).then((res) => {
          this.servicerList = res.result.records
          this.kfChoose = Math.floor(Math.random() * 2)
        })
      },
      //加载订单信息
      loadOrderInfo() {
        if (!this.orderId) {
          return
        }
        queryDetail({ id: this.orderId }).then((res) => {
          res = res.result
          this.orderResult = res
          this.num = res.num
          this.orderFieldValueList = res.orderInfos
          this.phone = res.phone

          this.goods.insureId = res.insureId
          for (let i = 0; i < this.insures.length; i++) {
            let item = this.insures[i]
            if (item.id === res.insureId) {
              this.choose = i
              break
            }
          }

          // for (let i = 0; i < this.servicerList.length; i++) {
          // 	let item = this.servicerList[i]
          // 	if (item.userId === res.serviceId) {
          // 		break
          // 	}
          // }
          if (this.stepList[1].val()) {
            this.stepList[1].text = '付款成功'
          }

          switch (res.status) {
            case -1:
              for (let i = 0; i < this.stepList.length; i++) {
                let item = this.stepList[i]
                let val = item.val()
                if (val) {
                  this.step = i + 1
                } else {
                  break
                }
              }
              this.stepStatus = 0
              this.statusText = '已取消'
              break
            case 0:
              this.step = 1
              this.stepStatus = 1
              this.statusText = this.stepList[this.step].text
              break
            case 1:
              this.step = 2
              this.stepStatus = 1
              this.statusText = this.stepList[this.step].text
              break
            case 3:
              this.step = 3
              this.stepStatus = 1
              this.statusText = this.stepList[this.step].text
              break
            case 4:
              this.step = 4
              this.stepStatus = 2
              this.statusText = this.stepList[this.stepList.length - 1].text
              break
          }
        })
      },
      back() {
        history.go(-1)
      },
      //切换显示详情
      gotoDetail() {
        this.$router.push('/gameDetaile/' + this.goods.id)
      },
      onCopy() {
        this.message('复制成功', 'success')
      },
    },
  }
</script>

<style scoped lang="scss">
  .bottomNum {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 20px;
    color: #1a1a1a;
  }
  .bottomMoney {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 32px;
    color: #ef2020;
  }
  .content2 {
    width: 1200px;
    //height: 673px;
    background: #ffffff;
    border-radius: 20px;
    margin: auto;
    box-sizing: border-box;
    padding: 20px;
    .msgList {
      width: 100%;
      border-bottom: 1px solid #ebebeb;
      padding: 30px;
      box-sizing: border-box;
      .num {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 20px;
        color: #999999;
        margin-top: 20px;
      }

      .num1 {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 26px;
        color: #000;
      }

      .money {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 20px;
        color: #1a1a1a;
      }
      .title {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 20px;
        color: #1a1a1a;
      }
    }
    .top {
      width: 1147px;
      height: 220px;
      background: #f9f9f9;
      border-radius: 10px;
      padding: 20px 30px;
      box-sizing: border-box;
      .title {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 20px;
        color: #1a1a1a;
      }
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 25px;
      div {
        flex: 1;
      }
    }
  }
  .content1 {
    width: 1200px;
    height: 313px;
    background: #ffffff;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 20px 30px;
    margin: 20px auto;
    .orderMsg {
      height: 164px;
    }
    .twoBox {
      display: flex;
      align-items: center;
      .numbox {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 28px;
        color: #ff5c00;
        span {
          font-size: 16px;
        }
      }
      .imgBox {
        width: 290px;
        height: 164px;
        overflow: hidden;
        background: #cfd1d1;
        display: flex;
        justify-content: space-around;
        border-radius: 18px;
      }
      .msgLI {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        margin-left: 20px;
        margin-top: 20px;
      }
      .msgTop {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        .orderType {
          height: 26px;
          background: #1861ee;
          border-radius: 4px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          line-height: 26px;
          padding: 4px 8px;
          margin: 0 15px;
        }

        .orderType1 {
          background: #b742ff !important;
        }

        .orderName {
          font-family: Microsoft YaHei;
          font-weight: 600;
          font-size: 16px;
          color: #1a1a1a;
          width: 600px;
          overflow: hidden;
          white-space: nowrap; /* 防止文本换行 */
          overflow: hidden; /* 隐藏超出的部分 */
          text-overflow: ellipsis; /* 超出部分显示省略号 */
          cursor: pointer;
        }
      }
    }
    .oneBox {
      width: 1147px;
      height: 70px;
      background: #f5faff;
      border-radius: 10px;
      padding: 0 40px;
      line-height: 70px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-size: 16px;
      box-sizing: border-box;
      color: #666666;
      display: flex;
      margin-bottom: 20px;
      .title {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 20px;
        color: #1a1a1a;
        margin-right: 30px;
      }
      .orderNo {
        margin: 0 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 18px;
        color: #666666;
      }
    }
    .oneBox:nth-child(1) {
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 20px;
      color: #1a1a1a;
    }
  }
  .content {
    height: 294px;
    background: #ffffff;
    border-radius: 20px;
  }
  .box1 {
    height: 70px;
    width: 1200px;
    background: #424242;
    border-radius: 20px 20px 0px 0px;
    display: flex;
    justify-content: space-between;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 20px;
    color: #ffffff;
    line-height: 70px;
    box-sizing: border-box;
    padding: 0 40px;
    .title {
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 24px;
      color: #ffffff;
    }
  }
  .box2 {
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding: 0 40px;
    box-sizing: border-box;
    .right {
      width: 160px;
      height: 160px;
      border-radius: 50%;
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 20px;
      line-height: 160px;
      text-align: center;
      margin: auto;
    }

    .stepStatus0 {
      background: url('@/static/image/newImage/i1.png') no-repeat;
      background-size: contain;
      color: #7c818c;
    }

    .stepStatus1 {
      background: url('@/static/image/newImage/i2.png') no-repeat;
      background-size: contain;
      color: #7c818c;
    }

    .stepStatus2 {
      background: url('@/static/image/newImage/i3.png') no-repeat;
      background-size: contain;
      color: #7c818c;
    }

    .border {
      width: 1px;
      height: 173px;
      background: #d1d1d1;
      margin: 0 60px;
    }
  }
</style>
