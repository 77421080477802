<template>
  <div class="app">
    <div class="title">钻石充值</div>
    <div class="top">
      <img :src="require('@/static/image/newImage/zuanshi.png')" alt="" />
      <div>钻石余额</div>
      <div>{{ $store.state.userInfo.diamondBalance }}</div>
    </div>
    <div class="typeBox">
      充值方式：
      <el-radio-group v-model="group">
        <el-radio v-model="group" label="5">支付宝</el-radio>
        <el-radio v-model="group" label="6">微信</el-radio>
      </el-radio-group>
    </div>
    <div class="content-title">
      <div>请选择钻石面额：</div>
      <div>兑换成功后，仅可抵扣商品实际金额，<span>不可提现</span>哦！</div>
    </div>
    <div class="moneyList">
      <div class="moneyLi" v-for="(item, index) in moneyList" :style="{ border: moneyIndex == index ? ' 1px solid #FF5700 ' : '' }" :key="index" @click="moneyIndex = index">
        <img :src="require('@/static/image/newImage/zuanshi.png')" alt="" />
        <div :style="{ color: moneyIndex == index ? '#FF5700 !important' : '' }">{{ item.diamond }}钻石</div>
        <div :style="{ color: moneyIndex == index ? '#FF5700 !important' : '' }">￥{{ item.money }}</div>
        <img class="topImg" v-show="moneyIndex == index " :src="require('@/static/image/newImage/yes.png')" alt="">
      </div>
      <div class="moneyLi1" @click="moneyIndex = 99" :style="{ border: moneyIndex == 99 ? ' 1px solid #FF5700 ' : '' }">
        <div>其他面额</div>
        <div><input style="border: none;font-size: 14px;background: none;width:80px;height:28px;border-bottom:1px solid #ababab" v-model="moneyNum" placeholder="请输入总数"></input></div>
        <img class="topImg" v-show="moneyIndex == 99 " :src="require('@/static/image/newImage/yes.png')" alt="">
      </div>
    </div>
<!--    <div style="text-align: center" v-if="onlineShow">-->
<!--      <iframe :src="iframeUrl" width="240" height="250" style="border: none;margin-top:40px"></iframe>-->
<!--    </div>-->
    <div class="reaclBox">应付款：<span>￥{{ money }}</span></div>
    <div class="btnBox" @click="submit">立即支付</div>


    <div class="popUp" v-if="onlineShow" style="display: flex; justify-content: center">
      <div class="bigContent">
        <img @click="onlineShow = !onlineShow" class="closeBtn" :src="require('@/static/image/index/closeNew.png')"/>
        <div class="content1">
          <img v-if="group == '5'" class="logo" :src="require('@/static/image/index/zfbPayImg.png')"/>
          <img v-if="group == '6'" class="logo" :src="require('@/static/image/index/wxPayImg.png')"/>
          <div class="text1">本次扫码需支付</div>
          <div class="text2">{{ money }}元</div>
          <div class="text3">
            <div class="xian"></div>
            <div style="margin-bottom: 30px">·&nbsp;请扫码右侧二维码完成本次交易</div>
            <div style="display: flex">
              <div>·&nbsp;</div>
              <div>
                完成交易后，我们可能需要
                <span style="color: #fd9814">几分钟</span>
                的时间来 确认支付是否完成，请布耐心等待
              </div>
            </div>
          </div>
        </div>
        <div class="content2">
          <div class="item1">
            <iframe :src="iframeUrl" width="240" height="250" style="border: none"></iframe>
          </div>
          <div style="display: flex; justify-content: center">
            <div class="text1">
              为确保资金安全，支付二维码会
              <span style="color: #1861ee">自动刷新</span> 请您 放心扫码
            </div>
          </div>
          <div style="display: flex; justify-content: center">
            <div class="text1" style="margin-top: 21px">
              打开手机
              <span style="color: #1861ee">支付宝</span> 点击扫一扫
            </div>
          </div>
        </div>
      </div>
    </div>
    
<!--    <div class="popUp" v-if="onlineShow">
      <div style="width: 100%; justify-content: center; position: relative" class="fl">
        <img style="width: 522px; height: 166px; position: absolute; top: 100px" :src="require('@/static/image/index/payImage.png')" />
        <div class="popCount">
          <img class="close" @click="closePay" :src="require('@/static/image/index/close.png')" />
&lt;!&ndash;          <img style="width: 240px; height: 240px; margin-top: 40px" :src="payCode" alt="" />&ndash;&gt;
          <iframe :src="iframeUrl" width="240" height="250" style="border: none;margin-top:40px"></iframe>
          <div class="popText">使用 支付宝 扫码支付<br />支付即视为你同意 <span style="color: #0085ff">相关协议</span> 条例</div>
          <div class="payOrder">
            <span>实付金额： </span>
            <div>
              <span style="color: #ff5c00; font-weight: bold; font-size: 36px">￥{{ money }}</span>
              <span style="color: #ff5c00">元</span>
            </div>
          </div>
        </div>
      </div>
    </div>-->
  </div>
</template>

<script>
import { orderDepositPay, queryPayById, rechargeList } from '@/api'

  export default {
    data() {
      return {
        onlineShow: false,
        group     : '5',
        moneyNum  : '',
        moneyIndex: 0,
        moneyList : [],
        money     : 0,
        configId  : '',
        iframeUrl : '',
        pollTime  : null,
      }
    },
    created() {
      this.loadRechargeList()
    },
    beforeDestroy() {
      clearInterval(this.pollTime)
    },
    watch: {
      moneyIndex(val) {
        this.changeMoneyIndex()
      },
      moneyNum(val) {
        this.changeMoneyIndex()
      },
      group(){
        this.onlineShow = false
        this.iframeUrl = ''
        clearInterval(this.pollTime)
      }
    },
    methods: {
      loadRechargeList() {
        rechargeList({}).then((res) => {
          this.moneyList = res.result
          this.changeMoneyIndex()
        })
      },
      changeMoneyIndex() {
        if (this.moneyIndex === 99) {
          if (/^[0-9]*\.?[0-9]*$/.test(this.moneyNum)) {
            this.money = this.moneyNum
          } else {
            this.money = ''
          }
          this.configId = ''
        } else {
          if (this.moneyList.length > 0) {
            this.money = this.moneyList[this.moneyIndex].money
            this.configId = this.moneyList[this.moneyIndex].id
          }
        }
        
      },
      closePay () {
        this.onlineShow=false
        clearInterval(this.pollTime)
      },
      submit() {
        if (this.group !== '5' && this.group !== '6') {
          this.message('请选择充值方式', 'error')
          return
        }
        if (!this.money || this.money <= 0) {
          this.message('请选择充值面额', 'error')
          return
        }
        orderDepositPay({
          id         : this.configId,
          payType    : this.group,
          depositType: 10,
          money      : this.money,
          returnType : 1,
        }).then((res) => {
          this.iframeUrl = res.result.endPayInfo
          this.onlineShow = true
          clearInterval(this.pollTime)
          this.orderQueryById(res.result.id)
        })
      },
      orderQueryById(id) {
        let that = this
        this.pollTime = setInterval(() => {
          queryPayById({
            id: id,
          }).then((res) => {
            res = res.result
            if (res.payStatus == 1) {
              clearInterval(that.pollTime)
              that.message('支付成功', 'success')
              that.onlineShow = false
              this.$store.dispatch('queryBalance')
            }
          })
        }, 3000)
      },
    },
  }
</script>

<style scoped lang="scss">
  .app {
    box-sizing: border-box;
    .typeBox{
      margin-left: 20px;
      margin-top: 10px;
      color: #666666;
    }
    .title {
      font-weight: 600;
      font-size: 24px;
      color: #1d223c;
      width: 950px;
      border-bottom: 1px solid #ececec;
      height: 60px;
      line-height: 60px;
      padding-left: 42px;
    }
    .top {
      width: 918px;
      height: 86px;
      background: linear-gradient(90deg, #fff5ec 0%, #ffffff 100%);
      margin: auto;
      margin-top: 20px;
      display: flex;
      align-items: center;
      padding-left: 30px;
      img {
        width: 66px;
        height: 61px;
      }
      :nth-child(2) {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 18px;
        color: #666666;
        margin: 0 18px;
      }
      :nth-child(3) {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 40px;
        color: #ff5700;
      }
    }
    .content-title {
      padding: 30px 20px;
      display: flex;
      justify-content: space-between;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 16px;
      color: #666666;
      span {
        color: #ff5700;
      }
    }
    .moneyList {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 0 20px;
   
      .moneyLi1 {
        position: relative;
        width: 114px;
        height: 164px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #cdcdcd;
        margin-bottom: 20px;
        margin-left: 20px;
        box-sizing: border-box;
        cursor: pointer;
        .topImg{
            position: absolute;
            top: 0;
            right: 0;
            width: 20px;
        }
        :nth-child(1) {
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 20px;
          color: #7cb2fb;
          text-align: center;
          height: 100px;
          line-height: 100px;
        }
        :nth-child(2) {
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 18px;
          text-align: center;
          color: #b3b3b3;
          height: 64px;
          line-height: 45px;
          width: 114px;
          box-sizing: border-box;
          padding: 0 10px;
        }
      }
      .moneyLi {
        width: 114px;
        height: 164px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #cdcdcd;
        padding: 10px;
        margin-bottom: 20px;
        margin-left: 20px;
        box-sizing: border-box;
        cursor: pointer;
        position: relative;
        .topImg{
            position: absolute;
            top: 0;
            right: 0;
            width: 20px;
        }
        img {
          margin: auto;
          display: block;
        }
        :nth-child(2) {
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          margin: auto;
          text-align: center;
          margin: 10px;
        }
        :nth-child(3) {
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 18px;
          color: #666666;
          margin: auto;
          text-align: center;
        }
      }
    }
    .reaclBox{
      text-align: center;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 14px;
      margin-top: 45px;
      color: #666666;
      span{
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 24px;
        color: #FF5700;
      }
    }
    .btnBox{
      width: 322px;
      height: 50px;
      background: #FF5700;
      border-radius: 4px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 18px;
      color: #FFFFFF;
      text-align: center;
      margin: auto;
      margin-top: 45px;
      line-height: 50px;
    }
  }
  .popUp {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.3);
    overflow: hidden;
  }
  .popCount {
    z-index: 9999;
    position: relative;
    margin-top: 220px;
    display: flex;
    flex-direction: column;
    width: 522px;
    border-radius: 24px;
    align-items: center;
    background-color: #fff;
    padding-bottom: 30px;

    .popText {
      width: 264px;
      font-weight: 400;
      font-size: 16px;
      color: #777a8a;
      text-align: center;
      margin-top: 20px;
    }

    .payOrder {
      width: 394px;
      height: 100px;
      background: #f6f8fa;
      border-radius: 12px 12px 12px 12px;
      margin-top: 40px;
      padding: 0 24px;
      color: #a5a7b1;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .close {
    position: absolute;
    top: -100px;
    right: 10px;
    z-index: 999;
    cursor: pointer;
  }
  .bigContent {
    z-index: 9999;
    position: relative;
    width: 990px;
    height: 520px;
    background: #fff;
    border-radius: 20px;
    margin-top: 120px;
    display: flex;

    .closeBtn {
      width: 34px;
      height: 33px;
      position: absolute;
      z-index: 9999;
      right: -31px;
      top: -31px;
    }

    .content1 {
      width: 437px;
      background: #0e3a8f;
      height: 520px;
      overflow: hidden;

      .logo {
        margin-top: 37px;
        margin-left: 48px;
        width: 68px;
        height: 68px;
      }

      .text1 {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 20px;
        color: #ffffff;
        margin-top: 42px;
        margin-left: 48px;
      }

      .text2 {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 40px;
        color: #ffffff;
        margin-left: 48px;
        margin-top: 30px;
      }

      .text3 {
        padding: 0 37px;
        margin-top: 40px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;

        .xian {
          height: 1px;
          background: #54688f;
          margin-bottom: 40px;
        }
      }
    }

    .content2 {
      width: 553px;
      height: 520px;
      overflow: hidden;

      .item1 {
        display: flex;
        justify-content: center;
        margin-top: 80px;
      }

      .text1 {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 16px;
        color: #1a1a1a;
        text-align: center;
        margin-top: 44px;
        width: 298px;
      }
    }
  }
</style>
