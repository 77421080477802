<template>
  <div id="app" ref="app" style="width: 100%">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />

  </div>
</template>

<script>
  export default {
    created() {},
    mounted() {
      // let _that = this
      // _that.bodyScale()
    },

    methods: {
      // 获取当前屏幕尺寸函数
      // bodyScale() {
      // 	let devicewidth = window.screen.availWidth //获取当前分辨率下的可是区域宽度
      // 	console.log(devicewidth)
      // 	if(devicewidth < 2200){
      // 		let scale = devicewidth / 2200 // 分母——设计稿的尺寸
      // 		document.body.style.zoom = scale //放大缩小相应倍数
      // 	}
      // },
    },
  }
</script>

<style lang="scss">
  @import url('static/css/public.css');

  #app {
    padding: 0;
    margin: 0;
    font-family: 'PingFangSC';

    min-height: 100vh;
  }

  /* 主内容宽度 */
  .content {
    margin: 0 auto;
    width: 1200px;
  }

  /* 宽度低于1200，给主体内容左右加留白 */
  @media screen and (max-width: 1200px) {
    .content {
      overflow: hidden;
      overflow-x: auto;
    }
  }

  input {
    outline: none;
  }

  @font-face {
    font-family: 'PingFangSC1';
    /* 自定义的字体名称 */
    src: url('~@/assets/fonts/PingFang-Medium.otf');
    font-weight: normal;
    /* 字体粗细 */
    font-style: normal;
    /* 字体样式 */
  }

  * {
    font-family: 'PingFangSC';
  }

  button {
    border: none;
  }

  // #app {
  //   width: 100vw;
  //   height: 100vh;
  //   background: rgb(24, 25, 35);
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }

  .el-cascader-node__postfix {
    position: absolute !important;
    right: 20px !important;
  }
</style>
