<template>
  <div style="background-color: #fff; ">
    <el-row style="background: #fff;">
      <div class="content" style="display: flex; align-items: center; ">
        <el-col :span="24">
          <div class="s bg-purple fl headerBox" >
            <div class="leftText fl">
              欢迎来到派派代售交易平台
              <!-- <span v-if="token == undefined" style="color: #FF7F37;" @click="$router.push('/login/login');">请登录</span>
							<span  v-if="token == undefined" style="margin-left: 8px;" @click="$router.push('/login/register');">
								免费注册
							</span> -->
              <div v-if="token != undefined" class="loginType fl" @click="loginOut">
                <img :src="require('@/static/image/newImage/icon5.png')" style="width: 14px; height: 14rpx; margin-right: 2px" />
                退出登录
              </div>
            </div>
            <el-menu :default-active="$store.state.headBarActive" class="el-menu-demo" style="height: 40px" mode="horizontal" @select="handleSelect" background-color="#fff" text-color="#777A8A" active-text-color="#0286FE">
              <el-menu-item v-for="(item, index) in titleList" :index="item.id" :key="index" style="position: relative;font-size: 12px">
                <div v-if="index!==0||!$store.state.token">
                  <a @click.prevent="DDDD" :href="'/#' + item.router" :class="{ setColor: index == 2 }" class="btnBox" style="text-decoration: none; color: inherit;padding-bottom: 5px;"  :style="{ 'border-bottom': item.id == $store.state.headBarActive ? '2px solid #1861EE' : 'none' }">{{ item.title }}</a>
                  <img class="hot" v-if="index == 6" :src="require('@/static/image/index/hot.png')" alt="" />
                </div>
              </el-menu-item>
            </el-menu>
          </div>
        </el-col>
        <!--				<el-col :span="3">
					<div class="topRight">
						<div @click="openLink('/game/select/0')" class="rightItem1">我要买</div>
						<div @click="openLink('/game/add/add')" class="rightItem2">我要卖</div>
					</div>
				</el-col>-->
      </div>
    </el-row>
    <div class="searchBox" style="background-color: #efefef;">
			<el-row style="width: 1200px;margin: 0 auto;">
				<el-col :span="24">
					<div>
						<el-row style="display: flex;align-items: center;">
								<div style="width: 270px;">
									<img :src="require('@/static/image/newImage/logo.png')" style="width: 180px;" />
								</div>
								<div  style="justify-content: flex-end; width: 680px;">
									<div >
										<div class="search">
											<div class="downList" ref="dialogBoxs">
												<div @click="selectGameShow('selectGame')">
													<div class="searchItem">
														{{selectGame.name || '游戏名称'}}
														<i class="el-icon-arrow-down"></i>
													</div>
												</div>
												<div @click="selectGameShow('selectGameTypeList')">
													<div class="searchItem">
														{{selectGameTypeList.name || '商品类型'}}

														<i class="el-icon-arrow-down"></i>
													</div>
												</div>
												<div v-if="sonGameTypeList.length !=0"
													@click="selectGameShow('selectSonGameTypeList')">
													<div class="searchItem">
														{{selectSonGameTypeList.name || '商品子类'}}

														<i class="el-icon-arrow-down"></i>
													</div>
												</div>
												<div v-if="systemList.length !=0">
													<div class="searchItem">
														{{selectSystem.name || '系统'}}
														<i class="el-icon-arrow-down"></i>
													</div>
												</div>
												<div v-if="districtList.length !=0">
													<div class="searchItem">
														{{selectDistrict.name || '游戏区服'}}
														<i class="el-icon-arrow-down"></i>
													</div>
												</div>
												<div v-if="sonDistrictList.length !=0">
													<div class="searchItem">
														{{sonSelectDistrict.name || '游戏大区'}}
														<i class="el-icon-arrow-down"></i>
													</div>
												</div>


												<div class="dialogBox" v-if="selectShow">
													<div class="searchName">
														<div>
															<div class="typeTop fl">
																<div class="fl">
																	<div class="gameType"
																		@click="selectSearch('','type')"
																		:class="{'gameTypeAction':gameSearch.terminalType == ''}">
																		全部游戏</div>
																	<div class="gameType"
																		@click="selectSearch('1','type')"
																		:class="{'gameTypeAction':gameSearch.terminalType == '1'}">
																		网络游戏</div>
																	<div class="gameType"
																		@click="selectSearch('2','type')"
																		:class="{'gameTypeAction':gameSearch.terminalType == '2'}">
																		手机游戏</div>
																</div>
																<div class="fl">
																	<el-input v-prevent-space placeholder="请输入内容"
																		v-model="gameSearch.name">
																		<i slot="prefix"
																			class="el-input__icon el-icon-search"></i>
																	</el-input>
																	<span @click="getGameList1"
																		style="width: 120px;text-align: center;cursor:pointer;font-size: 14px;">快速搜索</span>
																</div>
															</div>
															<div class="initialList">
																<el-link class="initialItem"
																	@click="selectSearch(item,'initial')"
																	v-for="(item,index) in initial"
																	target="_blank">{{item}}</el-link>

															</div>
														</div>
														<div class="selectList">
															<div class="selectItem" v-for="item in gameList">
																<i class="el-icon-caret-right" style="color: #ccc;"></i>
																<span @click="gameSelect(item)"
																	class="selectSpan">{{item.name}}</span>
															</div>
														</div>
													</div>
												</div>

												<div class="dialogBox" v-if="selectShow1">
													<div class="searchName">
														<div class="fl" style="margin-bottom: 10px;">
															<span class="searchLable">请选择商品类型：</span>
															<span class="searchLable" style="color: #0085FF;">不限</span>
														</div>

														<div class="selectList">
															<div class="selectItem" v-for="item in gameTypeList">
																<i class="el-icon-caret-right" style="color: #ccc;"></i>
																<span @click="gameTypeSelect(item)"
																	class="selectSpan">{{item.name}}</span>
															</div>
															<div style="color: #0085FF;"
																v-if="gameTypeList.length == 0">
																请先选择游戏
															</div>
														</div>
													</div>
												</div>
												<div class="dialogBox" v-if="selectShow2">
													<div class="searchName">
														<div class="fl" style="margin-bottom: 10px;">
															<span class="searchLable">请选择商品子类：</span>
															<span class="searchLable" style="color: #0085FF;">不限</span>
														</div>

														<div class="selectList">
															<div class="selectItem" v-for="item in sonGameTypeList">
																<i class="el-icon-caret-right" style="color: #ccc;"></i>
																<span @click="sonGameTypeSelect(item)"
																	class="selectSpan">{{item}}</span>
															</div>
														</div>
													</div>
												</div>
												<div class="dialogBox" v-if="selectShow3">
													<div class="searchName">
														<div class="fl" style="margin-bottom: 10px;">
															<span class="searchLable">游戏区服：</span>
															<span class="searchLable" style="color: #0085FF;">不限</span>
														</div>

														<div class="selectList">
															<div class="selectItem" v-for="item in districtList">
																<i class="el-icon-caret-right" style="color: #ccc;"></i>
																<span @click="districtSelect(item)"
																	class="selectSpan">{{item.name}}</span>
															</div>
														</div>
													</div>
												</div>
												<div class="dialogBox" v-if="selectShow4">
													<div class="searchName">
														<div class="fl" style="margin-bottom: 10px;">
															<span class="searchLable">游戏大区：</span>
															<span class="searchLable" style="color: #0085FF;">不限</span>
														</div>

														<div class="selectList">
															<div class="selectItem" v-for="item in sonDistrictList">
																<i class="el-icon-caret-right" style="color: #ccc;"></i>
																<span @click="sonDistrictSelect(item)"
																	class="selectSpan">{{item.name}}</span>
															</div>
														</div>
													</div>
												</div>
												<div class="dialogBox" v-if="selectShow5">
													<div class="searchName">
														<div class="fl" style="margin-bottom: 10px;">
															<span class="searchLable">系统：</span>
															<span class="searchLable" style="color: #0085FF;">不限</span>
														</div>

														<div class="selectList">
															<div class="selectItem" v-for="item in systemList">
																<i class="el-icon-caret-right" style="color: #ccc;"></i>
																<span @click="systemSelect(item)"
																	class="selectSpan">{{item.name}}</span>
															</div>
														</div>
													</div>
												</div>
												<div class="searchInput">
													<input v-prevent-space
														style="border: none;font-size: 14px;margin-left: 30px;min-width:100px;background: none;"
														v-model="searchKey" placeholder="搜索商品关键词"></input>
												</div>
											</div>
											<div class="submitSearch" @click="searchShop">
												搜索
											</div>
										</div>
										<div class="hotTag">
											<div class="hotList">
												<div style="margin-right: 5px;">热门搜索:</div>
												<div class="hotItem" @click="toShopLists(item)"
													v-for="(item,index) in hotTagList" :key="index">
													{{item.name}}
												</div>
											</div>
										</div>
									</div>
								</div>
               <div class="rightBtn">
                <div class="rightBtnBox" @click="handelGoShop(1)">
                    我要买
                  </div>
                  <div class="rightBtnBox" @click="handelGoShop(2)">
                    我要卖
                  </div>
               </div>
						</el-row>

					</div>
				</el-col>
      
			</el-row>
      <div style="width: 1200px;margin: 0 auto;">
        <newBar v-if="showNewBar"></newBar> 
      </div>
      
		</div>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth.js'
import newBar from '@/components/newBar.vue';
import {
		gameList,
		gameCommodityTypes,
  districtList,
  hotList
	} from '@/api/index.js'
  export default {
    name: 'HeadBar',
    activated() {
      this.token = getToken()
  },
  components: {
			newBar
		},
    data() {
      return {
        
				shoWData: false,
				lableAction: 0,
				headShow: true,
				hoverIndex: 0,
				hoverShow: false,
				userInfo: {},
				token: undefined,
				selectShow: false,
				selectShow1: false,
				selectShow2: false,
				selectShow3: false,
				selectShow4: false,
				selectShow5: false,
        funList: [],
				// 热门标签
				hotTagList: [],

				// 热门游戏列表
				hotSort: [],
				// 公告列表
				noticeList: [],
				//首字母
				initial: ['热门游戏', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R',
					'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
				],
        gameSearch: {
					initial: '',
					isHot: '',
					name: '',
					pageNo: 1,
					pageSize: 1000,
					terminalType: ''
				},
				gameList: [],
				selectGame: {},

				gameTypeList: [],
				selectGameTypeList: {},

				sonGameTypeList: [],
				selectSonGameTypeList: {},

				// 区服列表
				districtList: [],
				selectDistrict: {},

				// 大区
				sonDistrictList: [],
				sonSelectDistrict: {},
				// 系统
				systemList: [],
				selectSystem: {},

				sonSelectDistrict: {},
				sonGameTypeList: [],
        token: undefined,
        titleList: [
          {
            title: '登录/注册',
            router: '/login/login',
            id: '7',
          },
          {
            title: '个人中心',
            router: '/mineIndex/1',
            id: '2',
          },
          // {
          //   title: '订单聊天',
          //   router: '/chat/0',
          //   id: '3',
          // },
          {
            title: '我的订单',
            router: '/mineIndex/13',
            id: '4',
          },
          {
            title: '买家中心',
            router: '/mineIndex/8',
            id: '5',
          },
          {
            title: '卖家中心',
            router: '/mineIndex/12',
            id: '6',
          },
          // {
          // 	title: '账户设置',
          // 	id: 7,
          // 	router: '/setting/1',
          // },
        ],
        searchKey: "",
        showNewBar: true,
      }
    },
    created() {
      this.token = getToken()
			this.getGameList1()
			this.getHotList()
      let currentRoute = this.$router.currentRoute
      for (let item of this.titleList) {
        if (item.router == currentRoute.path) {
          this.$store.commit('setHeadBarActive',item.id)
          break
        }
      }
    },
    watch: {
      // 利用watch方法检测路由变化：
      $route: function (to, from) {
        this.showNewBar = to.path !== '/home'
      },
    },
    // mounted(){
    // 		let currentRoute = this.$router.currentRoute;
    // 		console.log(currentRoute)
  // },
  mounted () {
    // ref="dialogBox"
    // 监听全局点击事件
    this.showNewBar = this.$router.currentRoute.path !== '/home'
    document.addEventListener('click', (e) => {
      if (e.target.className == 'selectSpan') return
      if (this.$refs.dialogBoxs && !this.$refs.dialogBoxs.contains(e.target)) {
        this.selectShow = false
        this.selectShow1 = false
        this.selectShow2 = false
        this.selectShow3 = false
        this.selectShow4 = false
        this.selectShow5 = false
      }
    });
  },
  methods: {
    		// 选择商品子类
			sonGameTypeSelect(item) {
				this.selectSonGameTypeList = {
					name: item
				}
				if (this.selectGame.terminalType == 2) {
					districtList({
						id: this.selectGame.id
					}).then(res => {
						if (res.result.length != 0) {
							this.systemList = res.result
							this.selectShow2 = false
							this.selectShow5 = true
						} else {
							this.searchShop()
						}

					})
				} else {
					districtList({
						id: this.selectGame.id
					}).then(res => {
						if (res.result.length != 0) {
							this.districtList = res.result
							this.selectShow2 = false
							this.selectShow3 = true
						} else {
							this.searchShop()
						}

					})
				}


			},
    districtSelect(item) {
				this.selectDistrict = {
					id: item.id,
					name: item.name,
				}
				districtList({
					id: this.selectDistrict.id
				}).then(res => {
					if (res.result.length != 0) {
						this.sonDistrictList = res.result
						this.selectShow3 = false
						this.selectShow4 = true
					} else {
						this.searchShop()
					}

				})
			},
    getHotList() {
				hotList({
					pageNo: 1,
					pageSize: 6
				}).then(res => {
					this.hotTagList = res.result.records
				})

			},
    toShopLists(item) {
				let info = {
					id: item.id,
					terminalType: item.terminalType,
					name: item.name,
					icon: item.icon,
				}
				// localStorage.setItem('gameList', JSON.stringify(info));
				window.open(this.$router.resolve({
					path: '/gameList',
					query: {
						info: JSON.stringify(info)
					}
				}).href, '_blank');

			},
    searchShop() {
				if (this.searchKey != '') {
					window.open(this.$router.resolve({
						path: '/gameDetaile/' + this.searchKey
					}).href, '_blank');
					return
				}
				if (Object.keys(this.selectGame).length == 0) {
					this.message('请先选择游戏', 'error')
					return
				}

				let info = {
					id: this.selectGame.id,
					name: this.selectGame.name,
					terminalType: this.selectGame.terminalType,
					icon: this.selectGame.icon,
					title: this.searchKey
				}
				if (Object.keys(this.selectGameTypeList).length != 0) {
					info.gameTypeSelect = {
						id: this.selectGameTypeList.id,
						name: this.selectGameTypeList.name
					}
				}
				if (Object.keys(this.selectSonGameTypeList).length != 0) {
					info.gameTypeSonSelect = {
						name: this.selectSonGameTypeList.name
					}
				}

				if (Object.keys(this.selectDistrict).length != 0) {
					info.selectDistrict = {
						id: this.selectDistrict.id,
						name: this.selectDistrict.name
					}
				}
				if (Object.keys(this.sonSelectDistrict).length != 0) {
					info.sonSelectDistrict = {
						id: this.sonSelectDistrict.id,
						name: this.sonSelectDistrict.name
					}
				}
				if (Object.keys(this.selectSystem).length != 0) {
					info.selectSystem = {
						id: this.selectSystem.id,
						name: this.selectSystem.name
					}
				}
				// localStorage.setItem('gameList', JSON.stringify(info));
				window.open(this.$router.resolve({
					path: '/gameList',
					query: {
						info: JSON.stringify(info)
					}
				}).href, '_blank');

			},
    	// 选择大区
			sonDistrictSelect(item) {
				this.sonSelectDistrict = {
					id: item.id,
					name: item.name,
				}
				this.selectShow4 = false
				this.searchShop()
			},
			// 筛选游戏名称
			selectSearch(value, type) {
				if (type == 'type') {
					this.gameSearch.terminalType = value
				}
				if (type == 'initial') {
					if (value == '热门游戏') {
						this.gameSearch.initial = ''
					} else {
						this.gameSearch.initial = value
					}
				}
				this.getGameList1()
			},
    	// 选择游戏
			gameSelect(item) {
				this.selectGame = {
					id: item.id,
					name: item.name,
					terminalType: item.terminalType,
					icon: item.icon
				}
				// 获取商品类型
				gameCommodityTypes({
					id: item.id
				}).then(res => {
					if (res.result.length != 0) {
						this.gameTypeList = res.result
						this.selectShow = false
						this.selectShow1 = true

						this.selectGameTypeList = {}

						this.sonGameTypeList = []
						this.selectSonGameTypeList = {}

						this.districtList = []
						this.selectDistrict = {}

						this.sonDistrictList = []
						this.sonSelectDistrict = {}

						this.systemList = []
						this.selectSystem = {}
					} else {
						this.searchShop()
					}

				})
    },
      	// 选择游戏类型
			gameTypeSelect(item) {
				this.selectGameTypeList = {
					id: item.id,
					name: item.name,
				}
				this.systemList = []
				this.selectSystem = {}
				// 如果是手游的话先获取系统
				if (this.selectGame.terminalType == 2) {
					if (item.childrenName != null) {
						this.sonGameTypeList = item.childrenName.split(' ')
						// this.sonGameTypeList=['111','2222']
						this.selectShow1 = false
						this.selectShow2 = true
					} else {
						districtList({
							id: this.selectGame.id
						}).then(res => {
							if (res.result.length != 0) {
								this.systemList = res.result
								this.selectShow1 = false
								this.selectShow2 = false
								this.selectShow5 = true
							} else {
								this.searchShop()
							}

						})
					}
				} else {
					if (item.childrenName != null && item.childrenName != '') {
						this.sonGameTypeList = item.childrenName.split(' ')
						// this.sonGameTypeList=['111','2222']
						this.selectShow1 = false
						this.selectShow2 = true
					} else {
						districtList({
							id: this.selectGame.id
						}).then(res => {
							if (res.result.length != 0) {
								this.districtList = res.result
								this.selectShow2 = false
								this.selectShow1 = false
								this.selectShow3 = true
							} else {
								this.searchShop()
							}

						})
					}
				}


			},
    handelGoShop (num) {
      let url = num == 1 ? '/game/select/0' : '/game/add/add'
      if (this.$router.currentRoute.path !== url) {
        this.$router.push(url)
      }
    },
    	// 选择游戏的时候清空其他数据
			selectGameShow(type) {
				if (type == 'selectGame') {
					this.selectShow = true
					this.selectShow1 = false
					this.selectShow2 = false
					this.selectShow3 = false
					this.selectShow4 = false
					this.selectShow5 = false
				} else if (type == 'selectGameTypeList') {
					this.selectShow = false
					this.selectShow1 = true
					this.selectShow2 = false
					this.selectShow3 = false
					this.selectShow4 = false
					this.selectShow5 = false
				} else if (type == 'selectSonGameTypeList') {
					this.selectShow = false
					this.selectShow1 = false
					this.selectShow2 = true
					this.selectShow3 = false
					this.selectShow4 = false
					this.selectShow5 = false
				}

    },
    getGameList1() {
				gameList(this.gameSearch).then(res => {
					if (res.code == 200) {
						this.gameList = res.result.records
					}
				})
			},
      DDDD() {
        return false
      },
      isLogin() {
        this.token = getToken()
      },
      // 微信登录
      weixinLogin() {
        // let url = 'http://'+window.location.host+ '/#/login/login'
        let redirect_uri = encodeURIComponent(window.location.origin + '/#/login/login')
        let appid = 'wx34ce80f4fc983974'
        window.location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_login&state=123#wechat_redirect`
      },
      handleSelect(key, keyPath) {
        let currentRoute = this.$router.currentRoute
        for (let item of this.titleList) {
          if (item.id === key) {
            if (currentRoute.path !== item.router) {
              this.$router.push(item.router)
            }
            this.$store.commit('setHeadBarActive',item.id)
            return
          }
        }
      },
      openLink(router) {
        window.open(
          this.$router.resolve({
            path: router,
          }).href,
          '_blank'
        )
      },
      loginOut() {
        this.$store.dispatch('loginOut')
        setTimeout(() => {
          this.$router.push('/login/login')
        }, 1000)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .grid-content {
    background-color: #fff;
  }

  ::v-deep .el-menu.el-menu--horizontal {
    border: none;
  }

  ::v-deep .el-dropdown-menu__item,
  .el-menu-item {
    font-family: 'PingFangSC';
    /* 使用你定义的字体 */
    font-size: 14px;
    padding: 0 10px;
  }

  ::v-deep .el-header {
    padding: 0;
  }

  ::v-deep .el-menu--horizontal > .el-menu-item.is-active {
    border-bottom: 0px solid #409eff;
  }

  .topRight {
    color: #fff;
    display: flex;
    // height: 100%;
    // line-height: 100%;
    justify-content: flex-end;
    background-color: #fff;
  }

  .rightItem {
    margin-right: 20px;
    font-size: 14px;
    width: 100px;
    cursor: pointer;
  }
  .headerBox {
    justify-content: space-between;
    height: 40px;
  }
  .rightItem1 {
    width: 72px;
    height: 32px;
    background: #0085ff;
    border-radius: 6px 6px 6px 6px;
    cursor: pointer;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    line-height: 32px;
  }

  .rightItem2 {
    width: 72px;
    height: 32px;
    border: 1px solid #0085ff;
    border-radius: 6px 6px 6px 6px;
    cursor: pointer;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: #0085ff;
    line-height: 32px;
    margin-left: 16px;
  }

  .noticeTitle {
    height: 32px;
    font-weight: 600;
    font-size: 22px;
    color: #1d223c;
    padding: 16px;
    border-bottom: 1px solid #ececec;
  }

  .noticeItem {
    width: 362px;
    font-weight: 400;
    font-size: 20px;
    color: #1d223c;
    padding-top: 16px;
    padding-left: 16px;
  }

  .setColor {
    color: #ff5c00;
  }

  .hot {
    // background-color:#FF5C00;
    // color: #fff;
    // border-radius: 5px;
    position: absolute;
    width: 30px;
    height: 30px;
    top: 2px;
    // right:43px;
    right: -5px;
    text-align: center;
    font-size: 12px;
  }

  .hot::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid #ff5c00;
    position: absolute;
    /* 根据需要调整位置 */
    top: 15px;
    /* 示例，调整位置 */
    left: 8px;
    /* 示例，调整位置 */
    // background-color:#FF5C00;
  }

  .leftText {
    font-weight: 400;
    font-size: 12px;
    color: #a5a7b1;
    margin-right: 20px;
  }
  .btnBox:hover {
    color: #0085ff !important;
  }
  .loginType {
    width: 77px;
    height: 20px;
    font-size: 12px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    justify-content: center;
    margin-left: 8px;
  }

  ::v-deep .el-menu-item {
    font-size: 14px;
  }

  ::v-deep .el-menu {
    height: 40px; /* 设置菜单高度 */
  }

  ::v-deep .el-menu-item {
    line-height: 40px; /* 设置每个菜单项的垂直居中 */
    height: 40px; /* 设置菜单高度 */
  }

  ::v-deep is-active {
    height: 40px; /* 设置菜单高度 */
  }


  
	.search {
		width: 670px;
		height: 44px;
		background-color: #fff;
		border-radius: 22px;
    border: 2px solid #1861EE;
		padding: 2px 0;
		display: flex;
		align-items: center;
		margin-top: 27px;
		justify-content: space-between;
    box-sizing: border-box;
	}

	.submitSearch {
		line-height: 44px;
		text-align: center;
		color: #fff;
		font-size: 16px;
		letter-spacing: 5px;
		cursor: pointer;
    width: 110px;
    height: 44px;
    background: #1861EE;
    border-radius: 22px;
	}

	.el-dropdown-link {
		font-size: 18px;
		color: #000;
	}

	.downList {
		display: flex;
		align-items: center;
		background-color: #fff;
		height: 40px;
		// margin-left: 2px;
		// margin-right: 2px;
    margin-left: 10px;
		width: calc(100% - 80px);
		border-radius: 5px;
		position: relative;
	}

	.dialogBox {
		position: absolute;
		top: 60px;
		width: 800px;
		background-color: #fff;
		z-index: 99999 !important;
		border-radius: 5px;
		padding: 20px;
		box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
	}

	.downItem {
		width: 140px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	::v-deep .el-input__inner {
		height: 40px;
	}

	::v-deep .el-form-item {
		margin-bottom: 15px;
	}

	.hotList {
		color: #A5A7B1;
		display: flex;
		font-size: 14px;
		margin-top: 10px;
		align-items: center;
		white-space: nowrap;

		.hotItem {
			margin-right: 10px;
			border-radius: 5px;
			// border: 1px solid #ECECEC;
			padding: 2px 2px;
			font-size: 14px;
			white-space: nowrap;
			cursor: pointer;
		}

		.hotItem:hover {
			background-color: #0085FE;
			color: #fff;
			border: none;
		}
	}

	.sort {
		height: 426px;
		// overflow: hidden;
		width: 100%;
		border-radius: 12px;
		position: relative;

		.hoverList {
			position: absolute;
			right: -708px;
			top: 55px;
			background-color: #fff;
			width: 700px;
			height: 583px;
			z-index: 999;
			border: 4px solid #0085FE;

			.hoverTitle {
				font-size: 20px;
				font-weight: 600;
				padding: 20px 30px;
			}

			.hoverTag {
				padding: 20px 40px;
				display: flex;
				flex-wrap: wrap;

				.tagItem {
					width: 16%;
					margin-bottom: 30px;
					margin-right: 5%;
					font-weight: bold;
					font-size: 14px;
					color: #888;
					cursor: pointer;
				}

				.tagItem:nth-child(5n) {
					margin-right: 0;
				}

				.tagItem:hover {
					color: #0085FE;
				}

			}
		}


		.sortTop {
			color: #fff;
			height: 40px;
      background: #1A1A1A;
			border-top-right-radius: 12px;
			border-top-left-radius: 12px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 10px;

			.topLeft {
				font-weight: bold;
				font-size: 16px;
				letter-spacing: 2px;
			}

			.topRight {
				font-size: 12px;
				opacity: 0.8;
				cursor: pointer;
			}
		}
    .sortItemAll{
      background: linear-gradient(150deg, #D3F3FF, #FFFFFF);
      border-radius: 12px;
      margin-top: -10px
    }
		.sortItem {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 8px 10px;
			border-bottom: 1px solid #F6F6F6;
			.sortTitle {
				font-size: 16px;
				font-weight: 600;
			}

			.sortTag {
				color: #888;
				font-size: 12px;
				// font-weight: 600;
				margin-right: 6px;
				white-space: nowrap;
				display: inline-block;
				max-width: 100px;
				cursor: pointer;
			}

			.sortTag:hover {
				color: #0085FE;
			}

			// .sortTitle:hover {
			// 	color: #0085FE;
			// }
		}

	}

  .rightBtn{
    display: flex;
    .rightBtnBox{
      width: 110px;
      height: 44px;
      background: #1861EE;
      border-radius: 22px;
      text-align: center;
      line-height: 44px;
      color: #fff;
      margin-bottom: 5px;
      margin-left: 12px;
      cursor:pointer;
    }
  }

  .searchItem {
		min-width: 90px;
		text-align: center;
		white-space: nowrap;
		font-size: 14px;
		cursor: pointer;
    i{
      margin-left: 10px;
    }
    margin-left: 10px;
	}



	.searchName {
		padding: 0 20px;

		.searchLable {
			color: #000;
			opacity: 0.8;
			font-size: 14px;
		}

		.searchTag {
			font-size: 14px;
			margin-left: 10px;
		}
	}

	.typeTop {
		height: 35px;
		background-color: #FAFAFA;
		justify-content: space-between;

		::v-deep .el-input__inner {
			height: 35px;
		}

		.gameType {
			width: 150px;
			font-size: 14px;
			line-height: 35px;
			text-align: center;
			cursor: pointer;
		}

		.gameTypeAction {
			background-color: #0085FE;
			color: #fff;
		}
	}

	.selectList {
		margin-top: 10px;
		display: flex;
		flex-wrap: wrap;

		.selectItem {
			width: 190px;
			margin-bottom: 20px;
			// color: #0085FE;
			font-size: 14px;
		}

		.selectSpan {
			cursor: pointer;
		}

		.selectSpan:hover {
			background-color: #0085FE;
			color: #fff;
		}

	}

	.initialList {
		padding: 10px 0;
		display: flex;
		justify-content: space-between;

		.initialItem {
			font-size: 14px;

		}
	}

  ::v-deep.el-menu-item,
    .is-opened ::v-deep.el-submenu__title {
      background: #fff !important;
    }
    .searchBox {
      padding-bottom: 8px;
    }


    .el-menu-demo .el-menu-item {
    border-bottom-color: #ffffff !important;
    font-size: 16px;
  }

  ::v-deep .el-menu.el-menu--horizontal {
    border: none;
  }
</style>
