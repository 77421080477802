<template>
  <div style="background-color: #f8f8f8; min-height: 100vh">
    <div class="lableTop"></div>

    <div>
      <el-row class="content">
        <el-col :span="24">
          <div class="screen">
            <!-- <div class="cardBottom">
              <el-row class="content">
                <el-col :span="24">
                  <div class="bottom fl">
                    <el-button class="delete" @click="collectionDeleteBatch">取消收藏</el-button>
                    <span class="bottomText" @click="dialogVisible = true">清空收藏夹</span>
                  </div>
                </el-col>
              </el-row>
            </div> -->
            <div style="margin: 20px">
              <div class="collectTitle">
                我的足迹
                <span>请根据同一区服、同一商品类型、同一交易方式批量结算</span>
              </div>
              <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" @selection-change="handleCheckedCitiesChange">
                <el-table-column type="selection" width="35"> </el-table-column>
                <el-table-column width="360">
                  <template slot="header">
                    <div class="cardPrice">
                      <span class="selectAll">全选</span>
                      商品信息
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <div @click="handelGoDetail(scope.row)">
                      <div style="display: flex">
                        <img :src="getImgView(scope.row.pic)" style="width: 80px; height: 80px; border-radius: 12px" />
                        <div style="margin-left: 16px">
                          <div class="infoTitle el1">
                            <span v-if="scope.row.deliveryType == 1" class="infoImage">卖家发货</span>
                            <span v-else-if="scope.row.deliveryType == 2" style="background-color: rgb(183, 66, 255)" class="infoImage">平台发货</span>

                            <span>{{ scope.row.title }}</span>
                          </div>
                          <div class="idItem">
                            <span>游戏区服：</span>
                            <span style="color: #1d223c">{{ scope.row.districtName }}</span>
                          </div>
                          <div class="idItem">
                            <span>商品类型：</span>
                            <span style="color: #1d223c">{{ scope.row.commodityTypeName }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="game" width="220" align="center">
                  <template slot="header">
                    <div class="cardPrice">游戏</div>
                  </template>
                  <template slot-scope="scope">
                    <div class="cardPrice">{{ scope.row.gameId_dictText }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="name" width="150" align="right">
                  <template slot="header">
                    <div class="cardPrice">单价</div>
                  </template>
                  <template slot-scope="scope">
                    <div class="cardPrice">{{ scope.row.price }}</div>
                  </template>
                </el-table-column>
                <!-- <el-table-column prop="address" width="220">
									<template slot-scope="scope">
										<el-input-number v-model="num" @change="handleChange" :min="1" :max="10"
											label="描述文字"></el-input-number>
									</template>
								</el-table-column> -->
                <el-table-column prop="address" width="300" align="center">
                  <template slot="header">
                    <div class="cardPrice">库存</div>
                  </template>
                  <template slot-scope="scope">
                    <div class="cardPrice">{{ scope.row.num }}</div>
                  </template>
                </el-table-column>
                <!--                <el-table-column prop="address" width="200" align="center">
                  <template slot="header">
                    <div class="cardPrice">有效期</div>
                  </template>
                  <template slot-scope="scope">
                    <div class="cardPrice">30天</div>
                  </template>
                </el-table-column>-->
                <el-table-column prop="address" align="right">
                  <template slot="header">
                    <div class="cardPrice">操作</div>
                  </template>
                  <template slot-scope="scope">
                    <div style="text-align: right">
                      <span class="cardDelete" @click="collectionDelete(scope.row)" style="color: #0085ff">删除</span>
                      <!-- <span class="cardDelete" style="margin: 0 20px;" >|</span>
											<span class="cardDelete" style="color: #0085FF;" >删除</span> -->
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-dialog title="温馨提示" :visible.sync="dialogVisible" width="30%">
      <div class="dialogCenter fl">
        <img :src="require('@/static/image/index/Vector.png')" style="width: 76px; height: 76px" />
        <span class="deleteTitle">清空收藏夹</span>
        <span class="deleteDetaile">您确定要清空收藏夹吗？<br />清空后数据将无法恢复哦～</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          style="background-color: #ff5c00; color: #fff"
          @click="
            dialogVisible = false
            deleteAll()
          "
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <shopping-card></shopping-card>
  </div>
</template>

<script>
import { deleteExpire, browseList, browseDeleteBatch, browseDeleteAll } from '@/api/index.js'
  import layoutTop from '@/components/layoutTop.vue'
  import shoppingCard from '@/components/shoppingCart.vue'
  export default {
    components: {
      layoutTop,
      shoppingCard,
    },
    data() {
      return {
        num: 1,
        dialogVisible: false,
        tableData: [
          // 	{
          // 	date: '2016-05-03',
          // 	name: '￥888.88',
          // 	address: '上海市普陀区金沙江路 1518 弄'
          // }
        ],
        search: {
          pageNo: 1,
          pageSize: 10,
          title: '',
        },
        ids: [],
      }
    },
    created() {
      this.getList()
    },
    methods: {
      handelGoDetail(item) {
        this.$router.push('/gameDetaile/' + item.id)
      },
      getList() {
        browseList(this.search).then((res) => {
          if (res.code == 200) {
            this.tableData = res.result.records
          }
        })
      },
      // 单个取消收藏
      collectionDelete(item) {
        browseDeleteBatch({
          ids: item.id,
        }).then((res) => {
          if (res.code == 200) {
            this.message('删除成功', 'success')
            this.getList()
          }
        })
      },
      // 清除失效商品
      clearExpiration() {
        deleteExpire().then((res) => {
          if (res.code == 200) {
            this.message('过期商品清除成功', 'success')
            this.getList()
          }
        })
      },
      deleteAll() {
        browseDeleteAll().then((res) => {
          if (res.code == 200) {
            this.message('收藏夹清除成功', 'success')
            this.getList()
          }
        })
      },
      handleCheckedCitiesChange(value) {
        this.ids = []
        value.forEach((item) => {
          this.ids.push(item.id)
        })
      },
      // 取消多个
      collectionDeleteBatch() {
        if (this.ids.length == 0) {
          this.message('请选择数据', 'error')
          return
        }
        browseDeleteBatch({
          ids: this.ids.join(','),
        }).then((res) => {
          if (res.code == 200) {
            this.message('删除成功', 'success')
            this.getList()
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .lableTop {
    position: relative;
    padding-top: 20px;
    .lableImage {
      width: 100%;
      height: 150px;
      position: absolute;
    }
  }

  .lableBg {
    margin-top: 30px;
    margin-bottom: 20px;
    // height: 150px;
    display: flex;
    cursor: pointer;

    .lableTitle {
      font-weight: bold;
      font-size: 24px;
      color: #ffffff;
    }

    .lableDetaile {
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      margin-top: 12px;
    }
  }

  .screen {
    color: #777a8a;
    background: #ffffff;
    // margin-top: -80px;
    border-radius: 20px 20px 20px 20px;
    margin-bottom: 106px;
    padding-bottom: 20px;
    padding-top: 1px;
    // height: 700px;
    // padding: 0 0 24px;
  }

  .infoTitle {
    .infoAccount {
      font-size: 12px;
      background: #ff5c00;
      border-radius: 4px;
      color: #fff;
      padding: 4px 6px;
    }

    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    color: #000000;
    width: 450px;
  }

  .idItem {
    font-weight: 400;
    font-size: 14px;
    color: #a5a7b1;
    margin-top: 4px;
  }

  .cardPrice {
    font-weight: bold;
    font-size: 14px;
    color: #1d223c;
  }

  .cardDelete {
    font-weight: 400;
    font-size: 16px;
    color: #a5a7b1;
  }

  .toPay {
    width: 124px;
    height: 46px;
    background: #dddddd;
    border-radius: 6px;
    text-align: center;
    line-height: 46px;

    font-weight: 600;
    font-size: 18px;
    color: #ffffff;
  }

  ::v-deep .el-table {
    border: 1px solid #fafafa;
    border-radius: 6px;
  }

  ::v-deep .el-table th {
    background: #fafafa;
  }

  .cardTop {
    margin: 0 20px 20px;
    height: 64px;
    background: #fafafa;
    border-radius: 6px 6px 6px 6px;

    .cardTitle {
      font-weight: 600;
      font-size: 20px;
      color: #000000;
      margin-left: 32px;
    }

    .th1 {
      margin-left: 20px;
      width: 630px;
    }

    .th2 {
      width: 220px;
    }

    .th3 {
      width: 220px;
    }

    .th4 {
      width: 90px;
    }

    .th5 {
      width: 200px;
      text-align: right;
    }

    .th6 {
      width: 140px;
      text-align: right;
    }
  }

  .cardBottom {
    height: 80px;
    background: #ffffff;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 99;

    .bottom {
      height: 40px;
      padding: 20px;

      .delete {
        margin-left: 20px;
        font-size: 16px;
      }

      .bottomText {
        font-weight: 400;
        font-size: 16px;
        color: #777a8a;
        margin-left: 20px;
      }

      .bottomText:hover {
        color: #0094ff;
      }
    }
  }

  .dialogCenter {
    border-top: 1px solid #fafafa;
    border-bottom: 1px solid #fafafa;
    flex-direction: column;
    padding: 30px 0;

    .deleteTitle {
      font-weight: 600;
      font-size: 20px;
      color: #1d223c;
      text-align: center;
      margin: 20px;
    }

    .deleteDetaile {
      font-weight: 400;
      font-size: 16px;
      color: #777a8a;
      text-align: center;
      line-height: 30px;
    }
  }

  .selectAll {
    font-weight: 400;
    font-size: 16px;
    color: #1d223c;
    margin-right: 32px;
  }

  .infoImage {
    font-size: 12px;
    background: #0085ff;
    border-radius: 4px;
    color: #fff;
    padding: 4px 6px;
    margin-left: 5px;
  }
  .collectTitle {
    font-size: 30px;
    font-weight: 600;
    color: #000000;
    span {
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 18px;
      color: #999999;
      border-left: 2px solid #929191;
      padding-left: 12px;
      margin-left: 12px;
    }
  }
</style>
