<template>
  <div>
    <div v-if="type == 'recharge'">
      <div class="roleTitle fl" style="justify-content: space-between">
        <span>我要充值</span>
        <div class="fl" @click="type = ''">
          <img :src="require('@/static/image/mine/back.png')" style="width: 24px; height: 24px" />
          <span class="balance" style="margin: 0 60px 0 4px">返回上一级</span>
        </div>
      </div>
      <div style="margin-top: 68px">
        <div class="fl formItem">
          <div class="submitLable">
            <span class="lableText">充值金额：</span>
          </div>
          <div class="submitValue">
            <el-input v-prevent-space v-model="payNumber" type="number" placeholder="请输入充值金额"></el-input>
          </div>
        </div>
        <div class="fl formItem">
          <div class="submitLable">
            <span class="lableText">支付方式：</span>
          </div>
          <div style="position: relative">
            <el-select :popper-append-to-body="false" class="submitValue" v-model="payValue" placeholder="请选择">
              <el-option v-for="item in payType" :key="item.value" :label="item.text" :value="item.value"> </el-option>
            </el-select>
          </div>
        </div>

        <div class="fl formItem">
          <div class="submitLable">&emsp;</div>
          <div class="submitValue">
            <el-button type="primary" @click="configWithdraw">确认充值</el-button>
          </div>
        </div>
      </div>
    </div>

    <div style="overflow-y: auto; height: 1100px; scrollbar-width: none" v-else>
      <div class="mine">
        <!-- <div class="mineTop fl" style="margin-bottom: 40px">
          <div class="mineInfo">
            <img class="infoImage" :src="users.portrait || require('@/static/image/index/profile.png')" />
            <span class="infoName el1">{{ users.name }}</span>
            <span class="infoCode"
              >账号：{{ users.account }}
              <img v-clipboard:copy="users.account" v-clipboard:success="onCopy" :src="require('@/static/image/index/copy.png')" style="width: 16px; height: 16px; margin-left: 4px" />
            </span>
            <div class="auths">
              <div class="infoAuth fl" v-if="users.idCardAudit == 1">
                <img :src="require('@/static/image/index/auth2.png')" style="width: 16px; height: 16px; margin-right: 3px" />
                <span>已实名认证</span>
              </div>
              <div class="infoAuth fl">
                <img :src="require('@/static/image/index/auth2.png')" style="width: 16px; height: 16px; margin-right: 3px" />
                <span>已手机认证</span>
              </div>
            </div>
          </div>
          <div class="wallet">
            <div class="walletTop fl">
              <span class="walletText">我的钱包</span>
              <div>
                <el-button class="withdraw" @click="$emit('withdraw', 99)" type="danger" plain>充值钻石</el-button>
                <el-button class="withdraw" @click="$emit('withdraw', 16)" type="primary" plain>余额提现</el-button>
              </div>
            </div>
            <div class="balanceList fl">
              <div class="balanceItem">
                <span class="balanceTitle">我的余额(元)</span>
                <span class="balanceNumber">{{ users.earningsBalance }}</span>
                <div>
                  <span class="balanceDetail" @click="$emit('withdraw', 16)">提现</span>
                </div>
              </div>
              <div class="balanceItem" @click="jump('1')">
                <span class="balanceTitle">我的钻石(个)</span>
                <span class="balanceNumber">{{ users.diamondBalance }}</span>
                <span class="balanceDetail">查看明细</span>
              </div>
              <div class="balanceItem" @click="jump('2')">
                <span class="balanceTitle">待解冻(元)</span>
                <span class="balanceNumber">{{ users.freezeBalance }}</span>
                <span class="balanceDetail">查看明细</span>
              </div>
            </div>
          </div>
        </div> -->

        <div class="mineNew">
          <div class="topBox">
            <div>{{ users.name }}</div>
            <img style="cursor: pointer" @click="handelGo(1)" :src="require('@/static/image/newImage/icon55.png')" alt="" />
            <img style="cursor: pointer" @click="handelGo(2)" :src="require('@/static/image/newImage/icon66.png')" alt="" />
          </div>
          <div class="titleBox">
            <div :class="users.idCardAudit == 1 ? 'infoYes' : 'infoNo'">
              <img v-if="users.idCardAudit == 1" :src="require('@/static/image/newImage/icon69.png')" alt="" />
              <img v-else :src="require('@/static/image/newImage/icon67.png')" alt="" />
              已实名认证
            </div>
            <div :class="users.idCardAudit == 1 ? 'infoYes' : 'infoNo'">
              <img v-if="users.idCardAudit == 1" :src="require('@/static/image/newImage/icon70.png')" alt="" />
              <img v-else :src="require('@/static/image/newImage/icon68.png')" alt="" />
              已手机认证
            </div>
          </div>
          <div style="display: flex; align-items: center; justify-content: space-between">
            <div class="msgBox">
              <div class="msgTtile">账户余额(元)</div>
              <div class="msgNum">{{ users.earningsBalance }}</div>
              <div class="msgBtn" @click="$emit('withdraw', 16)">提现</div>
            </div>

            <div class="msgBox">
              <div class="msgTtile">我的钻石(个)</div>
              <div class="msgNum">{{ users.diamondBalance }}</div>
              <div class="msgBtn" @click="jump('1')">查看明细</div>
            </div>

            <div class="msgBox">
              <div class="msgTtile">待解冻(元)</div>
              <div class="msgNum">{{ users.freezeBalance }}</div>
              <div class="msgBtn" @click="jump('2')">查看明细</div>
            </div>
          </div>
        </div>

        <div class="mineOrder">
          <div class="orderTop fl">
            <div class="fl">
              <span class="orderTitle">我是买家</span>
              <div class="fl" style="padding-right: 24px">
                <div class="fl" style="margin-left: 24px">
                  <span class="credit">成交笔数:</span>
                  <span class="credit">{{ users.dealNum }}</span>
                </div>
                <div class="fl" style="margin-left: 24px">
                  <span class="credit">成交率:</span>
                  <span class="credit">{{ users.dealRate }}%</span>
                </div>
              </div>
            </div>
            <div style="font-family: Microsoft YaHei; font-weight: 400; font-size: 14px; color: #1861ee; margin-right: 20px; cursor: pointer" @click="$router.push('/mineIndex/8')">查看我的订单</div>
          </div>
          <div class="orderList">
            <div class="orderNew">
              <img :src="require('@/static/image/newImage/icon75.png')" style="width: 70px; height: 70px" />
              <div class="orderTextNew">
                <div>待支付</div>
                <div>
                  <span>{{ users.notPayNum }}</span>
                </div>
                <div class="iconBtn" @click="toOrder(1)">查看订单></div>
              </div>
            </div>

            <div class="orderNew">
              <img :src="require('@/static/image/newImage/icon76.png')" style="width: 70px; height: 70px" />
              <div class="orderTextNew">
                <div>交易中</div>
                <div>
                  <span>{{ isNaN(users.payNum + users.notReceiveNum)?'':users.payNum + users.notReceiveNum }} </span>
                </div>
                <div class="iconBtn" @click="toOrder(2)">查看订单></div>
              </div>
            </div>
            <!-- <div class="orderItem">
              <img :src="require('@/static/image/mine/mine1.png')" style="width: 50px; height: 50px" />
              <div class="orderText">
                <div>
                  <span style="color: #ff5c00; font-size: 24px; font-weight: bold">{{ users.notPayNum }}</span>
                  <span>单</span>
                </div>
                <div>待支付</div>
              </div>
            </div>
            <div class="orderItem">
              <img :src="require('@/static/image/mine/mine1.png')" style="width: 50px; height: 50px" />
              <div class="orderText">
                <div>
                  <span style="color: #1d223c; font-size: 20px; font-weight: bold">{{ users.payNum }}</span>
                  <span>单</span>
                </div>
                <div>待发货</div>
              </div>
            </div>
            <div class="orderItem">
              <img :src="require('@/static/image/mine/mine1.png')" style="width: 50px; height: 50px" />
              <div class="orderText">
                <div>
                  <span style="color: #1d223c; font-size: 20px; font-weight: bold">{{ users.notReceiveNum }}</span>
                  <span>单</span>
                </div>
                <div>已发货</div>
              </div>
            </div>
            <div class="orderItem">
              <img :src="require('@/static/image/mine/mine1.png')" style="width: 50px; height: 50px" />
              <div class="orderText">
                <div>
                  <span style="color: #1d223c; font-size: 20px; font-weight: bold">{{ users.allNum }}</span>
                  <span>单</span>
                </div>
                <div>全部订单</div>
              </div>
            </div> -->
          </div>
        </div>

        <div class="mineOrder">
          <div class="orderTop fl">
            <div class="fl">
              <span class="orderTitle1">我是卖家</span>
              <div class="fl" style="padding-right: 24px">
                <div class="fl" style="margin-left: 24px">
                  <span class="credit">成交笔数:</span>
                  <span class="credit">{{ users.dealNumSale }}</span>
                </div>
                <div class="fl" style="margin-left: 24px">
                  <span class="credit">成交率:</span>
                  <span class="credit">{{ users.dealRateSale }}%</span>
                </div>
              </div>
            </div>
            <div style="display: flex">
              <div style="font-family: Microsoft YaHei; font-weight: 400; font-size: 14px; color: #1861ee; margin-right: 20px; cursor: pointer" @click="$router.push('/mineIndex/12')">查看发布商品</div>
              <div style="font-family: Microsoft YaHei; font-weight: 400; font-size: 14px; color: #1861ee; margin-right: 20px; cursor: pointer" @click="$router.push('/mineIndex/13')">查看我的订单</div>
            </div>
          </div>

          <div class="orderList">
            <div class="orderNew1" v-for="(item, index) in orderListNew" :key="index">
              <img :src="item.url" style="width: 70px; height: 70px" />
              <div class="orderTextNew1">
                <div>{{ item.title }}</div>
                <div>
                  <span v-show="index == 0">{{ users.payNumSale }}</span>
                  <span v-show="index == 1">{{ users.notReceiveNumSale }}</span>
                  <span v-show="index == 2">{{ users.reductionNumSale }}</span>
                  <span v-show="index == 3">{{ users.allNumSale }}</span>
                </div>
                <div class="iconBtn" @click="toOrder(index+10)">查看订单></div>
              </div>
            </div>

            <!-- <div class="orderItem">
              <img :src="require('@/static/image/mine/mine6.png')" style="width: 50px; height: 50px" />
              <div class="orderText">
                <div>
                  <span style="color: #1d223c; font-size: 20px; font-weight: bold">{{ users.notReceiveNumSale }}</span>
                  <span>单</span>
                </div>
                <div>已发货</div>
              </div>
            </div>
            <div class="orderItem">
              <img :src="require('@/static/image/mine/mine7.png')" style="width: 50px; height: 50px" />
              <div class="orderText">
                <div>
                  <span style="color: #1d223c; font-size: 20px; font-weight: bold">{{ users.reductionNumSale }}</span>
                  <span>单</span>
                </div>
                <div>已完成</div>
              </div>
            </div>
            <div class="orderItem">
              <img :src="require('@/static/image/mine/mine8.png')" style="width: 50px; height: 50px" />
              <div class="orderText">
                <div>
                  <span style="color: #1d223c; font-size: 20px; font-weight: bold">{{ users.allNumSale }}</span>
                  <span>单</span>
                </div>
                <div>全部订单</div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="mineOrder">
          <div class="orderTop fl">
            <div class="fl">
              <span class="orderTitle1">我的收藏</span>
            </div>
          </div>
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" :header-cell-style="{ background: '#F2F2F2' }" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="商品信息" width="400">
              <template slot-scope="scope">
                <div class="infoBox">
                  <img v-if="scope.row.gameCommodityCategory!==1" :src="scope.row.pic" alt="" />
                  <div>
                    <div class="div1">
                      <span v-if="scope.row.deliveryType == 1" class="infoImage">卖家发货</span>
                      <span v-else-if="scope.row.deliveryType == 2" style="background-color: rgb(183, 66, 255)" class="infoImage">平台发货</span>
                      <div @click="gotoDetaile(scope.row)" style="cursor: pointer;display: inline-block">{{ scope.row.title }}</div>
                    </div>
                    <div class="div2"><span>游戏区服：</span> {{ scope.row.districtName }}</div>
                    <div class="div2"><span>商品类型：</span> {{ scope.row.commodityTypeName }}</div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="price" label="单价">
              <template slot-scope="scope">
                <div class="div1">￥{{ scope.row.price }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="num" label="库存">
              <template slot-scope="scope">
                <div class="div1">{{ scope.row.num }}</div>
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" witch="100">
              <template slot-scope="scope">
                <div @click="collectionDelete(scope.row)" style="font-family: Microsoft YaHei; font-weight: 400; font-size: 18px; color: #1861ee; cursor: pointer">取消收藏</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <!-- <div class="infoList">
        <div class="infoItem fl">
          <div class="fl">
            <div style="flex-direction: column; display: flex; width: 500px">
              <span class="title">个人信息</span>
              <span class="detaile">去完善个人信息</span>
            </div>
          </div>
          <div class="fl">
            <el-button @click="menuSelect(27)">查看个人信息</el-button>
          </div>
        </div>
        <div class="infoItem fl">
          <div class="fl">
            <div style="flex-direction: column; display: flex; width: 500px">
              <span class="title">实名认证</span>
              <span class="detaile">通过实名认证后，提升账户安全级别，同时获得更多的会员权利</span>
            </div>
            <div class="itemType">
              <i v-if="realNameType === 2" style="color: #19d972; margin-right: 8px" class="el-icon-success"></i>
              <i v-else style="color: #ff800e; margin-right: 8px" class="el-icon-warning"></i>
              <span v-if="realNameType === 2">已实人</span>
              <span v-else-if="realNameType === 1">未实人</span>
              <span v-else>未实名</span>
            </div>
          </div>
          <div class="fl">
            <div class="itemType" style="margin-right: 50px" v-if="isReal">
              <span>{{ nameEncryption(realInfo.realName) }}</span>
              <span>{{ idCardEncryption(realInfo.idCard) }}</span>
            </div>
            <el-button @click="menuSelect(28)">查看</el-button>
          </div>
        </div>
        <div class="infoItem fl">
          <div class="fl">
            <div style="flex-direction: column; display: flex; width: 500px">
              <span class="title">登录密码</span>
              <span class="detaile">互联网账户存在被盗风险，建议您定期更换密码以保护账户安全</span>
            </div>
            <div class="itemType">
              <i style="color: #19d972; margin-right: 8px" class="el-icon-success"></i>
              <span>已设置</span>
            </div>
          </div>
          <div>
            <el-button @click="menuSelect(29)">修改</el-button>
          </div>
        </div>
        <div class="infoItem fl">
          <div class="fl">
            <div style="flex-direction: column; display: flex; width: 500px">
              <span class="title">支付密码</span>
              <span class="detaile">保障账户资金安全，建议您定期更换新的支付密码，提高安全险</span>
            </div>
            <div class="itemType">
              <i v-if="$store.state.userInfo.hasPayPwd != ''" style="color: #19d972; margin-right: 8px" class="el-icon-success"></i>
              <i v-else style="color: #ff800e; margin-right: 8px" class="el-icon-warning"></i>
              <span v-if="$store.state.userInfo.hasPayPwd != ''">已设置</span>
              <span v-else>未设置</span>
            </div>
          </div>
          <div>
            <el-button @click="menuSelect(30)">修改</el-button>
          </div>
        </div>

        <div class="infoItem fl">
          <div class="fl">
            <div style="flex-direction: column; display: flex; width: 500px">
              <span class="title">手机绑定</span>
              <span class="detaile">可以选择手机验证码作为身份验证方式，接受交易通知，提高账户安全险</span>
            </div>
            <div class="itemType">
              <i style="color: #19d972; margin-right: 8px" class="el-icon-success"></i>
              <span>已绑定</span>
            </div>
          </div>
          <div class="fl">
            <div class="itemType" style="margin-right: 50px">
              <span>{{ phoneEncryption(bindPhone) }}</span>
            </div>
            <el-button @click="menuSelect(31)">修改</el-button>
          </div>
        </div>

        <div class="infoItem fl">
          <div class="fl">
            <div style="flex-direction: column; display: flex; width: 500px">
              <span class="title">注销账号</span>
              <span class="detaile">注销账号信息，请谨慎</span>
            </div>
          </div>
          <div class="fl">
            <div class="itemType" style="margin-right: 50px"></div>
            <el-button @click="deleteUser">注销</el-button>
          </div>
        </div>
      </div> -->
    </div>

    <!-- <div class="popUp" v-if="realShow">
      <div style="width: 100%; justify-content: center; position: relative" class="fl">
        <img style="width: 522px; height: 166px; position: absolute; top: 100px" :src="require('@/static/image/index/payImage.png')" />
        <div class="popCount">
          <img class="close" @click="closePay" :src="require('@/static/image/index/close.png')" />
          <img style="width: 240px; height: 240px; margin-top: 40px" :src="payImage" alt="" />
          <div class="popText">使用 支付宝 扫码支付<br />支付即视为你同意 <span style="color: #0085ff">相关协议</span> 条例</div>
          <div class="payOrder">
            <span>实付金额： </span>
            <div>
              <span style="color: #ff5c00; font-weight: bold; font-size: 36px">{{ payNumber }}</span>
              <span style="color: #ff5c00">元</span>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
  import { userCenter, orderDepositPay, orderDepositQueryById, realInfo, fush } from '@/api/index.js'
  import { collectionDelete, collectionList } from '@/api/index.js'
  export default {
    data() {
      return {
        tableData: [],
        multipleSelection: [],
        realShow: false,
        users: {},
        type: '',
        timeInterval: null,
        payNumber: '',
        payValue: 5,
        payImage: '',
        orderListNew: [
          {
            title: '待发货',
            num: '0',
            url: require('@/static/image/newImage/icon78.png'),
          },
          {
            title: '已发货',
            num: '0',
            url: require('@/static/image/newImage/icon77.png'),
          },
          {
            title: '已完成',
            num: '0',
            url: require('@/static/image/newImage/icon79.png'),
          },
          {
            title: '全部订单',
            num: '0',
            url: require('@/static/image/newImage/icon80.png'),
          },
        ],
        msgList: [
          {
            title: '账户余额(元)',
            num: '0',
            btnText: '提现',
          },
          {
            title: '保证金余额(元)',
            num: '0',
            btnText: '查看明细',
          },
          {
            title: '赔付金余额(元)',
            num: '0',
            btnText: '查看明细',
          },
        ],
        payType: [
          {
            text: '支付宝',
            value: 5,
          },
          // {
          // 	text: '微信',
          // 	value: 2
          // },
          // {
          // 	text: '银行卡',
          // 	value: 3
          // },
          // {
          // 	text: '余额',
          // 	value: 4
          // },
        ],
        isReal: false,
        realNameType: 0,
        realInfo: {},
        // payPassword: '',
        openId: '',
        bindPhone: '',
      }
    },
    created() {
      userCenter().then((res) => {
        if (res.code == 200) {
          this.users = res.result
        }
      })

      // 判断是否实名
      realInfo().then((res) => {
        if (res.result?.faceAudit === 1) {
          this.realNameType = 2
        } else if (res.result?.idCardAudit === 1) {
          this.realNameType = 1
        } else {
          this.realNameType = 0
        }
      })
      fush().then((res) => {
        if (res.result.openId != undefined && res.result.openId != null && res.result.openId != '') {
          this.openId = res.result.openId
        }
        this.bindPhone = res.result.phone
      })
      this.getList()
    },
    beforeDestroy() {
      clearInterval(this.timeInterval)
      this.timeInterval = null
    },
    methods: {
      handelGo(num) {
        if (num == 1) {
          this.$router.push('/mineIndex/29')
        } else if (num == 2) {
          this.$router.push('/mineIndex/30')
        }
      },
      getList() {
        collectionList({ }).then((res) => {
          if (res.code == 200) {
            this.tableData = res.result.records
          }
        })
      },
      collectionDelete(item) {
        collectionDelete({
          id: item.id,
        }).then((res) => {
          if (res.code == 200) {
            this.message('操作成功', 'success')
            this.getList()
          }
        })
      },
      handleSelectionChange(val) {
        this.multipleSelection = val
      },
      jump(type) {
        localStorage.setItem('money', type)
        this.$router.push('/mineIndex/15')
      },
      toOrder(type) {
        switch (type) {
          case 1:
            this.$router.push('/mineIndex/8?type=0')
            break
          case 2:
            this.$router.push('/mineIndex/8?type=100')
            break
          case 10:
            this.$router.push('/mineIndex/13?type=1')
            break
          case 11:
            this.$router.push('/mineIndex/13?type=3')
            break
          case 12:
            this.$router.push('/mineIndex/13?type=4')
            break
          case 13:
            this.$router.push('/mineIndex/13')
            break
        }
      },
      menuSelect(e) {
        this.$emit('withdraw', e)
      },
      configWithdraw() {
        if (this.payNumber == '' || this.payNumber == 0) {
          this.message('请输入充值金额', 'error')
          return
        }
        orderDepositPay({
          depositType: 9,
          money: this.payNumber,
          payType: this.payValue,
        }).then((res) => {
          if (res.code == 200) {
            this.payImage = res.result.qrcode
            this.getPaySuccess(res.result.id)
            this.realShow = true
          }
        })
      },
      getPaySuccess(id) {
        this.timeInterval = setInterval(() => {
          orderDepositQueryById({
            id: id,
          }).then((res) => {
            if (res.result.status == 1) {
              clearInterval(this.timeInterval)
              this.timeInterval = null
              this.realShow = false
              this.type = ''
              this.getInfo()
            }
          })
        }, 3000)
      },
      closePay() {
        clearInterval(this.timeInterval)
        this.timeInterval = null
        this.realShow = false
      },
      getInfo() {
        userCenter().then((res) => {
          if (res.code == 200) {
            this.users = res.result
          }
        })
      },
      baozheng() {
        localStorage.setItem('money', type)
      },
      peifu() {},

      deleteUser() {
        this.$confirm('注销账号会清空当前账号名下所有记录和数据包括余额，是否确认注销?', '账号注销', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.$store.dispatch('deleteUser')
        })
      },

      gotoDetaile(row) {
        this.$router.push('/gameDetaile/' + row.id)
      }
    },
  }
</script>

<style scoped lang="scss">
  .mine {
    padding: 0 20px;
    padding-right: 0;
  }

  .mineInfo {
    background-image: url('../../../static/image/mine/mineBg.png');
    width: 240px;
    height: 212px;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .infoImage {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 6px solid #86d2ff;
    }

    .infoName {
      width: 80%;
      font-weight: 600;
      font-size: 16px;
      color: #ffffff;
      margin-top: 10px;
      text-align: center;
    }

    .infoCode {
      font-weight: 400;
      font-size: 12px;
      color: #ffffff;
      display: flex;
      align-items: center;
      margin-top: 4px;
    }

    .auths {
      display: flex;
      margin-top: 14px;
    }

    .infoAuth {
      width: 96px;
      height: 30px;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #ffffff;

      justify-content: center;

      font-weight: 400;
      font-size: 12px;
      color: #ffffff;
      margin-right: 5px;
    }
  }

  .wallet {
    width: 700px;
    height: 188px;
    background: linear-gradient(180deg, #ffffff 0%, #f9f9f9 100%);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 0px 20px 20px 0px;

    .walletTop {
      justify-content: space-between;
      height: 55px;
      padding: 0 28px;
      border-bottom: 1px solid #ececec;

      .withdraw {
        width: 90px;
        height: 39px;

        font-weight: 600;
        font-size: 12px;
      }

      .walletText {
        font-weight: 600;
        font-size: 16px;
        color: #1d223c;
      }
    }
  }

  .balanceList {
    .balanceItem {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 123px;
      width: 304px;
      padding-left: 28px;

      .balanceTitle {
        font-weight: 400;
        font-size: 16px;
        color: #a5a7b1;
      }

      .balanceNumber {
        font-weight: bold;
        font-size: 30px;
        color: #1d223c;
      }

      .balanceNumber:hover {
        color: #ff5c00;
      }

      .balanceDetail {
        font-weight: 400;
        font-size: 14px;
        color: #a5a7b1;
        cursor: pointer;
      }

      .balanceDetail:hover {
        color: #0085ff;
      }
    }
  }
  .mineNew {
    border: 1px solid #dedede;
    background-color: #fff;
    width: 940px;
    border-radius: 8px;
    margin-bottom: 20px;
    box-sizing: border-box;
    padding: 20px;
    .topBox {
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 24px;
      color: #1a1a1a;
      display: flex;
      align-items: center;
      div {
        margin-right: 20px;
      }
      img {
        width: 19px;
        height: 20px;
        object-fit: contain;
        margin-left: 15px;
      }
    }
    .titleBox {
      display: flex;
      margin-top: 10px;
      .infoNo {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        border-radius: 6px;
        border: 1px solid #999999;
        padding: 4px 10px;
        display: flex;
        align-items: center;
        margin-right: 10px;
        img {
          margin-right: 5px;
        }
      }
      .infoYes {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #13ce66;
        border-radius: 6px;
        border: 1px solid #13ce66;
        padding: 4px 10px;
        display: flex;
        align-items: center;
        margin-right: 10px;
        img {
          margin-right: 5px;
        }
      }
    }
  }
  .msgBox {
    margin-top: 10px;
    padding-left: 20px;
    flex: 1;
    .msgTitle {
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 16px;
      color: #666666;
    }
    .msgNum {
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 40px;
      color: #666665;
      margin: 5px 0;
    }
    .msgBtn {
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 16px;
      color: #666666;
      cursor: pointer;
    }
  }

  .mineOrder {
    border: 1px solid #dedede;
    width: 940px;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  .orderNew {
    display: flex;
    margin-top: 30px;
    margin-left: 50px;
    margin-bottom: 30px;
    padding-right: 50px;
    margin-right: 50px;
    .iconBtn {
      cursor: pointer;
      position: absolute;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 12px;
      color: #666666;
      right: -70px;
      top: 10px;
    }
    .orderTextNew {
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 18px;
      color: #333333;
      margin-left: 20px;
      position: relative;

      span {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 36px;
        color: #1a1a1a;
      }
    }
  }

  .orderNew1 {
    display: flex;
    margin-top: 30px;
    margin-left: 20px;
    margin-bottom: 30px;
    padding-right: 20px;
    margin-right: 45px;
    .iconBtn {
      cursor: pointer;
      position: absolute;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 12px;
      color: #666666;
      right: -65px;
      top: 10px;
    }
    .orderTextNew1 {
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 18px;
      color: #333333;
      margin-left: 20px;
      position: relative;

      span {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 36px;
        color: #1a1a1a;
      }
    }
  }

  .orderTop {
    width: 940px;
    height: 64px;
    background: #ebebeb;
    border-radius: 8px 8px 0px 0px;
    border-bottom: 1px solid #dedede;
    justify-content: space-between;

    .orderTitle {
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 20px;
      color: #1a1a1a;
      padding-left: 24px;
      border-left: 5px solid #ff9b20;
    }
    .orderTitle1 {
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 20px;
      color: #1a1a1a;
      padding-left: 24px;
      border-left: 5px solid #1861ee;
    }

    .credit {
      font-weight: 400;
      font-size: 14px;
      color: #a5a7b1;
      margin-right: 5px;
    }
  }

  .orderList {
    display: flex;

    .orderItem {
      width: 208px;
      height: 140px;
      display: flex;
      align-items: center;
      padding-left: 24px;

      .orderText {
        margin-left: 16px;
        font-weight: 400;
        font-size: 14px;
        color: #a5a7b1;
      }
    }
  }

  .roleTitle {
    font-weight: 600;
    font-size: 20px;
    color: #1d223c;
    width: 1286px;
    border-bottom: 1px solid #ececec;
    height: 87px;
    line-height: 87px;
    padding-left: 42px;
  }

  .balance {
    font-weight: 400;
    font-size: 16px;
    color: #a5a7b1;
  }

  .noAccount {
    width: 1232px;
    // height: 255px;
    padding: 42px 0;
    background: #f8f8f8;
    border-radius: 12px 12px 12px 12px;
    justify-content: space-between;
    margin-left: 42px;
    margin-top: 68px;

    .accountLeft {
      display: flex;
      flex-direction: column;
      margin-left: 40px;
    }

    .account1 {
      font-weight: 600;
      font-size: 26px;
      color: #1d223c;
    }

    .account2 {
      font-weight: 400;
      font-size: 20px;
      color: #a5a7b1;
      margin-top: 16px;
    }
  }

  .toAdd {
    width: 208px;
    height: 52px;
    border-radius: 8px 8px 8px 8px;
    border: 2px solid #0085ff;
    font-weight: 600;
    font-size: 18px;
    color: #0085ff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 36px;
  }

  .toAdd1 {
    width: 210px;
    height: 54px;
    border-radius: 8px 8px 8px 8px;
    background-color: #0085ff;
    font-weight: 600;
    font-size: 18px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 36px;
    margin-left: 42px;
    margin-right: 12px;
  }

  .remind {
    margin-left: 42px;
    margin-top: 50px;

    .remind1 {
      font-weight: 600;
      font-size: 22px;
      color: #000000;
      margin-bottom: 32px;
    }

    .remind2 {
      font-weight: 400;
      font-size: 18px;
      color: #777a8a;
      margin-bottom: 28px;
    }
  }

  .formItem {
    margin-bottom: 30px;

    .submitLable {
      width: 200px;
      text-align: right;

      .lableText {
        font-weight: 400;
        font-size: 16px;
        color: #a5a7b1;
        line-height: 30px;
        position: relative;
      }
    }

    .submitValue {
      width: 336px;
      margin: 0 20px;
      position: relative;
    }

    .lableNumber {
      margin: 0 20px;

      font-weight: bold;
      font-size: 24px;
      color: #ff5c00;
    }

    .submitRemark {
      width: 40%;
      color: #a5a7b1;
    }
  }

  .submitForm {
    margin-top: 50px;
  }

  .lablePhone {
    font-weight: 400;
    font-size: 18px;
    color: #1d223c;
  }

  .getCode {
    position: absolute;
    right: 5px;
    line-height: 40px;
    font-size: 14px;
    color: #0085ff;
  }

  .getCode::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 20px;
    left: -10px;
    top: 10px;
    background-color: #ececec;
  }

  .typeList {
    display: flex;
    justify-content: space-between;
  }

  .withType {
    width: 160px;
    height: 46px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #dedede;
    font-weight: 400;
    font-size: 18px;
    color: #1d223c;
    line-height: 46px;
    text-align: center;
  }

  .action {
    background: rgba(0, 133, 255, 0.1);
    border: 1px solid #0085ff;
    color: #0085ff;
    position: relative;
  }

  .actionImage {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .delete {
    width: 88px;
    height: 37px;
    border-radius: 6px 6px 6px 6px;
    border: 1px solid #ff3c3c;
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 400;
    font-size: 18px;
    margin-left: 12px;
    color: #ff3c3c;
  }

  .dialogCenter {
    border-top: 1px solid #fafafa;
    border-bottom: 1px solid #fafafa;
    flex-direction: column;
    padding: 30px 0;

    .deleteTitle {
      font-weight: 600;
      font-size: 20px;
      color: #1d223c;
      text-align: center;
      margin: 20px;
    }

    .deleteDetaile {
      font-weight: 400;
      font-size: 16px;
      color: #777a8a;
      text-align: center;
      line-height: 30px;
    }
  }

  .payment-password-input {
    margin-top: 30px;
  }

  .payment-password-input input {
    width: 40px;
    height: 40px;
    margin: 0;
    padding: 10px;
    border: 1px solid #ccc;
    text-align: center;
  }

  .payment-password-input input:focus {
    border-color: #007bff;
  }

  .payOrder {
    width: 394px;
    height: 100px;
    background: #f6f8fa;
    border-radius: 12px 12px 12px 12px;
    margin-top: 40px;
    padding: 0 24px;
    color: #a5a7b1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .close {
    position: absolute;
    top: -100px;
    right: 10px;
    z-index: 999;
  }

  .popUp {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.3);
    overflow: hidden;
  }

  .popCount {
    z-index: 9999;
    position: relative;
    margin-top: 220px;
    display: flex;
    flex-direction: column;
    width: 522px;
    border-radius: 24px;
    align-items: center;
    background-color: #fff;
    padding-bottom: 30px;

    .popText {
      width: 264px;
      font-weight: 400;
      font-size: 16px;
      color: #777a8a;
      text-align: center;
      margin-top: 20px;
    }

    .payOrder {
      width: 394px;
      height: 100px;
      background: #f6f8fa;
      border-radius: 12px 12px 12px 12px;
      margin-top: 40px;
      padding: 0 24px;
      color: #a5a7b1;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .close {
    position: absolute;
    top: -100px;
    right: 10px;
    z-index: 999;
  }

  .realSubmit {
    width: 296px;
    height: 66px;
    background: linear-gradient(90deg, #03b5ff 0%, #0085ff 100%);
    border-radius: 8px 8px 8px 8px;
    font-weight: 500;
    font-size: 24px;
    color: #ffffff;
    margin-top: 50px;
  }

  .payment-password-input {
    margin-top: 30px;
  }

  .payment-password-input input {
    width: 40px;
    height: 40px;
    margin: 0;
    padding: 10px;
    border: 1px solid #ccc;
    text-align: center;
  }

  .payment-password-input input:focus {
    border-color: #007bff;
  }

  .infoList {
    border: 1px solid #ededed;
    margin: -30px 40px 0;

    .infoItem {
      width: calc(100% - 30px);
      justify-content: space-between;
      padding: 15px;
      border-bottom: 1px solid #ededed;

      .title {
        font-size: 16px;
        color: #000;
        margin-bottom: 15px;
        line-height: 16px;
      }

      .detaile {
        color: #888888;
        font-size: 14px;
      }
    }

    .itemType {
      color: #666666;

      font-size: 14px;
    }
  }
  ::v-deep .has-gutter {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
    background: red;
  }
  .infoBox {
    display: flex;
    align-items: center;
    img {
      width: 80px;
      height: 80px;
      border-radius: 18px;
      margin-right: 10px;
    }

    .div2 {
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 14px;
      color: #1a1a1a;
      font-weight: 600;
      span {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 12px;
        color: #999999;
        margin-right: 5px;
      }
    }
  }
  .div1 {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #1a1a1a;
    font-weight: 600;
    span {
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 13px;
      color: #ffffff;
      background: #f98e04;
      border-radius: 4px;
      padding: 4px 8px;
      margin-right: 5px;
    }
  }
</style>
