<template>
	<div class="orderAll">
		<div class="orderTop">
			<div class="fl">
				<img v-if="orderInfo.status == -1" :src="require('@/static/image/chat/order5.png')" class="orderImage"/>
				<img v-if="orderInfo.status == 0 || orderInfo.status == 1" :src="require('@/static/image/chat/order1.png')" class="orderImage"/>
				<img v-if="orderInfo.status == 2 || orderInfo.status == 3" :src="require('@/static/image/chat/order2.png')" class="orderImage"/>
				<img v-if="orderInfo.status == 4" :src="require('@/static/image/chat/order3.png')" class="orderImage"/>
				<img v-if="orderInfo.status == 5" :src="require('@/static/image/chat/order4.png')" class="orderImage"/>
				<img v-if="orderInfo.status == 6" :src="require('@/static/image/chat/order3.png')" class="orderImage"/>
				<div class="orderState" v-if="orderInfo.status == -1 ">
					<span class="orderTitle">已取消</span>
					<span class="orderDetaile">{{ getCancelReason() }}</span>
				</div>
				<div class="orderState" v-if="orderInfo.status == 0 ">
					<span class="orderTitle">未支付</span>
					<span class="orderDetaile">买家还未支付订单，请稍后</span>
				</div>

				<div class="orderState" v-if="orderInfo.status == 1 && orderInfo.reasonType == null">
					<span class="orderTitle">待发货</span>
					<span class="orderDetaile">您的订单正在出货中，请稍后</span>
				</div>

				<div class="orderState" v-if="orderInfo.status == 1 && orderInfo.reasonType != null">
					<span class="orderTitle">申请取消</span>
					<span class="orderDetaile">{{ getCancelReason() }}</span>
				</div>
				<div class="orderState" v-if="orderInfo.status == 3 ">
					<span class="orderTitle">已发货</span>
					<span class="orderDetaile">订单已处理结束，等待系统自动打款给卖家</span>
				</div>
				<div class="orderState" v-if="orderInfo.status == 4 ">
					<span v-if="orderInfo.settleType  == 2" class="orderTitle">部分结算</span>
					<span v-else-if="orderInfo.settleType  == 1" class="orderTitle">全部结算</span>
					<span v-else class="orderTitle">交易成功</span>
				</div>
				<div class="orderState" v-if="orderInfo.status == 5 ">
					<span class="orderTitle">售后中</span>
				</div>
				<div class="orderState" v-if="orderInfo.status == 6 ">
					<span class="orderTitle">售后完成,已赔付</span>
				</div>
				<div class="orderState" v-if="orderInfo.status == 7 ">
					<span class="orderTitle">售后完成,已退款</span>
				</div>
			</div>
			<div class="stateList">
				<span v-if="orderInfo.status>=0&&orderInfo.status<4&&!orderInfo.reasonType" class="state2" @click="cancelOrder">取消订单</span>
			</div>
		</div>

		<!--商品信息-->
		<div class="takeInfo">
			<div class="takeTitle">商品信息</div>
			<div class="infoItem">
				<span>商品编号：</span>
				<span style="color: #1D223C;">{{ orderInfo.gameCommodityId }}</span>
				<span v-clipboard:copy="orderInfo.gameCommodityId" v-clipboard:success="onCopy" style="margin-left: 8px;color: #0085FF;">复制</span>
			</div>
			<div class="infoItem">
				<span>商品类型：</span>
				<span style="color: #1D223C;">{{ orderInfo.gameCommodityTypeText }}</span>
			</div>

			<div class="infoItem">
				<span>交易类型：</span>
				<span v-if="orderInfo.deliveryType == '1'" style="color: #1D223C;">卖家发货</span>
				<span v-if="orderInfo.deliveryType == '2'" style="color: #1D223C;">平台发货</span>
				<span v-if="orderInfo.deliveryType == '3'" style="color: #1D223C;">收货</span>
				<span v-if="orderInfo.deliveryType == '4'" style="color: #1D223C;">代练</span>
				<span v-if="orderInfo.deliveryType == '5'" style="color: #1D223C;">热卖</span>
			</div>

			<div class="infoItem" v-if="orderInfo.remake != null">
				<span>交易备注：</span>
				<span style="color: #1D223C;">{{ orderInfo.remake }}</span>
			</div>
			<div class="infoItem" v-if="orderInfo.gameCommodityCategory == '1' || orderInfo.gameCommodityCategory == '12'">
				<span>交易数量：</span>
				<span v-if="orderInfo.deliveryType == 3 || orderInfo.deliveryType == 5 "
							style="color: #1D223C;">1件={{ orderInfo.num }}{{ orderInfo.commodityUnit }}</span>
				<span v-else
							style="color: #1D223C;">{{ orderInfo.num }}件={{ orderInfo.num * orderInfo.piecesNum }}{{ orderInfo.commodityUnit }}</span>
			</div>
			<div v-else class="infoItem">
				<span>出货数量：</span>
				<span style="color: #1D223C;">{{ orderInfo.num }}</span>
			</div>
			<div class="infoItem" v-if="orderInfo.deliveryType == '4'&&orderInfo.sellerPhone">
				<span>买家电话:</span>
				<span style="color: #1D223C;">{{ orderInfo.sellerPhone }}</span>
			</div>
			<div class="infoItem" v-else>
				<span>买家电话:</span>
				<span style="color: #1D223C;">{{ orderInfo.phone }}</span>
			</div>
			<div class="infoItem" v-for="(item,index) in orderInfo.orderInfos">
				<span>{{ item.title }}：</span>
				<span style="color: #1D223C;">{{ item.content }}</span>
			</div>
		</div>
		<!--卖家信息-->
		<div class="takeInfo">
			<div class="takeTitle">卖家信息</div>

			<div class="infoItem" v-if="orderInfo.sellerPhone">
				<span>联系电话:</span>
				<span v-if="orderInfo.deliveryType == '4'"
							style="color: #1D223C;">{{ orderInfo.phone }}</span>
				<span v-else style="color: #1D223C;">{{ orderInfo.sellerPhone }}</span>
			</div>
			<div class="infoItem" v-if=" orderInfo.gameCommodityFieldValueList != null && orderInfo.gameCommodityFieldValueList != undefined " v-for="(item,index) in orderInfo.gameCommodityFieldValueList">
				<span>{{ item.name }}：</span>
				<span style="color: #1D223C;">{{ item.value }}</span>
			</div>
		</div>
		<!--卖家账号信息-->
		<div class="takeInfo" v-if="orderInfo.gameCommodityAccountInfos && orderInfo.gameCommodityAccountInfos.length">
			<div class="takeTitle">卖家账号信息</div>

			<div class="infoItem" v-for="(item,index) in orderInfo.gameCommodityAccountInfos">
				<span>{{ item.title }}：</span>
				<span style="color: #1D223C;">{{ item.content }}</span>
			</div>
		</div>

		<!--订单信息-->
		<div class="takeInfo">
			<div class="takeTitle1">订单信息</div>
			<div class="infoItem">
				<span style="color: #1D223C;">订单编号：{{ orderInfo.id }}</span>
				<span v-clipboard:copy="orderInfo.id" v-clipboard:success="onCopy" style="margin-left: 8px;color: #0085FF;">复制</span>
			</div>
			<div class="infoItem">
				<span style="color: #1D223C;">商品标题：{{ orderInfo.title }}</span>
			</div>
			<div class="infoItem" v-if="orderInfo.detail != null">
				<span style="color: #1D223C;">商品描述：{{ orderInfo.detail }}</span>
			</div>
			<!-- <div class="infoItem" v-if="orderInfo.orderType == 3">
				<span style="color: #1D223C;">交易备注：{{orderInfo.title}}</span>
			</div> -->
			<div>
				<el-table :data="[orderInfo]" style="width: 100%">
					<el-table-column prop="date" label="商品信息">
						<template slot-scope="scope">
							<div>
								<div class="infoTitle1 el1">
									<span>{{ scope.row.gameCommodityTitle }}</span>
								</div>
								<div class="idItems">
									<span>{{ scope.row.gameName }}</span>
									<span v-if="scope.row.districtName !='' && scope.row.districtName !=null">｜{{ scope.row.districtName }}</span>
								</div>
								<div class="idItems">
									<span>{{ scope.row.gameCommodityCategory_dictText }}</span>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="name"
													 :label="(orderInfo.gameCommodityCategory == '8' || orderInfo.gameCommodityCategory == '2') ? '佣金':'单价'"
													 align="right" width="80">
						<template slot-scope="scope">
							<div class="idItems">
								<span style="color: #1D223C;font-weight: bold;">{{ scope.row.price }}</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="address" label="数量" align="right" width="80">
						<template slot-scope="scope">
							<div class="idItems">
								<span style="color: #1D223C;font-weight: bold;">{{ scope.row.num }}</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="address"
													 :label="(orderInfo.gameCommodityCategory == '8' || orderInfo.gameCommodityCategory == '2') ? '保证金':'金额'"
													 align="right" width="80">
						<template slot-scope="scope">
							<div class="idItems">
								<span style="color: #FF5C00;font-weight: bold;">{{ Number(scope.row.practicalPrice) }}</span>
							</div>
						</template>
					</el-table-column>
				</el-table>

				<div v-if="orderInfo.gameCommodityCategory != '8' && orderInfo.gameCommodityCategory != '2'"
						 class="idItems" style="text-align: right;margin-top: 5px;">
					<span style="color: #A5A7B1;font-weight: 500;">保险：</span>
					<span style="color: #1D223C;font-weight: 500;margin-right: 20px;">¥{{ orderInfo.insurePrice }}</span>
					<span style="color: #A5A7B1;font-weight: 500;">实付款：</span>
					<span style="color: #FF5C00;font-weight: 500;">¥{{ orderInfo.totalPrice }}</span>
				</div>
			</div>
		</div>
		<!--交易信息-->
		<div class="takeInfo" style="border-bottom: none;">
			<div class="takeTitle">交易信息</div>
			<div style="margin-top: 10px;">
				<el-timeline :reverse="reverse">
					<el-timeline-item v-for="(activity, index) in tradeMessage" :key="index" :timestamp="activity.createTime" :color="activity.color">
						{{ activity.message }}
					</el-timeline-item>
				</el-timeline>
			</div>
		</div>

	</div>
</template>
<script>
import { cancelOrder, cancelOrderSale } from '@/api';

export default {
	name : 'OrderInfo',
	props: {
		orderInfo   : {
			type   : Object,
			default: () => {},
		},
		tradeMessage: {
			type   : Array,
			default: () => {},
		},
	},
	data() {
		return {
			noClick: true,
			reverse: true,

			cancelList    : [],
			causeList     : [],
			causeListCache: {},
		};
	},

	created() {
	},
	destroyed() {
	},
	mounted() {

	},

	watch  : {
	},
	methods: {
		onCopy() {
			this.message('复制成功', 'success');
		},
		// 订单结算 弹窗
		
		//取消原因
		getCancelReason() {
			switch (this.orderInfo.reasonType) {
				case 1:
					return '买家原因:' + this.orderInfo.cancelReason;
				case 2:
					return '卖家原因:' + this.orderInfo.cancelReason;
				case 3:
					return '其他原因:' + this.orderInfo.cancelReason;
			}
		},
		//取消订单
		cancelOrder(){
			this.$prompt('确定取消订单?', '确认', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(({ value }) => {
				cancelOrder({
					orderId: this.orderInfo.id,
					cancelReason: value,
				}).then((res) => {
					this.message(res.result, 'success')
					this.$emit('reloadData',{id:this.orderInfo.id});
				})
			}).catch(() => {
			});
		}
	}
};


</script>

<style scoped lang="scss">
.notice {
	height: 72px;
	background: #0085FF;
}

.chatMain {
	//width: 1666px;
	height: 952px;
	background: #F9F9F9;
	box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.20);
	border-radius: 20px 20px 20px 20px;
	overflow: hidden;
	display: flex;
	margin-top: 16px;
	margin-bottom: 16px;
}

.chatLeft {
	width: 112px;
	padding-bottom: 40px;
	background: #212328;
	color: #A5A7B1;

	.leftItem {
		height: 112px;
		flex-direction: column;
		justify-content: center;
	}

	.leftItem1 {
		height: 90px;
		flex-direction: column;
		justify-content: center;
	}

	.leftText {
		margin-top: 8px;
	}
}

.account {
	width: 372px;
	height: 952px;
	overflow: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	background-color: #fff;
}

.search {
	// position: fixed;
	// top: 88px;
	width: 372px;
	// height: 200px;
	// background-color: #fff;
	z-index: 99;

	.searchInfo {
		margin-left: 12px;
	}
}

.account::-webkit-scrollbar {
	display: none;
}

.chatList {
	overflow: auto;
}

.chatList::-webkit-scrollbar {
	display: none;
}

.chatItem {
	margin-top: 16px;
	position: relative;
	border-radius: 12px;

	.chatClose {
		position: absolute;
		bottom: 10px;
		right: 10px;
		display: none;
	}
}

.chatItem:hover {
	.chatClose {
		position: absolute;
		bottom: 5px;
		right: 10px;
		display: block;
	}
}

.infoTitle {
	.infoAccount {
		font-size: 10px;
		background: #FF5C00;
		border-radius: 4px;
		color: #fff;
		padding: 1px 3px;
	}

	.infoAccount1 {
		background-color: #FF5C00;
	}

	.infoAccount2 {
		background-color: #B742FF;
	}

	.infoAccount3 {
		background-color: #19D972;
	}

	font-weight: 600;
	font-size: 14px;
	line-height: 30px;
	color: #000000;
	width: 170px;
}

.idItem {
	font-weight: 400;
	font-size: 14px;
	color: #A5A7B1;
	margin-bottom: 5px;
}

.chat {
	width: 644px;

	position: relative;

	.chatTop {
		display: flex;

		.chatTopItem {
			//width: 157px;
			height: 54px;
			border-radius: 4px;
			border: 1px solid #DEDEDE;
			display: flex;
			align-items: center;
			padding-left: 16px;
			padding-right: 16px;
			font-weight: 600;
			font-size: 16px;
			color: #0085FF;
		}

		.chatAction {
			background-color: #ECF9FF;
		}
	}
}

.chatCenter {
	height: 570px;
	// width: 624px;
	padding-right: 16px;
	overflow: auto;
}

// .chatCenter::-webkit-scrollbar {
// 	display: none;
// }

.recordsLeft {
	margin-top: 20px;
	margin-left: 16px;
	display: flex;

}

.recordsRight {
	margin-top: 20px;
	margin-left: 16px;
	display: flex;
	justify-content: flex-end;
}

.leftInfo {
	margin-left: 8px;

}

.rightInfo {

	display: grid;
	justify-items: end;
	/* 水平居中 */
	align-items: center;
	/* 垂直居中 */
	margin-right: 8px;

}

.infoName {
	font-weight: 400;
	font-size: 16px;
	color: #0085FF;
}

.infoName1 {
	font-weight: 400;
	font-size: 16px;
	color: #FF5C00;
}

.infoName2 {
	font-weight: 400;
	font-size: 16px;
	color: #000;
}


.infoTime {
	font-weight: 400;
	font-size: 16px;
	color: #A5A7B1;
	margin-left: 8px;
}

.infoCount {
	max-width: 296px;
	display: inline-block;

	background: #F1F1F1;
	border-radius: 8px;
	padding: 12px 16px;
	margin-top: 4px;
	word-break: break-all; //会断词

}

.bottomTop {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.bottomTips {
		font-weight: 400;
		font-size: 16px;
		color: #FF5C00;
		margin-right: 10px;
	}
}

.functionImage {
	width: 28px;
	height: 28px;
	margin-right: 30px;
}

::v-deep .el-textarea__inner {
	border: none;
	margin-top: 10px;
	width: 580px;
	height: 160px;
	background-color: #F9F9F9;
}

.chatArea {
	font-size: 18px;
	color: #000;
}


.orderAll {
	background-color: #fff;
	width: 538px;
	overflow: auto;

	.orderTop {
		padding: 16px;

		.orderImage {
			width: 60px;
			height: 60px;
		}

		.orderTitle {
			font-weight: 600;
			font-size: 20px;
			color: #1D223C;
		}

		.orderDetaile {
			font-weight: 400;
			font-size: 16px;
			color: #A5A7B1;
		}

		.orderState {
			display: flex;
			flex-direction: column;
			margin-left: 12px;
		}
	}

	.stateList {
		display: flex;
		justify-content: right;
		margin-top: 8px;
		padding-bottom: 8px;
		border-bottom: 1px solid #ECECEC;

		span {
			margin-left: 8px;
		}

		.state1 {
			font-weight: 400;
			font-size: 14px;
			color: #FFFFFF;
			height: 22px;
			padding: 4px 12px;
			background: #19D972;
			border-radius: 6px 6px 6px 6px;
		}

		.state2 {
			font-weight: 400;
			font-size: 14px;
			color: #FFFFFF;
			height: 22px;
			padding: 4px 12px;
			background: #FF5C00;
			border-radius: 6px 6px 6px 6px;
		}

		.state3 {
			font-weight: 400;
			font-size: 14px;
			color: #FFFFFF;
			height: 22px;
			padding: 4px 12px;
			background: #0085FF;
			border-radius: 6px 6px 6px 6px;
		}

		.state4 {
			font-weight: 400;
			font-size: 14px;
			border: 1px solid #0085FF;
			height: 22px;
			padding: 4px 12px;
			color: #0085FF;
			border-radius: 6px 6px 6px 6px;
		}

		.cancelState {
			font-weight: 400;
			font-size: 14px;
			height: 22px;
			color: #777A8A;
			padding: 3px 12px;
			border: 1px solid #DEDEDE;
			border-radius: 6px 6px 6px 6px;
		}
	}
}

.orderAll::-webkit-scrollbar {
	display: none;
}

.takeInfo {
	padding: 8px 12px;
	border-bottom: 1px solid #ECECEC;

	.takeTitle {
		font-weight: 600;
		font-size: 16px;
		color: #1D223C;
		line-height: 24px;
		margin-left: 18px;
		position: relative;
	}

	.takeTitle::before {
		position: absolute;
		content: '';
		top: 0;
		left: -15px;
		width: 3px;
		height: 24px;
		background: #FF5C00;

	}

	.takeTitle1 {
		font-weight: 600;
		font-size: 16px;
		color: #1D223C;
		line-height: 24px;
		margin-left: 18px;
		position: relative;
	}

	.takeTitle1::before {
		position: absolute;
		content: '';
		top: 0;
		left: -15px;
		width: 3px;
		height: 24px;
		background: #0085FF;

	}

	.infoItem {
		font-weight: 400;
		font-size: 16px;
		color: #A5A7B1;
		margin-top: 12px;
	}
}

.takeSubmit {

	height: 54px;
	background: #FAFAFA;
	border-radius: 6px 6px 6px 6px;
	padding-left: 12px;
	margin-top: 16px;

	.takeText {
		font-weight: 400;
		font-size: 16px;
		color: #A5A7B1;

		line-height: 40px;
	}

	.takeInput {
		width: 225px;
		height: 40px;
		border-radius: 4px 4px 4px 4px;
	}

	.takeBtn {
		width: 56px;
		height: 38px;
		padding: 0;
		margin-left: 8px;
	}
}

// ::v-deep .el-timeline-item__node:last-child{
// 	border: 4px solid #FF5C00;
// }
::v-deep .el-timeline {
	margin-left: -40px;
}

::v-deep .el-timeline-item__tail {
	border-left: 2px dotted #E4E7ED;

}

.infoTitle1 {
	font-weight: 600;
	font-size: 14px;
	line-height: 30px;
	color: #0085FF;
	width: 250px;
}

.idItems {
	font-weight: 400;
	font-size: 14px;
	color: #A5A7B1;
	margin-top: 3px;
}

::v-deep .el-table th {
	background: #FAFAFA;
	color: #000;
}

.leftAction {
	background: linear-gradient(270deg, rgba(0, 133, 255, 0.3) 0%, rgba(0, 133, 255, 0) 100%);
	color: #0085FF;
}

.img-style {
	margin-left: 10px;
	margin-bottom: 10px;
}

.bottomLeft {
	position: relative;

	.emoji {
		position: absolute;
		bottom: 60px;
		left: -100px;
		width: 500px;
		background-color: #fff;
		padding: 15px;
		border-radius: 5px;
		border: 1px solid #E4E7ED;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
	}

	// el-popover el-popper


}

.dialogCenter {
	border-top: 1px solid #FAFAFA;
	border-bottom: 1px solid #FAFAFA;
	flex-direction: column;
	padding: 10px 0;

	.deleteTitle {
		font-weight: 600;
		font-size: 20px;
		color: #1D223C;
		text-align: center;
		margin: 20px;
	}

	.deleteDetaile {
		font-weight: 400;
		font-size: 16px;
		color: #777A8A;
		text-align: center;
		line-height: 30px;
	}

}

.dialogLable {

	font-size: 16px;
	color: #000000;
	width: 100px;
	justify-content: space-between;
	margin-left: 40px;
	position: relative;

}

.must {
	position: relative;
}

.must::after {
	position: absolute;
	content: '*';
	color: red;
	top: 0;
	left: -20px;
}

.dialogValue {
	position: relative;

	::v-deep .el-select-dropdown {
		position: absolute !important;
		top: auto !important;
		left: auto !important;
	}
}

.search {


	.searchText {
		width: 100px;
		text-align: right;
		font-weight: 400;
		font-size: 18px;
		color: #1D223C;
	}
}

.roleTitles {

	width: 1186px;
	border-bottom: 1px solid #ECECEC;
	height: 67px;
	// line-height: 87px;
	padding-left: 12px;
	padding-right: 42px;
	margin-top: 30px;
	display: flex;
	align-items: center;

	.titleItem {
		font-weight: 400;
		font-size: 20px;
		position: relative;
		color: #1D223C;
		height: 30px;
		margin-right: 50px;
	}

	.titleItem::after {
		position: absolute;
		width: 1px;
		height: 20px;
		content: '';
		top: 2px;
		right: -25px;
		background-color: #DEDEDE;
	}

	.titleItem:last-child::after {
		width: 0px;
	}

	.action {
		color: #0085FF;
		font-weight: bold;
	}
}

.tableList {
	margin: 20px;
	width: 1400px;
	height: 500px;
	overflow-y: auto;
	scrollbar-width: none;
}

.chatStatus {
	position: absolute;
	bottom: 5px;
	left: 0;
	width: 80px;
	text-align: center;
	font-size: 14px;
	color: #FFFFFF;
	opacity: 0.8;
	padding: 3px 0;
	letter-spacing: 2px;
	background-color: rgba(0, 0, 0, .8);
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
}

.chatStatus1 {
	position: absolute;
	top: 10px;
	right: -20px;
	width: 80px;
	text-align: center;
	font-size: 14px;
	color: #FFFFFF;
	background: #FF5C00;
	transform: rotate(45deg);
	// opacity: 0.8;
	letter-spacing: 2px;
	overflow: hidden;
}

.popUp {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	background-color: rgba(0, 0, 0, .6);
	overflow: hidden;

	.popClose {
		position: absolute;
		top: 20px;
		right: 100px;
		width: 50px;
		height: 50px;
	}

	.popLeft {
		position: absolute;
		top: 450px;
		left: 30px;
		width: 50px;
		height: 50px;
	}

	.popRight {
		position: absolute;
		top: 450px;
		right: 30px;
		width: 50px;
		height: 50px;
	}
}

::v-deep .video-js.vjs-fluid {
	height: 1000px;

}

.loginOut {
	line-height: 72px;
	color: #fff;
}

.loginOut:hover {
	color: #FF5C00;
}

.chatActions {
	background-color: #e5f6ff;
}

.online {
	padding: 10px;
	text-align: center;
	position: relative;

	.lineList {
		position: absolute;
		left: 110px;
		top: 0;
		z-index: 99999;
		width: 150px;
		background-color: #212328;
		padding: 10px;
		display: flex;
		flex-direction: column;
		display: none;
	}

	.lineItem {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.isOnline {
		color: rgb(25, 217, 114);
	}
}

.online:hover {
	.lineList {
		display: block;
		position: absolute;
		left: 110px;
		top: 0;
		z-index: 99999;
		width: 150px;
		background-color: #212328;
		padding: 10px;
		display: flex;
		flex-direction: column;

	}
}

.textareaSty {
	width: 100%;
	height: 180px;
	resize: none;

	border-radius: 4px;
	overflow-y: auto;
	scrollbar-width: none;

	margin: 5px 0;
	border: none;
	background: none;

}

[contenteditable]:focus {
	outline: none;
}

.allMore {
	text-align: center;
	color: #0085FF;
	cursor: pointer;
	margin-top: 30px;
}

.endOrder {
	color: #0085FF;
	cursor: pointer;
}
</style>