<template>
  <div class="menuLeft-box">
    <div class="nav-left">
      <div class="nav-title" v-if="title">
        {{ title }}
      </div>
      <div class="nav-list" v-if="router.length > 0">
        <el-menu :default-active="active" class="el-menu-vertical-demo">
          <template v-for="(item, index) in router">
            <el-menu-item :class="{ 'is-active': active === item.path }" v-if="item.child.length == 0" :index="item.path" :key="index" @click="openMenu(item.path)">
              <div class="menu-css">
                <img :src="item.icon" alt="" />
                <span>{{ item.name }}</span>
              </div>
            </el-menu-item>
            <el-submenu v-else :key="index" :index="item.path">
              <template slot="title">
                <div class="menu-css">
                  <img :src="item.icon" alt="" />
                  <span>{{ item.name }}</span>
                </div>
              </template>
              <el-menu-item v-for="(child, childindex) in item.child" :index="child.path" :key="childindex" @click="openMenu(child.path)">
                <div class="submenu-css">
                  <i class="icon"></i>
                  <p>{{ child.name }}</p>
                </div>
              </el-menu-item>
            </el-submenu>
          </template>
        </el-menu>
      </div>
    </div>
    <div class="content-css">
      <slot></slot>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'menuLeft',
    props: {
      space: {
        type: String,
        default: '20px',
      },
      title: {
        type: String,
        required: true,
        default: '',
      },
      router: {
        type: Array,
        default: () => {
          return []
        },
      },
      active: {
        type: String,
        default: '0',
      },
    },
    methods: {
      openMenu(path) {
        this.$emit('change', path)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .menuLeft-box::v-deep {
    display: flex;
    width: 100%;

    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 0px 9px 0px #e0e0e0;
    box-sizing: border-box;
    .nav-left {
      width: 220px;
      background: #f8f8f8;
      padding-bottom: 50px;

      .nav-title {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 72px;
        text-align: center;
        background: #1861ee;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 22px;
        color: #f8f8f8;
        border-radius: 20px 0 0 0;
      }
      .nav-list {
        max-height: 900px;
        overflow-y: auto;
        .el-menu {
          background: #f8f8f8;
          border-right: none;
        }
        .el-submenu {
          .el-submenu__title {
            padding: 0 !important;
          }
          .el-menu-item {
            height: 56px !important;
            &.is-active {
              background: #fff !important;
              .submenu-css {
                &:before {
                  position: absolute;
                  left: 0;
                  top: 0;
                  content: '';
                  width: 6px;
                  height: 100%;
                  background: #1861ee;
                }
              }
            }
            .submenu-css {
              position: relative;
              display: flex;
              align-items: center;
              height: 56px;
              flex-wrap: nowrap;
              width: 100%;
              padding-left: 28px;
              box-sizing: border-box;
              gap: 10px;
              .icon {
                width: 6px;
                height: 6px;
                background: #666666;
                border-radius: 50%;
              }
              p {
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }
        .el-menu-item {
          padding: 0 !important;
          &.is-active {
            background: #fff !important;
            .menu-css {
              &:before {
                position: absolute;
                left: 0;
                top: 0;
                content: '';
                width: 6px;
                height: 100%;
                background: #1861ee;
              }
            }
          }
        }
        .menu-css {
          position: relative;
          display: flex;
          gap: 14px;
          align-items: center;
          padding-left: 28px;
          color: #666;
          img {
            width: 22px;
            height: 22px;
          }
        }
      }
    }
    .content-css {
      flex: 1;
    }
  }
</style>
