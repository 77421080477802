import request from '@/api/request.js'


// 公告列表
export function noticeList(data) {
  return request({
    url: 'api/message/noticeList',
    method: 'get',
    data
  })
}

// 游戏商品大类
export function gameCommodityCategories(data) {
  return request({
    url: 'api/game/gameCommodityCategories',
    method: 'get',
    data
  })
}
// 游戏-分页列表查询
export function gameList(data) {
  return request({
    url: 'api/game/list',
    method: 'get',
    params:data
  })
}

//游戏商品-分页列表查询
export function gameCommodityList(data) {
  return request({
    url: 'api/gameCommodity/list',
    method: 'post',
    data:data
  })
}

//游戏区服列表
export function districtList(data) {
  return request({
    url: 'api/game/districtList',
    method: 'get',
    params:data
  })
}

//游戏商品类型
export function gameCommodityTypes(data) {
  return request({
    url: 'api/game/gameCommodityTypes',
    method: 'get',
    params:data
  })
}
//游戏商品账号字段列表
export function accountList(data) {
  return request({
    url: 'api/game/accountList',
    method: 'get',
    params:data
  })
}

//游戏商品字段列表
export function fieldList(data) {
  return request({
    url: 'api/game/fieldList',
    method: 'get',
    params:data
  })
}

//游戏商品保险列表
export function insureList(data) {
  return request({
    url: 'api/game/insureList',
    method: 'get',
    params:data
  })
}

//游戏商品-添加
export function gameCommodityAdd(data) {
  return request({
    url: 'api/gameCommodity/add',
    method: 'post',
    data
  })
}


//游戏商品-通过id查询
export function gameCommodityQueryById(data) {
  return request({
    url: 'api/gameCommodity/queryById',
    method: 'get',
    params:data
  })
}

//添加购物
export function shopCartAdd(data) {
  return request({
    url: 'api/shopCart/add',
    method: 'post',
    data:data
  })
}

//跨区列表
export function spannedList(data) {
  return request({
    url: 'api/game/spannedList',
    method: 'get',
    params:data
  })
}

//相关商品
export function queryByCommodityTypeId(data) {
  return request({
    url: 'api/gameCommodity/queryByCommodityTypeId',
    method: 'get',
    params:data
  })
}
//收藏商品
export function collectionAdd(data) {
  return request({
    url: 'api/collection/add',
    method: 'get',
    params:data
  })
}

//认证商家信息
export function authShop(data) {
  return request({
    url: 'api/gameCommodity/authShop',
    method: 'get',
    params:data
  })
}

// 是否实名认证
export function realInfo(data) {
  return request({
    url: 'api/realInfo/queryById',
    method: 'get',
    params:data
  })
}

// 实名认证
export function realInfoAdd(data) {
  return request({
    url: 'api/realInfo/add',
    method: 'post',
    data:data
  })
}
// 活体检测-获取token
export function getLivenessToken(data) {
  return request({
    url: 'api/realInfo/getLivenessToken',
    method: 'post',
    data:data
  })
}

// 活体检测-获取认证结果
export function getLivenessResult(data) {
  return request({
    url: 'api/realInfo/getLivenessResult',
    method: 'post',
    params:data
  })
}
// 修改密码
export function updatePass(data) {
  return request({
    url: 'api/userUp/password',
    method: 'post',
    data:data
  })
}
// 修改密码验证码
export function updateSend(data) {
  return request({
    url: 'api/sms/updateSend',
    method: 'post',
    data:data
  })
}
// // 人脸认证
// export function faceAudit(data) {
//   return request({
//     url: 'api/realInfo/faceAudit',
//     method: 'post',
//     data:data
//   })
// }
//
// // 获取人脸url
// export function realInfoGetUrl(data) {
//   return request({
//     url: 'api/realInfo/getUrl',
//     method: 'get',
//     params:data
//   })
// }

// 验证手机号
export function smsVerify(data) {
  return request({
    url: 'api/sms/verify',
    method: 'post',
    data:data
  })
}

// 更改用户手机号码
export function userUpPhone(data) {
  return request({
    url: 'api/userUp/phone',
    method: 'post',
    data:data
  })
}

// 预备订单
export function singleReady(data) {
  return request({
    url: 'api/order/singleReady',
    method: 'post',
    data:data
  })
}

// 提交订单动态表单
export function orderFieldList(data) {
  return request({
    url: 'api/order/orderFieldList',
    method: 'get',
    params:data
  })
}

// 第一步支付
export function onePay(data) {
  return request({
    url: 'api/order/onePay',
    method: 'post',
    data:data
  })
}

// 第二步支付
export function twoPay(data) {
  return request({
    url: 'api/order/twoPay',
    method: 'post',
    data:data
  })
}

//刷新用户信息
export function fush(data) {
  return request({
    url: 'api/login/fush',
    method: 'post',
    data:data
  })
}

//购物车列表
export function shopList(data) {
  return request({
    url: 'api/shopCart/getList',
    method: 'get',
    params:data
  })
}

//收藏列表
export function collectionList(data) {
  return request({
    url: 'api/collection/list',
    method: 'get',
    params:data
  })
}

// 添加申诉
export function accountAppealAdd(data) {
  return request({
    url: 'api/accountAppeal/add',
    method: 'post',
    data:data
  })
}

// 通过id查询申诉
export function accountAppealQueryById(data) {
  return request({
    url: 'api/accountAppeal/queryById',
    method: 'post',
    data:data
  })
}

// 账号申诉-分页列表查询
export function accountAppealList(data) {
  return request({
    url: 'api/accountAppeal/list',
    method: 'get',
    params:data
  })
}


// 交易信息列表查询-分页列表查询
export function messageList(data) {
  return request({
    url: 'api/order/tradeMessageListPage',
    method: 'get',
    params:data
  })
}
//订单列表（买家）
export function getOrderList(data) {
  return request({
    url: 'api/order/getOrderList',
    method: 'post',
    data:data
  })
}


// //订单列表（买家）
// export function getOrderList(data) {
//   return request({
//     url: 'api/order/getOrderList',
//     method: 'post',
//     data:data
//   })
// }


//订单列表数量（买家）
export function getOrderNum(data) {
  return request({
    url: 'api/order/getOrderNum',
    method: 'post',
    data:data
  })
}

//收货角色-分页列表查询
export function receiveRoleList(data) {
  return request({
    url: 'api/receiveRole/list',
    method: 'get',
    params:data
  })
}


//我的商品列表
export function gameCommodityMyList(data) {
  return request({
    url: 'api/gameCommodity/myList',
    method: 'post',
    data:data
  })
}
//商家入驻列表
export function settledStoreList(data) {
  return request({
    url: 'api/settledStore/list',
    method: 'get',
    params:data
  })
}

//游戏商品-编辑
export function gameCommodityEdit(data) {
  return request({
    url: 'api/gameCommodity/edit',
    method: 'post',
    data:data
  })
}

//游戏商品-编辑
export function gameCommodityDelete(data) {
  return request({
    url: 'api/gameCommodity/delete',
    method: 'get',
    params:data
  })
}

//游戏商品-下架
export function offShelf(data) {
  return request({
    url: 'api/gameCommodity/offShelf',
    method: 'post',
    params:data
  })
}

//批量上架下架
export function offShelfBatch(data) {
  return request({
    url: 'api/gameCommodity/offShelfBatch',
    method: 'get',
    params:data
  })
}

//批量删除
export function deleteBatch(data) {
  return request({
    url: 'api/gameCommodity/deleteBatch',
    method: 'get',
    params:data
  })
}

//订单列表（卖家）
export function getSaleOrderList(data) {
  return request({
    url: 'api/order/getSaleOrderList',
    method: 'post',
    data:data
  })
}

//订单列表数量（卖家）
export function getSaleOrderNum(data) {
  return request({
    url: 'api/order/getSaleOrderNum',
    method: 'post',
    data:data
  })
}

//个人中心
export function userCenter(data) {
  return request({
    url: 'api/settledStore/userCenter',
    method: 'get',
    params:data
  })
}

//个人中心
export function detailList(data) {
  return request({
    url: 'api/detail/getList',
    method: 'post',
    data:data
  })
}

//提现账户-分页列表查询
export function withdrawList(data) {
  return request({
    url: 'api/withdrawAccount/list',
    method: 'get',
    params:data
  })
}

//提现账户-添加
export function withdrawAdd(data) {
  return request({
    url: 'api/withdrawAccount/add',
    method: 'post',
    data:data
  })
}


//提现账户-添加
export function withdrawGetUrl(data) {
  return request({
    url: 'api/withdrawAccount/getUrl',
    method: 'get',
    params:data
  })
}

//发起提现申请
export function withdrawApply(data) {
  return request({
    url: 'api/userWithdraw/apply',
    method: 'post',
    data:data
  })
}

//入驻商家-添加
export function settledStoreAdd(data) {
  return request({
    url: 'api/settledStore/add',
    method: 'post',
    data:data
  })
}
//入驻商家-修改
export function settledStoreEdit(data) {
  return request({
    url: 'api/settledStore/edit',
    method: 'post',
    data:data
  })
}

//入驻支付
export function orderDepositPay(data) {
  return request({
    url: 'api/orderDeposit/pay',
    method: 'post',
    data:data
  })
}

//店铺列表
export function storeList(data) {
  return request({
    url: 'api/settledStore/storeList',
    method: 'get',
    params:data
  })
}

//我的收货商品
export function receiveGoodsList(data) {
  return request({
    url: 'api/receiveGoods/myList',
    method: 'get',
    params:data
  })
}


//收货商品-添加
export function receiveGoodsAdd(data) {
  return request({
    url: 'api/receiveGoods/add',
    method: 'post',
    data:data
  })
}

//收货商品-修改
export function receiveGoodsEdit(data) {
  return request({
    url: 'api/receiveGoods/edit',
    method: 'post',
    data:data
  })
}
//收货商品-删除
export function receiveGoodsDelete(data) {
  return request({
    url: 'api/receiveGoods/delete',
    method: 'get',
    params:data
  })
}

//收货商品-批量上架下架
export function receiveGoodsOffShelfBatch(data) {
  return request({
    url: 'api/receiveGoods/offShelfBatch',
    method: 'get',
    params:data
  })
}

//保证金充值列表
export function rechargeList(data) {
  return request({
    url: 'api/orderDeposit/rechargeList',
    method: 'post',
    data:data
  })
}

//保证金订单-分页列表查询
export function orderDepositList(data) {
  return request({
    url: 'api/orderDeposit/list',
    method: 'get',
    params:data
  })
}

//聊天订单列表
export function timeList(data) {
  return request({
    url: 'api/order/timeList',
    method: 'get',
    params:data
  })
}

//语音通话
export function voiceCall(data) {
  return request({
    url: 'api/order/voiceCall',
    method: 'get',
    params:data
  })
}

//更新订单状态
export function updateStatus(data) {
  return request({
    url: 'api/order/updateStatus',
    method: 'get',
    params:data
  })
}


//更新订单状态
export function receiveGoods(data) {
  return request({
    url: 'api/receiveGoods/list',
    method: 'get',
    params:data
  })
}

//收藏-删除过期
export function deleteExpire(data) {
  return request({
    url: 'api/collection/deleteExpire',
    method: 'get',
    params:data
  })
}

// 收藏-删除所有
export function deleteAll(data) {
  return request({
    url: 'api/collection/deleteAll',
    method: 'get',
    params:data
  })
}
// 收藏-删除单个
export function collectionDelete(data) {
  return request({
    url: 'api/collection/delete',
    method: 'get',
    params:data
  })
}
// 收藏-删除多个
export function collectionDeleteBatch(data) {
  return request({
    url: 'api/collection/deleteBatch',
    method: 'get',
    params:data
  })
}

// 角色删除
export function roleDelete(data) {
  return request({
    url: 'api/receiveRole/delete',
    method: 'get',
    params:data
  })
}

// 角色添加
export function roleAdd(data) {
  return request({
    url: 'api/receiveRole/add',
    method: 'post',
    data:data
  })
}

// 出售收货商品
export function saleGoods(data) {
  return request({
    url: 'api/receiveGoods/sale',
    method: 'get',
    params:data
  })
}

// 取消订单（买家或代练）
export function cancelOrder(data) {
  return request({
    url: 'api/order/cancelOrder',
    method: 'post',
    data:data
  })
}

// 账号回收（代练商家）
export function storeAccountList(data) {
  return request({
    url: 'api/settledStore/accountList',
    method: 'get',
    params:data
  })
}

// 区服根据跨区 区服id获取
export function districts(data) {
  return request({
    url: 'api/game/districts',
    method: 'get',
    params:data
  })
}

// 取消订单（卖家或发布代练方）
export function cancelOrderSale(data) {
  return request({
    url: 'api/order/cancelOrderSale',
    method: 'post',
    data:data
  })
}


// 确认收货
export function conform(data) {
  return request({
    url: 'api/order/conform',
    method: 'post',
    data:data
  })
}


// // 确认收货
// export function conform(data) {
//   return request({
//     url: 'api/order/conform',
//     method: 'post',
//     data:data
//   })
// }

// 取消原因
export function cancelReasonList(data) {
  return request({
    url: 'api/order/cancelReasonList',
    method: 'get',
    params:data
  })
}

// 获取订单详情
export function queryDetail(data) {
  return request({
    url: 'api/order/queryDetail',
    method: 'get',
    params:data
  })
}

// 申请理赔
export function applyInsure(data) {
  return request({
    url: 'api/order/applyInsure',
    method: 'post',
    data:data
  })
}

// 理赔列表
export function orderInsureList(data) {
  return request({
    url: 'api/order/insureList',
    method: 'get',
    params:data
  })
}

// 热门搜索
export function hotList(data) {
  return request({
    url: 'api/game/hotList',
    method: 'get',
    params:data
  })
}

// 热门搜索
export function gameBanner(data) {
  return request({
    url: 'api/game/banner',
    method: 'get',
    params:data
  })
}

// 单聊会话记录-添加
export function sessionRecordAdd(data) {
  return request({
    url: 'api/sessionRecord/add',
    method: 'post',
    data:data
  })
}

// 单聊会话记录-添加
export function sessionRecordList(data) {
  return request({
    url: 'api/sessionRecord/list',
    method: 'get',
    params:data
  })
}

// 单聊会话记录-添加
export function tradeMessageList(data) {
  return request({
    url: 'api/order/tradeMessageList',
    method: 'get',
    params:data
  })
}

// 单聊会话记录-添加
export function saveReceiveRole(data) {
  return request({
    url: 'api/order/saveReceiveRole',
    method: 'get',
    params:data
  })
}

// 申请交易客服
export function applyService(data) {
  return request({
    url: 'api/order/applyService',
    method: 'get',
    params:data
  })
}

// 获取单条会话记录
export function queryOne(data) {
  return request({
    url: 'api/sessionRecord/queryOne',
    method: 'get',
    params:data
  })
}


// 获取单条会话记录
export function quitById(data) {
  return request({
    url: 'api/settledStore/quitById',
    method: 'get',
    params:data
  })
}

// 查看押金余额列表
export function getPaidMoney(data) {
  return request({
    url: 'api/detail/getPaidMoney',
    method: 'post',
    data:data
  })
}

// 清空购物车
export function shopCartDeleteAll(data) {
  return request({
    url: 'api/shopCart/deleteAll',
    method: 'post',
    data:data
  })
}

// 清空购物车
export function deleteList(data) {
  return request({
    url: 'api/shopCart/deleteList',
    method: 'post',
    data:data
  })
}

// 删除过期游戏商品
export function deleteExpireTime(data) {
  return request({
    url: 'api/gameCommodity/deleteExpireTime',
    method: 'get',
    params:data
  })
}

// 订单支付成功轮询接口
export function orderQueryById(data) {
  return request({
    url: 'api/order/queryById',
    method: 'get',
    params:data
  })
}

// 提现账户删除
export function withdrawAccountDelete(data) {
  return request({
    url: 'api/withdrawAccount/delete',
    method: 'get',
    params:data
  })
}
// 电子保单
export function ePolicy(data) {
  return request({
    url: 'api/order/ePolicy',
    method: 'get',
    params:data
  })
}


// 保证金订单-通过id查询
export function orderDepositQueryById(data) {
  return request({
    url: 'api/orderDeposit/queryById',
    method: 'get',
    params:data
  })
}

// 更新卖家最新回复时间
export function updateLastReplyTime(data) {
  return request({
    url: 'api/order/updateLastReplyTime',
    method: 'get',
    params:data
  })
}

// 更新卖家最新回复时间
export function messageEdit(data) {
  return request({
    url: 'api/message/edit',
    method: 'post',
    data:data
  })
}

// 交易公告
export function tradeList(data) {
  return request({
    url: 'api/message/tradeListNew',
    method: 'get',
    params:data
  })
}

// 温馨提示
export function tipsList(data) {
  return request({
    url: 'api/message/tipsList',
    method: 'get',
    params:data
  })
}

// 首页 网游还是手游图片
export function queryPic(data) {
  return request({
    url: '/api/game/queryPic',
    method: 'get',
    params:data
  })
}

// 聊天页面轮播图
export function queryPicByType(data) {
  return request({
    url: '/api/game/queryPicByType',
    method: 'get',
    params:data
  })
}


// 协议列表
export function protocolList(data) {
  return request({
    url: '/api/accountAppeal/protocolList',
    method: 'get',
    params:data
  })
}

// 常见问题
export function commonProblemList(data) {
  return request({
    url: 'api/accountAppeal/commonProblemList',
    method: 'get',
    params:data
  })
}
// 售前客服
export function consult(data) {
  return request({
    url: 'api/accountAppeal/consult',
    method: 'get',
    params:data
  })
}

// 黑号查询
export function blackQuery(data) {
  return request({
    url: 'api/accountAppeal/blackQuery',
    method: 'get',
    params:data
  })
}

// 收款账户验证
export function accountVerification(data) {
  return request({
    url: 'api/accountAppeal/accountVerification',
    method: 'get',
    params:data
  })
}

// 获取订单详情
export function queryDetailAll(data) {
  return request({
    url: 'api/order/queryDetailAll',
    method: 'get',
    params:data
  })
}
// 获取订单详情
export function listDailian(data) {
  return request({
    url: 'api/gameCommodity/listDailian',
    method: 'post',
    data:data
  })
}

// 验证
export function validate(data) {
  return request({
    url: 'api/login/validate',
    method: 'get',
    params:data
  })
}
// 获取配置信息
export function getConfig(data) {
  return request({
    url: 'api/login/getConfig',
    method: 'get',
    params:data
  })
}
// 查询对前端开放的配置项
export function getConfigList(data) {
  return request({
    url: 'api/login/getConfigList',
    method: 'get',
    params:data
  })
}

// 打开页面准备发货
export function updateLastReplyTimeById(data) {
  return request({
    url: 'api/order/updateLastReplyTimeById',
    method: 'get',
    params:data
  })
}

// 获取配置信息
export function gameQueryById(data) {
  return request({
    url: 'api/game/queryById',
    method: 'get',
    params:data
  })
}

// 游戏-统计
export function statGame() {
  return request({
    url: 'api/game/statGame',
    method: 'get'
  })
}

// 猜你喜欢
export function likeList(data) {
  return request({
    url: 'api/gameCommodity/likeList',
    method: 'get',
    params:data
  })
}

//关闭聊天框
export function closeConsultById(data) {
  return request({
    url: 'api/order/closeById',
    method: 'get',
    params:data
  })
}

//统计订单金额
export function statPrice(data) {
  return request({
    url: 'api/order/statPrice',
    method: 'get',
    params:data
  })
}

//提交已发货材料
export function sendOver(data) {
  return request({
    url: 'api/order/sendOver',
    method: 'post',
    data:data
  })
}

//足迹列表
export function browseList(data) {
  return request({
    url: 'api/browse/list',
    method: 'get',
    params:data
  })
}

//足迹批量删除
export function browseDeleteBatch(data) {
  return request({
    url: 'api/browse/deleteBatch',
    method: 'delete',
    params:data
  })
}

//足迹删除所有
export function browseDeleteAll(data) {
  return request({
    url: 'api/browse/deleteAll',
    method: 'delete',
    params:data
  })
}

//支付记录查询
export function queryPayById(data) {
  return request({
    url: 'api/payMessage/queryById',
    method: 'get',
    params:data
  })
}

//客户投诉-添加
export function complainAdd(data) {
  return request({
    url: 'api/complain/add',
    method: 'post',
    data:data
  })
}














