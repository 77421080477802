<template>
	<div >
		<div class="roleTitle">
			<el-badge :value="statusNum.allNum == 0 ? '' : statusNum.allNum" class="titleItem">
				<div @click="flagClick('')" :class="{'action':search.flag === ''}">全部订单</div>
			</el-badge>
			<el-badge :value="statusNum.notPayNum == 0 ? '' : statusNum.notPayNum" class="titleItem">
				<div @click="flagClick(0)" :class="{'action':search.flag === 0}">待支付</div>
			</el-badge>
			<el-badge :value="statusNum.tradeNum == 0 ? '' : statusNum.tradeNum" class="titleItem">
				<div @click="flagClick(100)" :class="{'action':search.flag === 100}">交易中</div>
			</el-badge>
<!--			<el-badge :value="statusNum.payNum == 0 ? '' : statusNum.payNum" class="titleItem">-->
<!--				<div @click="flagClick(1)" :class="{'action':search.flag === 1}">待发货</div>-->
<!--			</el-badge>-->
<!--			<el-badge :value="statusNum.notConfirmNum == 0 ? '' : statusNum.notConfirmNum" class="titleItem">-->
<!--				<div @click="flagClick(2)" :class="{'action':search.flag === 2}">待确认</div>-->
<!--			</el-badge>-->
<!--			<el-badge :value="statusNum.notReceiveNum == 0 ? '' : statusNum.notReceiveNum " class="titleItem">-->
<!--				<div @click="flagClick(3)" :class="{'action':search.flag === 3}">已发货</div>-->
<!--			</el-badge>-->
			<el-badge :value="statusNum.completeNum == 0 ?  '' : statusNum.completeNum  " class="titleItem">
				<div @click="flagClick(4)" :class="{'action':search.flag === 4}">已完成</div>
			</el-badge>
			<el-badge :value="statusNum.cancelNum == 0 ? '' : statusNum.cancelNum " class="titleItem">
				<div @click="flagClick(-1)" :class="{'action':search.flag === -1}">已取消</div>
			</el-badge>
		</div>
		<div class="fl search">
			<div class="fl">
				<div class="searchText">游戏区服：</div>
				<div style="position: relative;">
					<el-cascader clearable :append-to-body="false" v-model="districtId" style="width: 206px;"
						:options="gameList" :props="districtProps"></el-cascader>
				</div>
			</div>
			<div class="fl">
				<div class="searchText">商品类型：</div>
				<div style="position: relative;">
					<el-cascader clearable :append-to-body="false" v-model="commodityTypeId" style="width: 206px;"
						:options="gameList1" :props="districtProps1"></el-cascader>
				</div>
			</div>
			<div class="fl">
				<div class="searchText">交易类型：</div>
				<el-select clearable :popper-append-to-body="false" style="width: 208px;" v-model="search.deliveryType"
					placeholder="请选择">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
		</div>

		<div class="fl search">
			<div class="fl">
				<div class="searchText">关键字：</div>
				<el-input v-prevent-space v-model="search.title" style="width: 208px;" placeholder="请输入关键字"></el-input>
				<!-- <el-select :popper-append-to-body="false" style="width: 286px;" v-model="value" placeholder="请选择">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select> -->
			</div>
			<div class="fl">
				<div class="searchText">创建时间：</div>
				<div style="position: relative;">
					<el-date-picker popper-class="popperClass" format="yyyy-MM-dd" style="width: 188px;" value-format="yyyy-MM-dd HH:mm:ss"
						:append-to-body="false" v-model="search.createTimeBegin" type="date" placeholder="选择日期">
					</el-date-picker>
				</div>
				<span style="margin: 0 10px; color: #000;">-</span>
				<div style="position: relative;">
					<el-date-picker popper-class="popperClass" format="yyyy-MM-dd" style="width: 188px;" value-format="yyyy-MM-dd HH:mm:ss"
						:append-to-body="false" v-model="search.createTimeEnd" type="date" placeholder="选择日期">
					</el-date-picker>
				</div>
			</div>
			<div style="margin-left: 11px;">
				<el-button type="primary" @click="getList" icon="el-icon-search">搜索</el-button>
			</div>
		</div>

<!--		<div class="operate fl">-->
<!--			<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"-->
<!--				@change="handleCheckAllChange">全选</el-checkbox>-->
<!--			<div style="margin-left: 24px;">-->
<!--				<el-button @click="batchPay"  size="mini">批量支付</el-button>-->
<!--&lt;!&ndash;				<el-button @click="batchSold" size="mini">确认收货</el-button>&ndash;&gt;-->
<!--			</div>-->
<!--		</div>-->
		<div class="fl paging" style="justify-content: space-between;font-size: 18px;margin-top: 20px;">
			<div></div>
			<el-pagination popper-class="popperClass" @size-change="sizeChange" @current-change="currentChange"
				:current-page="search.pageNo" :page-sizes="[20, 30,50]" :page-size="search.pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="totalNumber">
			</el-pagination>
		</div>
		<el-checkbox-group @change="handleCheckedCitiesChange" v-model="ids">
			<div style="scrollbar-width: none; ">
				<div class="mineOrder" v-for="(item,index) in tableList">
					<div class="orderTop fl">
						<div class="fl" style="padding-right: 24px;">
							<div class="fl" style="margin-left: 24px;">
								<el-checkbox style="margin-right: 8px;" :label="item.id">&ensp;</el-checkbox>
								<span class="credit">订单编号：</span>
								<span class="credit" style="color: #1D223C;">{{item.id}}</span>
							</div>
							<div class="fl" style="margin-left: 24px;">
								<span class="credit">下单时间：</span>
								<span class="credit" style="color: #1D223C;">{{item.createTime}}</span>
							</div>
						</div>
						<div>
							<div class="fl">
								<span class="credit">成交金额：</span>
								<span class="credit"
									style="color: #FF5C00;font-size: 18px;font-weight: bold;">¥{{item.practicalPrice}}</span>
							</div>
						</div>
					</div>

					<div class="fl orderBottom">
						<div style="display: flex;margin-left: 24px;">
							<img :src="getImgView(item.pic)" style="width: 80px;height: 80px;border-radius: 12px;" />
							<div style="margin-left: 16px;">
								<div class="infoTitle el1" @click="openLink(item)">
									<span v-if="item.deliveryType == 2" class="infoImage" style="background-color: rgb(183, 66, 255)">平台发货</span>
									<span v-else class="infoImage">卖家发货</span>
									<span>【{{ item.gameName }}】{{item.title}}</span>
								</div>
								<div class="idItem">
									<span>游戏区服：</span>
									<span style="color: #1D223C;">{{item.districtName}}</span>
								</div>
								<div class="idItem">
									<span>商品类型：</span>
									<span style="color: #1D223C;">{{item.commodityTypeName}}</span>
								</div>
							</div>
						</div>
						<div class="idItem infoItem">
							<span v-if="item.status == -1">已取消</span>
							<span v-if="item.status == -2">已拒绝</span>
							<span v-if="item.status == 0">待支付</span>
							<span v-if="item.status == 1">待发货</span>
							<span v-if="item.status == 2">待确认</span>
							<span v-if="item.status == 3&&!item.receiveTime">已发货</span>
							<span v-if="item.status == 3&&item.receiveTime">已收货</span>
							<span v-if="item.status == 4">已完成</span>
							<span v-if="item.status == 5">售后中</span>
							<span v-if="item.status == 6">已赔付</span>
							<span v-if="item.status == 7">已退款</span>
						</div>
						<div class="idItem infoItem">x{{item.num}}</div>
						<div v-if="item.deliveryType == 3 " class="price">¥{{item.practicalPrice}}</div>
						<div v-else-if=" item.deliveryType == 5" class="price">¥{{item.practicalPrice}}</div>
						<div v-else class="price">¥{{item.practicalPrice}}</div>
						<div class="state" v-if="item.status == 0">
							<span class="btn1" @click="toPay(item)">立即支付</span>
							<!-- 跳转到客服聊天 -->
							<span class="btn3" v-if="item.deliveryType != 3" @click="toChat(item)">联系客服</span>
							<span slot="reference" class="idItem" style="width: 128px;text-align: center;margin: 0;"  @click="orderCancel(item)" >取消订单</span>
						</div>
						<div class="state" v-else-if="item.status == 1">
<!--							<span class="btn2" v-if="item.deliveryType != 2" @click="remind(item)">提醒发货</span>-->
							<!-- 跳转到客服聊天 -->
							<span class="btn3" @click="toChat(item)">联系客服</span>
							<span slot="reference" class="idItem" style="width: 128px;text-align: center;margin: 0;"  @click="orderCancel(item)" >取消订单</span>
						</div>
						<div class="state" v-else-if="item.status == 3">
							<span class="btn2" v-if="item.deliveryType == 1&&!item.receiveTime" @click="dialogShow(item,'确认收货')">确认收货</span>
							<!-- 跳转到客服聊天 -->
							<span class="btn3" @click="toChat(item)">联系客服</span>
						</div>
						<div class="state" v-else>
<!--							<span v-if="item.gameCommodityCategory == 5 || item.gameCommodityCategory== 6 "-->
<!--								@click="dialogShow(item,'查看账号')" class="btn2">查看账号</span>-->
							<!-- 跳转到客服聊天 -->
							<span class="btn3" @click="toChat(item)">联系客服</span>

						</div>
					</div>
				</div>

				<el-empty v-if="tableList.length == 0" :image-size="200"></el-empty>

			</div>
		</el-checkbox-group>



		


		<el-dialog :title="confirmText" :visible.sync="confirmShow" width="30%" style="margin-top: 200px;">
			<div>
				<div class="fl formItem">
					<div class="submitLable">
						<span style="color:#FF3C3C;">*</span>
						<span class="lableText">验证方式：</span>
					</div>
					<div class="submitValue">
						<el-select :popper-append-to-body="false" v-model="reasonValue" placeholder="请选择">
							<el-option v-for="item in reason" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="fl formItem" v-if="reasonValue == 1">
					<div class="submitLable">
						<span style="color:#FF3C3C;">*</span>
						<span class="lableText">支付密码：</span>
					</div>
					<div class="submitValue">
						<el-input v-prevent-space maxlength="6" show-password v-model="payPass"
							placeholder="请输入您的支付密码"></el-input>
					</div>
				</div>

				<div class="fl formItem" v-if="reasonValue == 2">
					<div class="submitLable">
						<span style="color:#FF3C3C;">*</span>
						<span class="lableText">验证码：</span>
					</div>
					<div class="submitValue">
						<el-input v-prevent-space maxlength="6" show-password v-model="payPass"
							placeholder="请输入短信验证码"></el-input>
						<span @click="getCode('7')" class="getCode">{{ countDown || '获取验证码'}}</span>
					</div>
				</div>
			</div>

			<span slot="footer" class="dialog-footer">
				<el-button @click="confirmShow = false">取 消</el-button>
				<el-button type="primary" @click="updateStatus">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="账号信息" :visible.sync="confirmShow1" width="30%" style="margin-top: 200px;">
			<div>

				<div class="fl formItem" v-for="(item,index) in confirmInfo.gameCommodityAccountInfos">
					<div class="submitLable" style="width: 150px;">

						<span class="lableText">{{item.title}}：</span>
					</div>
					<div class="submitValue">
						<el-input :value="item.content" readonly="true"></el-input>
					</div>
					<div style="color: #0085FF;" v-clipboard:copy="item.content" v-clipboard:success="onCopy">
						复制
					</div>
				</div>


			</div>

			<span slot="footer" class="dialog-footer">
				<el-button @click="confirmShow1 = false">取 消</el-button>
				<el-button type="primary" @click="confirmShow1 = false">确 定</el-button>
			</span>
		</el-dialog>


	</div>
</template>

<script>
	import {
		getOrderList,
		getOrderNum,
		gameList,
		districtList,
		gameCommodityTypes,
		cancelOrder,
		voiceCall,
		updateStatus,
		conform,
		realInfo,
		onePay,
		queryDetailAll,
		fush
	} from '@/api/index.js'
	import {
		smsSend
	} from '@/api/public.js'
	import noDate from '@/components/noData.vue'
	export default {
		components: {
			noDate
		},
		data() {
			return {
				confirmShow1: false,
				confirmInfo: {},
				confirmText: '',
				ids: [],
				checkAll: false,
				isIndeterminate: false,
				cities: [],
				confirmType: '',

				confirmShow: false,
				confirmItem: {},
				payPass: '',
				// 倒计时
				countDown: 0,
				countdownInterval: null,
				reasonValue: 1,
				reason: [{
					value: 1,
					label: '支付密码验证'
				}],
				actionIndex: 0,
				options: [
					{
						value: '',
						label: '全部'
					},{
					value: '1',
					label: '卖家发货'
				}, {
					value: '2',
					label: '平台发货'
				// }, {
				// 	value: '3',
				// 	label: '收货'
				// },{
				// 	value: '5',
				// 	label: '热卖'
				}],
				districtId: [],
				commodityTypeId: [],
				search: {
					pageNo: 1,
					pageSize: 20,
					orderType: '1',
					flag: '',
					title: '',
					createTimeBegin: '',
					createTimeEnd: '',
					// // 交易类型
					deliveryType: '',
					// // 区服id
					districtId: '',
					isTrain: 0,
					// // 商品分类id
					commodityTypeId: null
				},
				totalNumber: 0,
				tableList: [],
				statusNum: {},
				gameList: [],
				gameList1: [],
				payInfo: null,
				districtProps1: {
					value: 'id',
					label: 'name',
					// leaf:'hasChild',
					lazy: true,
					lazyLoad(node, resolve) {
						if (!node.value) {
							return
						}
						gameCommodityTypes({
							id: node.value.toString()
						}).then(res => {
							const {
								level
							} = node;
							let newList = []
							res.result.forEach(data => {

								data.leaf = level

								newList.push(data)
							})
							resolve(newList)
						})
					},
				},
				districtProps: {
					value: 'id',
					label: 'name',
					// leaf:'hasChild',
					lazy: true,
					lazyLoad(node, resolve) {
						if (!node.value) {
							return
						}
						districtList({
							id: node.value.toString()
						}).then(res => {
							const {
								level
							} = node;
							let newList = []
							res.result.forEach(data => {
								if (data.hasChild == 0) {
									data.leaf = level
								}
								newList.push(data)
							})
							resolve(newList)
						})
					},
				}
			}
		},
		created() {
			if (this.$route.query.type) {
				this.search.flag = eval(this.$route.query.type)
			}
			this.getList()
			this.getOrderNums()
			this.getGameList()

		},
		methods: {
			onCopy() {
				this.message('复制成功', 'success')
			},
			// 批量支付
			batchPay() {
				if (this.ids.length == 0) {
					this.message('请选择订单', 'error')
					return
				}
				onePay({
					orderId: this.ids.join(',')
				}).then(res => {
					if (res.code == 200) {
						this.payInfo = res.result
						this.payInfo.createTime = this.sjCurrent(6)
						// localStorage.setItem('orderPay', JSON.stringify(this.payInfo));
						this.$router.push({
							path: '/orderPay',
							query: {
								info: JSON.stringify(this.payInfo)
							}
						})
					}

				})
			},
			//批量收货
			batchSold() {
				if (this.ids.length == 0) {
					this.message('请选择订单', 'error')
					return
				}
				this.confirmText = '确认收货'
				this.confirmType = 'batch'
				this.confirmShow = true

			},
			getGameList() {
				// 获取游戏列表
				gameList({
					pageNo: 1,
					pageSize: 1000
				}).then(res => {
					if (res.code == 200) {
						this.gameList = res.result.records
						this.gameList1 = res.result.records

					}
				})
			},
			sizeChange(e) {
				this.search.pageSize = e.toString()
				this.search.pageNo = '1'
				this.getList()
			},
			currentChange(e) {
				this.search.pageNo = e.toString()
				this.getList()
			},
			// 确认收货弹框
			dialogShow(item, text) {
				// 判断是否设置支付密码
				// this.confirmType = ''
				// this.confirmItem = item
				// this.confirmText = text
				// this.confirmShow = true
				this.$confirm('确定已经收到【' + item.id + '】订单的商品?', '确认收货',
						{
							confirmButtonText: '确定',
							cancelButtonText : '取消',
							type             : 'warning',
						}
				).then(() => {
					conform({ id: item.id }).then(res => {
						this.message('操作成功', 'success')
						this.getList()
						this.getOrderNums()
					})
				}).catch(() => {})
			},
			// 确认收货
			updateStatus() {
				if (this.confirmText == '确认收货') {
					if (this.confirmType == 'batch') {
						conform({
							id: this.ids.join(','),
							password: this.payPass
						}).then(res => {
							if (res.code == 200) {
								this.message('收货成功', 'success')
								this.getList()
								this.getOrderNums()
								this.confirmShow = false
							}
						})
					} else {
						conform({
							id: this.confirmItem.id,
							password: this.payPass
						}).then(res => {
							if (res.code == 200) {
								this.message('收货成功', 'success')
								this.getList()
								this.getOrderNums()
								this.confirmShow = false
								this.payPass = ''
							}
						})
					}
				} else {
					queryDetailAll({
						id: this.confirmItem.id,
						password: this.payPass
					}).then(res => {
						if (res.code == 200) {
							this.message('验证成功', 'success')
							this.confirmShow = false
							this.confirmInfo = res.result
							this.confirmShow1 = true
							this.payPass = ''
						}
					})
				}


			},
			getOrderNums() {
				getOrderNum({
					orderType: 1,
					isTrain: 0
				}).then(res => {
					if (res.code == 200) {
						res.result.tradeNum = res.result.payNum + res.result.notReceiveNum
						res.result.tradeNum = isNaN(res.result.tradeNum) ? 0 : res.result.tradeNum
						this.statusNum = res.result
					}

				})
			},
			getList() {
				if (this.districtId.length != 0) {
					this.search.districtId = this.districtId[this.districtId.length - 1]
				} else {
					this.search.districtId = null
				}
				if (this.commodityTypeId.length != 0) {
					this.search.commodityTypeId = this.commodityTypeId[this.commodityTypeId.length - 1]
				} else {
					this.search.commodityTypeId = null
				}
				console.log(this.search)
				getOrderList(this.search).then(res => {
					if (res.code == 200) {
						this.cities = res.result.records
						this.tableList = res.result.records
						this.totalNumber = res.result.total
					}

				})
			},
			// 跳转到聊天
			toChat(item) {
				window.open(
						this.$router.resolve({
							path: `/chat/${item.serviceId}?sessionId=${item.id}`,
						}).href,
						'_blank'
				)
			},
			// 状态搜索
			flagClick(type) {
				this.search.flag = type
				this.getList()
			},
			// 取消订单
			orderCancel(item) {
				this.$prompt('请输入取消原因', '取消说明', {
					confirmButtonText: '确定',
					cancelButtonText : '取消',
				}).then(({ value }) => {
					cancelOrder({
						orderId: item.id,
						reasonType: 1,
						cancelReason: value,
					}).then(res => {
						if (res.code == 200) {
							this.message('订单取消成功', 'success')
							this.getList()
							this.getOrderNums()
						}
					})
				})
			},
			toPay(item) {
				console.log(item)
				let info = {
					id: item.payId,
					districtName: item.districtName,
					title: item.title,
					price: item.totalPrice,
					createTime: item.createTime
				}
				// localStorage.setItem('orderPay', JSON.stringify(info));
				this.$router.push({
					path: '/orderPay',
					query: {
						info: JSON.stringify(info)
					}
				})
			},
			// 获取验证码
			getCode(type) {

				if (!/^1\d{10}$/.test(this.$store.state.userInfo.phone)) {
					this.message('手机号码格式不正确', 'error')
					return
				}
				if (this.countDown > 0) {
					return
				}
				smsSend({
					type: type,
					phoneNum: this.$store.state.userInfo.phone
				}).then(res => {
					this.message('发送成功', 'success')
					// 开始倒计时  
					this.startCountdown();
				})
			},
			startCountdown() {
				this.countDown = 60; // 初始倒计时时间（秒）  
				this.countdownInterval = setInterval(() => {
					this.countDown--;
					if (this.countDown <= 0) {
						clearInterval(this.countdownInterval);
						this.countDown = 0;
						// 可以选择是否自动重新生成验证码  
						// this.generateCode();  
					}
				}, 1000);
			},
			// 提醒发货
			remind(item) {
				voiceCall({
					id: item.id,
					type: 3
				}).then(res => {
					if (res.code == 200) {
						this.message('提醒成功', 'success')
					}
				})
			},
			handleCheckAllChange(val) {
				if (val) {
					this.cities.forEach(res => {
						this.ids.push(res.id)
					})
				} else {
					this.ids = []
				}
				this.checkAll = val
			},
			handleCheckedCitiesChange(value) {
				this.ids = value
				let checkedCount = value.length;
				this.checkAll = checkedCount === this.cities.length;
				this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
			},
			openLink(item){
				// window.open(this.$router.resolve({
				// 	path: '/orderDetail/'+ item.gameCommodityId,
				// }).href, '_blank');
				if (item.status == 0) {
					let info = {
						title       : item.title,
						districtName: item.districtName,
						price       : item.price,
						id          : item.payId,
						createTime  : item.createTime,
					}
					this.$router.push({
						path: '/orderPay',
						query: {
							info: JSON.stringify(info),
						},
					})
				} else {
					this.$router.push('/orderDetail/' + item.id + '/' + item.gameCommodityId)
				}
			}


		}
	}
</script>

<style scoped lang="scss">
	::v-deep .el-pagination span:not([class*=suffix]) {
		font-size: 16px;
	}

	::v-deep .el-pager li {
		font-size: 16px;
	}

	.roleTitle {

		width: 856px;
		border-bottom: 1px solid #ECECEC;
		height: 57px;
		// line-height: 87px;
		padding-left: 42px;
		padding-right: 42px;
		display: flex;
		align-items: center;

		.titleItem {
			font-weight: 400;
			font-size: 16px;
			position: relative;
			color: #1D223C;
			height: 30px;
			margin-right: 50px;
			cursor: pointer;
		}

		.titleItem::after {
			position: absolute;
			width: 1px;
			height: 20px;
			content: '';
			top: 2px;
			right: -25px;
			background-color: #DEDEDE;
		}

		.titleItem:last-child::after {
			width: 0px;
		}
	}

	.search {
		padding: 24px 0 0 0;

		.searchText {
			width: 100px;
			text-align: right;
			font-weight: 400;
			font-size: 14px;
			color: #1D223C;
		}

		::v-deep .popperClass {
			position: absolute !important;
			top: auto !important;
			left: auto !important;
		}

		::v-deep .el-select-dropdown {
			position: absolute !important;
			top: auto !important;
			left: auto !important;
		}

		::v-deep .el-cascader__dropdown {
			position: absolute !important;
			top: auto !important;
			left: auto !important;
		}

		// ::v-deep .el-select{
		// 	position: absolute !important;
		// 	top: auto !important;
		// 	left: auto !important;
		// }
	}

	.cardPrice {
		font-weight: bold;
		font-size: 18px;
		color: #1D223C;
	}

	.action {
		color: #0085FF;
	}

	.mineOrder {
		border: 1px solid #DEDEDE;
		width: 930px;
		border-radius: 8px;
		margin-top: 24px;
		margin-left: 30px;
	}

	.orderTop {
		// width: 1232px;
		padding-right: 24px;
		height: 64px;
		background: #FCFCFC;
		border-radius: 8px 8px 0px 0px;
		border-bottom: 1px solid #DEDEDE;
		justify-content: space-between;

		.credit {
			font-weight: 400;
			font-size: 14px;
			color: #A5A7B1;
			margin-right: 5px;
		}
	}

	.infoTitle {
		font-weight: 600;
		font-size: 14px;
		line-height: 30px;
		color: #000000;
		width: 280px;
		cursor: pointer;
		.infoAccount {
			font-size: 12px;
			background: #FF5C00;
			border-radius: 4px;
			color: #fff;
			padding: 4px 6px;
		}

		
	}

	.idItem {
		font-weight: 400;
		font-size: 12px;
		color: #A5A7B1;
		margin-top: 8px;
		cursor: pointer;
	}

	.orderBottom {
		height: 108px;
	}

	.infoItem {
		margin: 0;
		width: 130px;
		text-align: center;
	}

	.price {
		font-weight: bold;
		font-size: 20px;
		color: #1D223C;
		width: 130px;
		text-align: center;
	}

	.state {
		width: 128px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		

		.btn1 {
			width: 128px;
			height: 35px;
			background: #FF5C00;
			border-radius: 6px 6px 6px 6px;

			font-weight: 500;
			font-size: 14px;
			color: #FFFFFF;
			text-align: center;
			line-height: 35px;
			margin-bottom: 8px;
			cursor: pointer;
		}

		.btn2 {
			width: 128px;
			height: 35px;
			background: #19D972;
			border-radius: 6px 6px 6px 6px;
			font-weight: 500;
			font-size: 14px;
			color: #FFFFFF;
			text-align: center;
			line-height: 35px;
			margin-bottom: 8px;
			cursor: pointer;
		}

		.btn3 {
			width: 128px;
			height: 35px;
			background: #fff;
			border-radius: 6px 6px 6px 6px;
			font-weight: 500;
			font-size: 14px;
			color: #000;
			border: 1px solid #DEDEDE;
			text-align: center;
			line-height: 35px;
			margin-bottom: 8px;
			cursor: pointer;
		}
	}

	// .paging {
	// 	::v-deep .popperClass {
	// 		position: absolute !important;
	// 		top: auto !important;
	// 		left: auto !important;
	// 	}
	// }

	// ::v-deep .el-cascader-node__postfix{
	// 	position: absolute !important; 
	// 	right:50px !important;
	// }

	.formItem {
		margin-bottom: 20px;

		.submitLable {
			width: 100px;
			text-align: right;

			.lableText {
				font-weight: 400;
				font-size: 18px;
				color: #1D223C;
				line-height: 30px;
				position: relative;
			}


		}

		.submitValue {
			margin: 0 20px;
			position: relative;

			.getCode {
				position: absolute;
				color: #0085FF;
				right: 10px;
				top: 10px;
			}

			::v-deep .el-cascader {
				width: 400px;
			}

			::v-deep .el-input__inner {
				width: 300px;
			}

			::v-deep .el-popper {
				position: absolute !important;
				top: auto !important;
				left: auto !important;
			}

			::v-deep .el-select-dropdown {
				position: absolute !important;
				top: auto !important;
				left: auto !important;
			}
		}

		.submitRemark {
			// width: 40%;
			margin-left: 50px;
			color: red;
		}
	}

	.popUp {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		z-index: 999;
		background-color: rgba(0, 0, 0, .3);
		overflow: hidden;

		.realNameTitle {
			font-weight: 500;
			font-size: 24px;
			color: #1D223C;
			text-align: center;
			margin-bottom: 24px;
		}

		.realNameDetaile {
			font-weight: 400;
			font-size: 18px;
			color: #777A8A;
			text-align: center;
			line-height: 30px;
		}

		.realSubmit {
			width: 296px;
			height: 66px;
			background: linear-gradient(90deg, #03B5FF 0%, #0085FF 100%);
			border-radius: 8px 8px 8px 8px;
			font-weight: 500;
			font-size: 24px;
			color: #FFFFFF;
			margin-top: 50px;

		}
	}

	.infoImage {
		font-size: 12px;
		background: #1861ee;
		border-radius: 4px;
		color: #fff;
		padding: 4px 6px;
		margin-left: 5px;
	}

	.operate {
		margin-left: 30px;
		margin-top: 20px;

	}
</style>