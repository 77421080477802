import store from '@/store'
import router from '@/router'
import { Message } from 'element-ui'

let result;
const listeners = new Map();

const config = {
	url        : '',
	userId     : '',
	getTokenFun: null,
	//客户端类型
	clientType: 'pc',
	//心跳进程
	heartCheck: {},
	//错误次数
	wsErrorTime: 0,
	//是否锁定重试
	lockReconnect: false
};

//消息主题
export const WebSocketTopic = {
	// 心跳/无意义
	ping: 0,
	// 系统通知
	systemNotify: 1,
	// 订单消息
	orderNotify: 2,
	// 用户上线
	userLogin: 3,
	// 聊天
	chat: 4,
};

/**
 * 开启 WebSocket 链接，全局只需执行一次
 */
export function connectWebSocket(url, userId, getTokenFun) {
	// let url = WEBSOCKET_URL + '/websocket';
	if (result) {
		return;
	}
	config.url = url;
	config.userId = userId;
	config.getTokenFun = getTokenFun;
	result = new WebSocket(url);
	//update-end-author:taoyan date:2022-4-22 for:  v2.4.6 的 websocket 服务端，存在性能和安全问题。 #3278
	result.onopen = websocketOnopen;
	result.onclose = websocketOnclose;
	result.onerror = websocketOnerror;
	result.onmessage = websocketOnmessage;
	rewriteSend(result);
	//心跳检测初始化
	heartCheckFun();
}

function websocketOnopen(e) {
	console.log('[WebSocket] 连接成功', e);
	config.wsErrorTime = 0;
	config.heartCheck.start();
	userLogin();
}

function websocketOnclose(e) {
	console.log('[WebSocket] 连接断开：', e);
	result?.close();
	result = null;
	config.heartCheck.stop();
	reconnect();
}

function websocketOnerror(e) {
	console.log('[WebSocket] 连接发生错误，第%s次', config.wsErrorTime + 1, e);
	config.wsErrorTime++;
	result?.close();
	result = null;
	reconnect();
}

function websocketOnmessage(e) {
	if (e.data==='ping') {
		//心跳消息
		return;
	}
	console.log('[WebSocket] -----接收消息-------');
	try {
		const res = JSON.parse(e.data);
		if (!res.success) {
			console.warn(res.message, res)
			if (router.currentRoute.name !== 'login') {
				switch (res.code) {
					case 401:
					case 494:
					case 497:
					case 498:
					case 499:
						Message({
							message: res.message,
							type: 'error',
							duration: 5 * 1000,
						})
						store.commit('loginOut')
						setTimeout(() => {
							router.push('/login/login')
						}, 1500)
						break
				}
			}
			return;
		}
		let data = res.result;
		data = JSON.parse(data);
		console.log('消息:',data);

		messageHandler(data);
	} catch (err) {
		console.error('[WebSocket] data解析失败：', err);
	}
}

function reconnect() {
	if (config.lockReconnect) return;
	config.lockReconnect = true;
	let time = 3000;

	//没连接上会一直重连，设置延迟避免请求过多
	setTimeout(function () {
		console.info('尝试重连...');
		connectWebSocket(config.url, config.userId, config.getTokenFun);
		config.lockReconnect = false;
	}, time);
}

function heartCheckFun() {
	//心跳检测,每20s心跳一次
	config.heartCheck = {
		timeout         : 55000,//55000 毫秒
		intervalId      : null,
		serverTimeoutObj: null,
		reset           : () => {
			clearInterval(this.intervalId);
			//clearTimeout(serverTimeoutObj);
			// return this
			this.start();
		},
		start           : function () {
			this.intervalId = setInterval(function () {
				//这里发送一个心跳，后端收到后，返回一个心跳消息，
				//onmessage拿到返回的心跳就说明连接正常
				result.send('ping');
				// console.info('客户端发送心跳');
				//self.serverTimeoutObj = setTimeout(function(){//如果超过一定时间还没重置，说明后端主动断开了
				//  that.websock.close();//如果onclose会执行reconnect，我们执行ws.close()就行了.如果直接执行reconnect 会触发onclose导致重连两次
				//}, self.timeout)
			}, this.timeout);
			console.log('intervalId', this.intervalId);
		},
		stop            : function () {
			clearInterval(this.intervalId);
		},
	};
}

/**
 * 重写发送消息方法
 * @param result
 */
function rewriteSend(result) {
	result.sendMsg = function (msg, topic = WebSocketTopic.ping) {
		if (!topic && topic !== 0) {
			console.error('发送主题不能为空');
			return;
		}
		let token = config.getTokenFun();
		if (!token) {
			console.error('未登录,关闭websocker');
			close();
			return;
		}
		let message = {
			token     : token,
			imMessage : msg,
			time      : Date.now(),
			topic     : topic,
			clientType: config.clientType
		};
		result.send(JSON.stringify(message));
	};
}

/**
 * 用户登录
 * @param result
 */
function userLogin() {
	result.sendMsg({}, WebSocketTopic.userLogin);
}

//主动断开连接
export function close(restart = false) {
	console.log('[WebSocket] 主动断开连接');
	result?.close();
	result = null;
	config.lockReconnect = !restart;
	if (Object.keys(config.heartCheck).length > 0) {
		config.heartCheck.stop();
	}
	config.userId = '';
	config.getTokenFun = null;
}

/**
 * 发送消息
 * @param sessionTopic
 * @param targetId
 * @param targetType
 * @param content
 */
export function sendChat(sessionTopic , targetId, targetType, content) { // 数据发送
	let id = buildMsgSessionId(sessionTopic, config.userId, targetId)
	let msg = {
		type     : WebSocketTopic.chat,//聊天类型
		sessionId: id,
		targetId : targetId,
		//目标类型 1:客服/客服组 2:买家 3:卖家 4:咨询
		targetType: targetType,
		title     : '',
		content   : content,
	};
	result.sendMsg(msg, WebSocketTopic.chat);
}

/**
 * 添加 WebSocket 消息监听
 * @param webSocketTopic
 * @param callback
 */
export function onWebSocket(webSocketTopic, callback) {
	console.debug('添加监听', webSocketTopic);
	if (typeof callback === 'function') {
		listeners.set(webSocketTopic, callback);
		//消费本地消息
		consume(webSocketTopic, callback);
	} else {
		console.debug('[WebSocket] 添加 WebSocket 消息监听失败：传入的参数不是一个方法');
	}
}

/**
 * 解除 WebSocket 消息监听
 * @param webSocketTopic
 */
export function offWebSocket(webSocketTopic) {
	listeners.delete(webSocketTopic);
}

/**
 * 生成消息的sessionId
 * @param sessionTopic
 * @param userId
 * @param targetId
 * @returns {string|string}
 */
export function buildMsgSessionId(sessionTopic, userId, targetId) {
	let id = (sessionTopic ? sessionTopic + '-' : '');
	if (userId > targetId) {
		id += userId + '-' + targetId;
	} else {
		id += targetId + '-' + userId;
	}
	return id;
}

//消费消息
function consume(webSocketTopic, callback) {
	let storageName = getStorageName(webSocketTopic);
	let storageValue = JSON.parse(localStorage.getItem(storageName) || '{}');
	let userMessages = storageValue[config.userId];
	if (!userMessages) {
		return;
	}
	callback(userMessages)
	delete storageValue[config.userId];
	localStorage.setItem(storageName, JSON.stringify(storageValue));
}
//消息处理
function messageHandler(data){
	if (data.targetId.indexOf('.') > -1) {
		data.targetId = data.targetId.split('.')[1];
	}
	let storageName = getStorageName(data.type);
	let listenerFun = listeners.get(data.type);
	if (listenerFun) {
		listenerFun([ data ]);
	} else {
		//消息未被监听 暂存到本地
		addMessage(storageName, data);
	}
}

//获取存储名
function getStorageName(webSocketTopic){
	for (let key in WebSocketTopic) {
		if (WebSocketTopic[key] === webSocketTopic) {
			return key;
		}
	}
}

//添加消息到本地存储
function addMessage(storageName, data) {
	let storageValue = JSON.parse(localStorage.getItem(storageName) || '{}');
	let userMessages = storageValue[config.userId] || [];
	userMessages.push(data);
	storageValue[config.userId] = userMessages;
	localStorage.setItem(storageName, JSON.stringify(storageValue));
}
