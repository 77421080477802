<template>
	<div>
		<div class="roleTitle">热卖商城</div>
		<div class="fl search">
			<div class="fl">
				<div class="searchText">游戏区服：</div>
				<div style="position: relative;">
					<el-cascader clearable :append-to-body="false" v-model="districtId" style="width: 206px;"
						:options="gameList" :props="districtProps"></el-cascader>
				</div>
			</div> 
			<div class="fl">
				<div class="searchText">商品类型：</div>
				<div style="position: relative;">
					<el-cascader clearable :append-to-body="false" v-model="commodityTypeId" style="width: 206px;"
						:options="gameList1" :props="districtProps1"></el-cascader>
				</div>
			</div>
			<div class="fl">
				<div class="searchText">交易类型：</div>
				<el-select :popper-append-to-body="false" style="width: 200px;" v-model="search.deliveryType"
					placeholder="请选择">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
		</div>

		<div class="fl search">
			<div class="fl">
				<div class="searchText">关键字：</div>
				<el-input v-prevent-space v-model="search.title" style="width: 206px;" placeholder="请输入关键字"></el-input>
				<!-- <el-select :popper-append-to-body="false" style="width: 286px;" v-model="value" placeholder="请选择">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select> -->
			</div>
			<div class="fl">
				<div class="searchText">创建时间：</div>
				<div style="position: relative;">
					<el-date-picker popper-class="popperClass" format="yyyy-MM-dd" style="width: 188px;" value-format="yyyy-MM-dd HH:mm:ss"
						:append-to-body="false" v-model="search.createTimeBegin" type="date" placeholder="选择日期">
					</el-date-picker>
				</div>
				<span style="margin: 0 10px; color: #000;">-</span>
				<div style="position: relative;">
					<el-date-picker popper-class="popperClass" format="yyyy-MM-dd" style="width: 188px;" value-format="yyyy-MM-dd HH:mm:ss"
						:append-to-body="false" v-model="search.createTimeEnd" type="date" placeholder="选择日期">
					</el-date-picker>
				</div>
			</div>
			<div style="margin-left: 11px;">
				<el-button type="primary" @click="getList" icon="el-icon-search">搜索</el-button>
			</div>
		</div>


		<div class="roleTitles">
			<el-badge value="" class="titleItem">
				<div @click="flagClick('')" :class="{'action':search.flag === ''}">全部商品</div>
			</el-badge>
			<el-badge value="" class="titleItem">
				<div @click="flagClick(0)" :class="{'action':search.flag === 0}">已上架</div>
			</el-badge>
			<el-badge value="" class="titleItem">
				<div @click="flagClick(1)" :class="{'action':search.flag === 1}">已下架</div>
			</el-badge>
		</div>

		<div class="roleTitles">
			<el-button type="primary" @click="toPublic" icon="el-icon-plus">发布商品</el-button>
		</div>

		<div class="tableList" style="overflow-y: auto;height: 700px; scrollbar-width: none; ">
			<el-table ref="multipleTable" :data="tableList" tooltip-effect="dark" style="width:980px"
				@selection-change="handleCheckedCitiesChange">
				<el-table-column type="selection" prop="id" width="50">
				</el-table-column>
				<el-table-column width="180">
					<template slot="header">
						<div class="cardPrice">游戏区服/类型</div>
					</template>
					<template slot-scope="scope">
						<div style="font-size: 12px;">
							{{scope.row.gameId_dictText}}/{{scope.row.districtName}}/{{scope.row.commodityTypeId_dictText}}
							<span v-if="scope.row.childCommodityType != null">/{{scope.row.childCommodityType}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="name" width="110" align="left">
					<template slot="header">
						<div class="cardPrice">库存</div>
					</template>
					<template slot-scope="scope">
						<div class="totleInput">
							<el-input v-prevent-space v-model="scope.row.num"></el-input>
						</div>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="name" width="130" align="center">
					<template slot="header">
						<div class="cardPrice">已出货量</div>
					</template>
					<template slot-scope="scope">
						<div class="totleInput">
							<span>{{scope.row.orderNum}}</span>
						</div>
					</template> -->
				</el-table-column>

				<el-table-column prop="address" width="150">
					<template slot="header">
						<div class="cardPrice">单价</div>
					</template>
					<template slot-scope="scope">
						<!-- <div style="display: flex;align-items: center;flex-wrap: nowrap;white-space: nowrap;">
							
							1{{scope.row.commodityUnit}} = <div class="totleInput">
								<el-input v-prevent-space v-model="scope.row.unitPrice" ></el-input>
							</div>
						</div> -->
						<div style="display: flex;align-items: center;flex-wrap: nowrap;white-space: nowrap;">

							1元 = <div class="totleInput">
								<el-input v-prevent-space v-model="scope.row.unitPrice"></el-input>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="address" width="110">
					<template slot="header">
						<div class="cardPrice">单次最低购买</div>
					</template>
					<template slot-scope="scope">
						<div style="display: flex;align-items: center;flex-wrap: nowrap;white-space: nowrap;">

							<div class="totleInput">
								<el-input v-prevent-space :min="1" v-model="scope.row.minOrderNum"
									type="number"></el-input>
							</div>
						</div>
					</template>
				</el-table-column>

				<el-table-column prop="address" width="120" align="center">
					<template slot="header">
						<div class="cardPrice">比例</div>
					</template>
					<template slot-scope="scope">
						<div style="font-size: 12px;">
							<div>1元
								={{(Number(scope.row.piecesNum) / scope.row.price).toFixed(4)}}{{scope.row.commodityUnit}}
							</div>
							<div>1{{scope.row.commodityUnit}}
								={{( scope.row.price / Number(scope.row.piecesNum) ).toFixed(4)}}元</div>
						</div>
					</template>
				</el-table-column>


				<el-table-column prop="address" width="100" align="center">
					<template slot="header">
						<div class="cardPrice">状态</div>
					</template>
					<template slot-scope="scope">
						<div class="fl" v-if="scope.row.isOffShelf == 0">
							<div class="stateColor1">&emsp;</div> 热卖中
						</div>
						<div class="fl" v-if="scope.row.isOffShelf == 1">
							<div class="stateColor2">&emsp;</div> 已下架
						</div>
					</template>
				</el-table-column>

				<el-table-column prop="address" width="130" align="center">
					<template slot="header">
						<div class="cardPrice">操作</div>
					</template>
					<template slot-scope="scope">
						<div style="text-align: center;display: flex;flex-direction: column;">
							<span slot="reference" @click="txtblur(scope.row)" style="color: #85CE61;">保存</span>

							<el-popconfirm v-if="scope.row.isOffShelf == 1"
								@confirm="scope.row.isOffShelf = 0;txtblur(scope.row)" class="idItemHover"
								title="确定上架么？">
								<span slot="reference" style="color: #000;">上架</span>
							</el-popconfirm>
							<el-popconfirm v-if="scope.row.isOffShelf == 0"
								@confirm="scope.row.isOffShelf = 1;txtblur(scope.row)" class="idItemHover"
								title="确定下架么？">
								<span slot="reference" style="color: #0085FF;">下架</span>
							</el-popconfirm>

							<el-popconfirm @confirm="shopDelete(scope.row)" class="idItemHover" title="确定删除此订单么？">
								<span slot="reference" style="color: red;">删除</span>
							</el-popconfirm>
						</div>
					</template>
				</el-table-column>
			</el-table>

		</div>
		<div class="fl" style="justify-content: space-between;font-size: 18px;margin-top: 20px;padding:0 20px;">
			<div>
				<div class="all">
					<el-button @click="offShelfBatch(0)" class="allIn" style="margin-left: 24px;">批量上架</el-button>
					<el-button @click="offShelfBatch(1)" class="allIn">批量下架</el-button>
				</div>
			</div>
			<el-pagination @size-change="getSizeList" @current-change="getCurrentList" :current-page="search.pageNo"
				:page-sizes="[10, 20, 30, 40]" :page-size="search.pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="totalNumber">
			</el-pagination>
		</div>


		<el-dialog title="发布商品" :visible.sync="upDateShow" width="700px" style="margin-top: 50px;">
			<div>
				<div class="fl formItem">
					<div class="submitLable">
						<span style="color:#FF3C3C;">*</span>
						<span class="lableText">游戏区服：</span>
					</div>
					<div class="submitValue">
						<el-cascader clearable ref="district" :append-to-body="false" v-model="addFormDistrictId"
							style="width: 286px;" :options="gameList" :props="districtProps"
							@change="districtChange"></el-cascader>
					</div>
				</div>
				<div class="fl formItem">
					<div class="submitLable">
						<span style="color:#FF3C3C;">*</span>
						<span class="lableText">商品类型：</span>
					</div>
					<div class="submitValue">
						<el-cascader clearable ref="typeRef" :append-to-body="false" v-model="addForm.commodityTypeId"
							style="width: 286px;" :options="gameList1" :props="districtProps1"
							@change="commodityChange"></el-cascader>
					</div>
				</div>
				<div class="fl formItem">
					<div class="submitLable">
						<span style="color:#FF3C3C;">*</span>
						<span class="lableText">商品类型：</span>
					</div>
					<div class="submitValue">
						<el-checkbox label="1" v-model="payType1">邮寄</el-checkbox>
						<el-checkbox label="2" v-model="payType2">拍卖</el-checkbox>
						<el-checkbox label="3" v-model="payType3">摆摊</el-checkbox>
						<el-checkbox label="4" v-model="payType4">当面</el-checkbox>
					</div>
				</div>

				<div class="fl formItem" v-if="addForm.commodityTypeId != ''">
					<div class="submitLable">
						<span style="color:#FF3C3C;">*</span>
						<span class="lableText">数量：</span>
					</div>
					<div class="submitValue">
						<el-input v-prevent-space type="number" @input="setTitle" v-model="addForm.piecesNum"
							placeholder="请输入数量"></el-input>
					</div>
				</div>
				<div class="fl formItem" v-if="addForm.commodityTypeId != ''">
					<div class="submitLable">
						<span style="color:#FF3C3C;">*</span>
						<span class="lableText">价格：</span>
					</div>
					<div class="submitValue">
						<el-input type="number" v-prevent-space @input="setTitle" v-model="addForm.price"
							placeholder="请输入价格"></el-input>
					</div>
				</div>
				<div class="fl formItem" v-if="addForm.commodityTypeId != ''">
					<div class="submitLable">
						<span style="color:#FF3C3C;">*</span>
						<span class="lableText">库存：</span>
					</div>
					<div class="submitValue">
						<el-input v-prevent-space @input="numInput" v-model="addForm.num" placeholder="请输入库存"></el-input>
					</div>
				</div>
				<div class="fl formItem" v-if="addForm.commodityTypeId != ''">
					<div class="submitLable">
						<span style="color:#FF3C3C;">*</span>
						<span class="lableText">标题：</span>
					</div>
					<div class="submitValue">
						<el-input v-prevent-space v-model="addForm.title"
							:disabled="addForm.gameCommodityCategory == '1' ||  addForm.gameCommodityCategory == '12'"
							placeholder="请输入标题"></el-input>
					</div>
				</div>

				<div class="formItem fl" v-for="(item,index) in shopForm">
					<div class="submitLable"><span v-if="item.isRequired == 1"
							style="color:#FF3C3C;">*</span>{{item.name}}：</div>

					<div class="submitValue">
						<el-input v-prevent-space v-prevent-space v-if="item.form == 1" v-model="item.value"
							:placeholder="item.inputTip"></el-input>
						<el-select v-if="item.form == 3" style="width: 400px;" v-model="item.value"
							:popper-append-to-body="false" :placeholder="item.inputTip" multiple>
							<el-option v-for="(items,indexs) in item.options.split(' ')" :key="items" :label="items"
								:value="items">
							</el-option>
						</el-select>
						<div v-if="item.form == 2">
							<el-radio v-model="item.value" :label="items"
								v-for="(items,indexs) in item.options.split(' ')">{{items}}</el-radio>
						</div>
					</div>
					<div v-if="item.remark != ''" class="item3">{{item.remark}}</div>
				</div>


				<div class="formItem fl" v-if="addForm.commodityTypeId != ''">
					<div class="submitLable">
						<span style="color:#FF3C3C;">*</span>
						<span class="lableText">出货账号：</span>
					</div>
					<div class="submitValue">
						<el-input v-prevent-space v-prevent-space v-model="addForm.sellAccount"
							placeholder="请输入出货账号"></el-input>
					</div>
				</div>

				<div class="formItem fl" v-if="addForm.commodityTypeId != ''">
					<div class="submitLable">
						<span style="color:#FF3C3C;">*</span>
						<span class="lableText">出货角色：</span>
					</div>
					<div class="submitValue">
						<div class="role">
							<el-select no-data-text="暂无历史角色，请键入您的角色名" :filter-method="$event=>sellRoleFilter($event)" filterable allow-create
								:reserve-keyword="true" ref="searchSelect" style="width: 300px;"
								:popper-append-to-body="false" v-model="addForm.sellRole" placeholder="请输入角色">
								<el-option v-for="(items,indexs) in roleList" :key="items.receiveRole"
									:label="items.receiveRole" :value="items.receiveRole">
								</el-option>
							</el-select>
						</div>

					</div>
				</div>


				<div class="fl formItem" v-if="addForm.commodityTypeId != ''">
					<div class="submitLable">
						<span style="color:#FF3C3C;">*</span>
						<span class="lableText">您的联系电话：</span>
					</div>
					<div class="submitValue">
						<el-input v-prevent-space v-model="addForm.sellerPhone" placeholder="请输入联系电话"></el-input>
					</div>
				</div>
				<!-- <div class="fl formItem" v-if="addForm.commodityTypeId != ''">
					<div class="submitLable">
						<span style="color:#FF3C3C;">*</span>
						<span class="lableText">您的联系QQ：</span>
					</div>
					<div class="submitValue">
						<el-input v-prevent-space v-model="addForm.sellerQq" placeholder="请输入联系QQ"></el-input>
					</div>
				</div> -->


				<div class="formItem fl" v-if="insureList.length != 0">
					<div class="submitLable" style="color:#FF3C3C;"><span style="color:#FF3C3C;">*</span>
						<span class="lableText">赠送交易安全险：</span>
					</div>
					<div>
						<div class="secureList">
							<div v-for="(item,index) in insureList" @click="insureIndex = index" class="secureItem "
								:class="{'secureAction':insureIndex === index}">
								<!-- <div v-if="insureIndex === index" class="secureTopAction">保障升级，购赠永久包赔，让交易无忧
								</div> -->
								<!-- <div>
									<span style="font-size: 16px;margin-right: 5px;"
										v-if="item.days == '180'">享普通包赔</span>
									<span style="font-size: 16px;margin-right: 5px;"
										v-else-if="item.days == '360'">享进阶包赔</span>
									<span style="font-size: 18px;margin-right: 5px;" v-else>享普通包赔</span>
								</div> -->
								<div style="display: flex;flex-direction: column;margin-left: 15px;">
									<span
										style="font-size: 16px;margin-bottom: 5px;">￥{{getInsurePrice(item.rate)}}</span>
									<span
										style="font-size: 16px;opacity: .7;margin-bottom: 5px;">赔付百分比:{{item.paidRate}}%</span>
									<span
										style="font-size: 16px;opacity: .7;margin-bottom: 5px;">保障额度:{{getInsurePrice(item.paidRate)}}</span>
								</div>

							</div>

							<div @click="insureIndex = ''" class="secureItem "
								:class="{'secureAction':insureIndex === ''}" style="padding: 15px;text-align: center;">
								<span style="text-align: center;margin-left: 60px;font-size: 16px;">不赠送保险</span>
							</div>

						</div>
						<!-- <div class="secureTip">赠送保险的商品会被优先高亮展示</div>
						<div class="secureTip1">
							<span>1、购买交易安全险的商品带有"永久包赔","卖家投保"标识，商品更受欢迎</span>
							<span>2、"账号交易"购买保险服务后，"永久包赔"生效，若出现被卖家恶意找回等情况，平台核实后先行赔付给买家</span>
							<span>3、"账号交易"订单，购买保险后，交易双方必须签署交易合同，发生找回情况，平台法务将第一时间联合警方进行线下追回</span>
							<span>4、"金币交易"订单，购买保险后，若发生被官方回收扣除情况，平台将核实后全额赔付</span>
							<span>5、"代练交易"订单，购买保险后，若发生被盗，封号等情况，平台核实后将全额赔付</span>
						</div>
						<div class="secureTip2">
							<span>本模块为保险投保页面，选择购买表示您已阅读，理解并同意</span>
							<span style="color: #0085FF;"
								@click="$router.push('/serviceCenter/3/1808436948773982209')">《中国平安购买须知》</span>
							<span style="color: #0085FF;"
								@click="$router.push('/serviceCenter/2/1808234874761199618')">《什么是交易安全险》</span>
							<span style="color: #0085FF;"
								@click="$router.push('/serviceCenter/3/0')">《隐私政策》</span>
							<span style="color: #0085FF;"
								@click="$router.push('/serviceCenter/3/1808435680399675394')">《保险经纪委托书》</span>
							<span style="color: #0085FF;"
								@click="$router.push('/serviceCenter/3/0')">《客户告知书》</span>
							<span>。为保障您的权益将会安全记录您的操作</span>
						</div> -->
					</div>

				</div>


				<div class="formItem ">
					<div class="submitLable"><span style="color:#FF3C3C;">*</span>押金赔付：</div>
					<div class="item2">
						<div>
							<el-radio v-model="addForm.deposit" :label="1">是</el-radio>
							<el-radio v-model="addForm.deposit" :label="0">否</el-radio>
						</div>
						<div class="item3 fl" style="color: #A5A7B1;margin-top: 10px;margin-left: 0;">
							<div style="white-space: nowrap;" v-if="addForm.deposit == 1">押金 <el-input v-prevent-space
									type="number" v-model="addForm.depositMoney" @change="setDeposit"
									style="width: 80px;"></el-input>
								元/件，</div>
							商品拥有押金赔付标识，商品优先排序，订单由于卖家原因取消将押金赔付给买家。
						</div>
					</div>
				</div>
				<div class="formItem ">
					<div class="submitLable"><span style="color:#FF3C3C;">*</span>商品有效期：</div>
					<div class="submitValue">
						<el-select style="width: 400px;" :popper-append-to-body="false" v-model="addForm.expirationNum"
							placeholder="请选择有效期">
							<el-option v-for="(items,indexs) in validityList" :key="items" :label="items.value"
								:value="items.key">
							</el-option>
						</el-select>

					</div>
				</div>

				<div class="formItem ">
					<div class="submitLable"><span style="color:#FF3C3C;">*</span>交易方便时间：</div>
					<div>
						<div class="fl" style="position: relative;">
							<el-time-select :append-to-body="false" ref="pageSelect" v-model="addForm.startTime"
								popper-class="popper-select" :picker-options="{
								    start: '00:00',
								    step: '00:15',
								    end: '24:00'
								  }" placeholder="选择时间">
							</el-time-select>


							<div>&emsp;-&emsp;</div>
							<el-time-select ref="pageSelect1" v-model="addForm.endTime" popper-class="popper-select"
								:append-to-body="false" :picker-options="{
								    start: '00:00',
								    step: '00:15',
								    end: '24:00'
								  }" placeholder="选择时间">
							</el-time-select>
						</div>
					</div>
				</div>
			</div>






			<span slot="footer" class="dialog-footer">
				<el-button @click="upDateShow = false">取 消</el-button>
				<el-button type="primary" @click="publicSuccess">确 定</el-button>
			</span>
		</el-dialog>


		<!-- <div>
			<noDate></noDate>
		</div> -->
	</div>
</template>

<script>
	import {
		getOrderList,
		getOrderNum,
		gameList,
		districtList,
		gameCommodityTypes,
		getSaleOrderList,
		gameCommodityMyList,
		gameCommodityEdit,
		gameCommodityDelete,
		offShelf,
		offShelfBatch,
		deleteBatch,
		receiveRoleList,
		fieldList,
		insureList,
		gameCommodityAdd
	} from '@/api/index.js'
	import noDate from '@/components/noData.vue'
	export default {
		components: {
			noDate
		},
		data() {
			return {
				insureIndex: '',
				payType1:false,
				payType2:false,
				payType3:false,
				payType4:false,
				addForm: {

					// 发货类型
					deliveryType: 1,
					gameId: '',
					icon: '',
					gameCommodityCategory: '',
					// 标题
					title: '',
					// 价格
					price: '',
					// 图片
					pic: '',
					// 库存
					num: '1',
					piecesNum: '',
					// 游戏商品分类id
					commodityTypeId: '',
					// 游戏商品分类名称	
					commodityTypeName: '',
					// 区服id
					districtId: '',
					// 区服名称
					districtName: '',
					// 卖家手机号
					sellerPhone: '',
					// 卖家qq
					sellerQq: '',
					commodityUnit: '',
					sellAccount: '',
					sellRole: '',
					deposit: 0,
					depositMoney: 10,
					//允许降价
					priceReduction: 0,
					// 商品有效期
					expirationNum: 30,
					//交易方便开始时间
					startTime: '00:00',
					//交易方便结束时间
					endTime: '24:00',
					// 保险天数
					days: '',
					// 保险比例
					rate: '',
					insurePaidRate: '',
					// 交易方式
					tradeType: 1,
					// 游戏账号信息
					gameCommodityAccountInfos: [],
					gameCommodityFieldValues: {},
					userId: '',
					isSendInsure: '',
					isHot: 1,
					isOffShelf: 1,

				},
				isGive: 1,
				validityList: [{
						key: 30,
						value: '30天'
					},
					{
						key: 60,
						value: '60天'
					},
					{
						key: 90,
						value: '90天'
					},
					{
						key: 120,
						value: '120天'
					},
					{
						key: 150,
						value: '150天'
					},
					{
						key: 180,
						value: '180天'
					}
				],
				addFormDistrictId: [],
				upDateShow: false,
				ids: [],
				checkAll: false,

				cities: [],
				isIndeterminate: false,
				options: [{
					value: '1',
					label: '卖家发货'
				}, {
					value: '2',
					label: '平台发货'
				}],
				districtId: [],
				commodityTypeId: [],
				search: {
					pageNo: 1,
					pageSize: 5,
					orderType: '',
					flag: '',
					title: '',
					createTimeBegin: '',
					createTimeEnd: '',
					// // 交易类型
					deliveryType: null,
					// // 区服id
					districtId: null,
					// // 商品分类id
					commodityTypeId: null,
					isHot: 1,
					minInsure: 0
				},
				totalNumber: 0,
				tableList: [],
				statusNum: {},
				gameList: [],
				gameList1: [],
				districtProps1: {
					value: 'id',
					label: 'name',
					// leaf:'hasChild',
					lazy: true,
					lazyLoad(node, resolve) {
						gameCommodityTypes({
							id: node.value.toString()
						}).then(res => {
							const {
								level
							} = node;
							let newList = []
							res.result.forEach(data => {
								data.leaf = level
								if (data.isHot == 1) {
									newList.push(data)
								}
							})
							resolve(newList)
						})
					},
				},
				districtProps: {
					value: 'id',
					label: 'name',
					// leaf:'hasChild',
					lazy: true,
					lazyLoad(node, resolve) {
						districtList({
							id: node.value.toString()
						}).then(res => {
							const {
								level
							} = node;
							let newList = []
							res.result.forEach(data => {
								if (data.hasChild == 0) {
									data.leaf = level
								}
								newList.push(data)
							})
							resolve(newList)
						})
					},
				},
				public: {

				},
				roleList: [],
				shopForm: [],
				insureList: []
			}
		},
		created() {
			// if (localStorage.getItem('confirmOrderQQ') != null) {
			// 	this.addForm.sellerQq = localStorage.getItem('confirmOrderQQ')
			// } else {
			// 	if (!this.isEmpty(this.$store.state.userInfo.qq)) {
			// 		this.addForm.sellerQq = this.$store.state.userInfo.qq
			// 	}

			// }
      if (!this.isEmpty(this.$store.state.userInfo.phone)) {
        this.addForm.sellerPhone = this.$store.state.userInfo.phone
      }
			this.getList()
			this.getGameList()
		},
		methods: {
			numInput(){
				if (this.addForm.num > 9999) {
					this.addForm.num = 9999
				}
				if (this.addForm.num < 1) {
					this.addForm.num = 1
				}
			},
			sellRoleFilter(val) {
				this.$set(this.addForm, 'sellRole', val)
			},
			getInsurePrice(rate) {
				if (this.addForm.price * rate / 100 > this.addForm.minInsure) {
					return (this.addForm.price * rate / 100).toFixed(2) + '元'
				} else {
					return this.addForm.minInsure + '元'
				}
			},
			publicSuccess() {
				let tradeType=[]
				if(this.payType1){
					tradeType.push('1')
				}
				if(this.payType2){
					tradeType.push('2')
				}
				if(this.payType3){
					tradeType.push('3')
				}
				if(this.payType4){
					tradeType.push('4')
				}
				this.addForm.tradeType = tradeType.join(',')
				// console.log(tradeType)
				// return 
				if (this.addForm.districtId == '') {
					this.message('请选择游戏区服', 'error')
					return
				}
				if (this.addForm.commodityTypeId == '') {
					this.message('请选择商品类型', 'error')
					return
				}
				if (this.addForm.piecesNum == '' || this.addForm.piecesNum == null || this.addForm.piecesNum ==
					undefined) {
					this.message('请输入数量', 'error')
					return
				}
				if (this.addForm.price == '' || this.addForm.price == null || this.addForm.price == undefined) {
					this.message('请输入价格', 'error')
					return
				}
				if (this.addForm.num == '' || this.addForm.num == null || this.addForm.num == undefined) {
					this.message('请输入件数', 'error')
					return
				}
				if (this.addForm.title == '' || this.addForm.title == null || this.addForm.title == undefined) {
					this.message('请输入商品标题', 'error')
					return
				}
				if (this.addForm.deliveryType == 1) {
					if (this.addForm.sellRole == '' || this.addForm.sellRole == null || this.addForm.sellRole ==
						undefined) {
						this.message('请输入角色', 'error')
						return
					}
				}
				if (this.addForm.deliveryType == 1) {
					if (this.addForm.sellAccount == '' || this.addForm.sellAccount == null || this.addForm.sellAccount ==
						undefined) {
						this.message('请输入出货账号', 'error')
						return
					}
				}
				if (this.addForm.sellerPhone == '' || this.addForm.sellerPhone == null || this.addForm.sellerPhone ==
					undefined) {
					this.message('请输入您的联系电话', 'error')
					return
				}
				// if (this.addForm.sellerQq == '' || this.addForm.sellerQq == null || this.addForm.sellerQq == undefined) {
				// 	this.message('请输入您的联系qq', 'error')
				// 	return
				// }

				let isPublic = true
				
				

				if (this.shopForm.length != 0) {
					let newShopForm = []
					try {
						this.shopForm.forEach(item => {
							if (item.isRequired == 1) {
								if (item.value == '' || item.value == null || item.value == undefined) {
									this.message('请填写' + item.name, 'error')
									throw Error();
								}
							}

							let key = {
								iconKey: `field${item.fieldNum}Icon`,
								iconValue: item.icon,
								nameKey: `field${item.fieldNum}Name`,
								nameValue: item.name,
								valueKey: `field${item.fieldNum}Value`,
								valueValue: item.value
							}
							newShopForm.push(key)
						})
						this.addForm.gameCommodityFieldValues = newShopForm.reduce((obj, item) => {
							obj[item.iconKey] = item.iconValue
							obj[item.nameKey] = item.nameValue
							obj[item.valueKey] = item.valueValue
							return obj
						}, {})
					} catch (e) {
						isPublic = false

					}


				}

				if (!isPublic) {
					return
				}
				if (this.insureIndex === '') {
					this.isGive = 0
				} else {
					this.isGive = 1
				}
				if (this.isGive == 1) {
					this.addForm.days = this.insureList[this.insureIndex].days
					this.addForm.rate = this.insureList[this.insureIndex].rate
					this.addForm.insurePaidRate = this.insureList[this.insureIndex].paidRate
				}
				if (this.addForm.deposit == 0) {
					this.addForm.singleDepositMoney = 0
				} else {
					this.addForm.singleDepositMoney = this.addForm.depositMoney
				}
				console.log(this.addForm)
				this.addForm.userId = this.$store.state.userInfo.id
				this.addForm.price = Number(this.addForm.price).toFixed('2')
				this.addForm.isSendInsure = this.isGive
				setTimeout(() => {
					gameCommodityAdd(
						this.addForm
					).then(res => {
						if (res.code == 200) {
							if (res.result.status == 0) {
								let info = {
									title: res.result.title,
									districtName: res.result.districtName,
									price: this.totalPrice(),
									id: res.result.id,
									type: 'earnestMoney',
									depositType: 7
								}
								info.createTime = this.sjCurrent(6)
								// localStorage.setItem('orderPay', JSON.stringify(info));
								this.$router.push({
									path: '/orderPay',
									query: {
										info: JSON.stringify(info)
									}
								})


							} else {
								this.message('发布成功', 'success')
								this.upDateShow =false
								this.getList()

							}


						}
					})
				}, 1000)
			},
			// 判断押金金额是否小于10
			setDeposit(e) {
				if (this.addForm.depositMoney < 10) {
					this.addForm.depositMoney = 10
					this.message('押金最低为10元', 'error')
				}
			},
			districtChange(e) {
				this.addForm.gameId = this.$refs.district.getCheckedNodes()[0].pathNodes[0].data.id
				this.addForm.icon = this.$refs.district.getCheckedNodes()[0].pathNodes[0].data.icon
				let districtId = JSON.parse(JSON.stringify(this.$refs.district.getCheckedNodes()[0].path))
				let newDistrict = districtId.splice(0, 1)

				let districtName = JSON.parse(JSON.stringify(this.$refs.district.getCheckedNodes()[0].pathLabels))
				let newDistrictName = districtName.splice(0, 1)
				this.addForm.districtName = districtName.join('/')
				this.addForm.districtId = districtId.join('/')
				this.getReceiveRoleList()
			},
			commodityChange(e) {
				this.addForm.commodityTypeName = this.$refs.typeRef.getCheckedNodes()[0].label
				this.addForm.commodityTypeId = this.$refs.typeRef.getCheckedNodes()[0].value
				this.addForm.commodityUnit = this.$refs.typeRef.getCheckedNodes()[0].data.commodityUnit
				this.addForm.gameCommodityCategory = this.$refs.typeRef.getCheckedNodes()[0].data.gameCommodityCategory
				this.addForm.minInsure = this.$refs.typeRef.getCheckedNodes()[0].data.minInsure
				this.getFieldList()
			},
			setTitle() {
				this.addForm.piecesNum = parseInt(this.addForm.piecesNum)
				// this.addForm.price = this.addForm.price.to
				if (this.addForm.piecesNum != '' && this.addForm.price != '') {
					this.addForm.title = this.addForm.piecesNum + this.addForm.commodityUnit + '=' + Number(this.addForm
						.price).toFixed(2) + '元'
				}
			},
			getFieldList() {
				this.shopForm = []
				let that = this;
				fieldList({
					gameTypeId: this.addForm.commodityTypeId
				}).then(res => {
					if (res.code == 200) {
						this.shopForm = res.result


						this.$forceUpdate()
						that.getInsureList()
					}

				})
			},
			// 保险列表
			getInsureList() {
				insureList({
					gameTypeId: this.addForm.commodityTypeId
				}).then(res => {
					this.insureList = res.result
				})
			},

			// 获取角色列表
			getReceiveRoleList() {
				receiveRoleList({
					districtId: this.addForm.districtId,
					pageNo: '1',
					pageSize: '1000'
				}).then(res => {
					if (res.code == 200) {
						this.roleList = res.result.records
					}
				})
			},
			// 
			toPublic() {
				this.addForm.commodityTypeName = ''
				this.addForm.commodityTypeId = ''
				this.addForm.commodityUnit =0
				this.addForm.gameCommodityCategory = ''
				this.addForm.minInsure = ''
				this.addForm.districtName = ''
				
				this.addForm.districtId = ''
				this.addFormDistrictId = []
				this.upDateShow = true
				
			},
			publishShop() {
				this.$router.push('/game/addHot/0')
			},
			getGameList() {
				// 获取游戏列表
				gameList({
					pageNo: 1,
					pageSize: 1000
				}).then(res => {
					if (res.code == 200) {
						this.gameList = res.result.records
						this.gameList1 = res.result.records
					}
				})
			},
			getList() {
				if (this.districtId.length != 0) {
					this.search.districtId = this.districtId[this.districtId.length - 1]
				} else {
					this.search.districtId = null
				}
				if (this.commodityTypeId.length != 0) {
					this.search.commodityTypeId = this.commodityTypeId[this.commodityTypeId.length - 1]
				} else {
					this.search.commodityTypeId = null
				}
				gameCommodityMyList(this.search).then(res => {
					this.tableList = res.result.records
					this.cities = res.result.records
					res.result.records.forEach((item, index) => {

						this.$set(this.tableList[index], 'unitPrice', (Number(item.piecesNum) / item.price)
							.toFixed(4));
					})

					this.totalNumber = res.result.total
				})
			},
			handleCheckAllChange(val) {
				if (val) {
					this.cities.forEach(res => {
						this.ids.push(res.id)
					})
				} else {
					this.ids = []
				}
				this.checkAll = val
			},
			handleCheckedCitiesChange(value) {
				this.ids = value
				let checkedCount = value.length;
				this.checkAll = checkedCount === this.cities.length;
				this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
			},
			// 状态搜索
			flagClick(type) {
				this.search.flag = type
				this.getList()
			},
			// 删除商品
			shopDelete(item) {
				gameCommodityDelete({
					id: item.id
				}).then(res => {
					this.message('删除成功', 'success')
					this.getList()
				})
			},
			shopOut(item) {
				offShelf({
					id: item.id,
					isOffShelf: item.isOffShelf == 1 ? 0 : 1
				}).then(res => {
					this.message(res.message, 'success')
					this.getList()
				})
			},
			//修改价格
			setPrice(item, index) {
				gameCommodityEdit(item).then(res => {
					if (res.code == 200) {
						this.message('价格修改成功', 'success')
						this.$set(this.tableList[index], 'isUpdate', false);
					}
				})
			},
			update(index) {
				this.$set(this.tableList[index], 'isUpdate', true);
			},
			// 批量上架
			batchPut() {
				offShelfBatch({
					ids: this.ids.join(','),
					isOffShelf: 0
				}).then(res => {
					this.ids = []
					let checkedCount = this.ids.length;
					this.checkAll = checkedCount === this.cities.length;
					this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
					this.message(res.message, 'success')
					this.getList()
				})
			},
			// 批量下架
			batchSold() {
				offShelfBatch({
					ids: this.ids.join(','),
					isOffShelf: 1
				}).then(res => {
					this.ids = []
					let checkedCount = this.ids.length;
					this.checkAll = checkedCount === this.cities.length;
					this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
					this.message(res.message, 'success')
					this.getList()
				})
			},
			// 批量删除
			batchDelete() {
				deleteBatch({
					ids: this.ids.join(',')
				}).then(res => {
					this.message(res.message, 'success')
					this.getList()
				})
			},
			txtblur(item) {
				if (item.num == 0 || item.num == '') {
					this.message('库存不能为0', 'error')
					return
				}
				if (item.unitPrice == 0 || item.unitPrice == '') {
					this.message('请填写单价', 'error')
					return
				}
				if (item.minOrderNum < 1 || item.minOrderNum == '') {
					this.message('单次最低购买不能小于1', 'error')
					return
				}

				if (1 / Number(item.unitPrice).toFixed(4) * Number(item.minOrderNum) < Number(0.01)) {
					this.message('单次购买不得小于0.01元', 'error')
					return
				}
				
				item.price = Number(item.piecesNum) / Number(item.unitPrice).toFixed(4)
				var currentDate = new Date();
				currentDate.setDate(currentDate.getDate() + 30);

				item.expirationDate = this.sjVal(currentDate,4)
				
				gameCommodityEdit(item).then(res => {
					if (res.code == 200) {
						this.message('编辑成功', 'success')
						this.dialogVisible1 = false
						this.getList()
					}
				})
				console.log(item)
			},

		}
	}
</script>

<style scoped lang="scss">
	::v-deep .el-pagination span:not([class*=suffix]) {
		font-size: 16px;
	}

	::v-deep .el-pager li {
		font-size: 16px;
	}

	.roleTitle {

		font-weight: 600;
		font-size: 24px;
		color: #1D223C;
		width: 950px;
		border-bottom: 1px solid #ECECEC;
		height: 60px;
		line-height: 60px;
		padding-left: 42px;

		.titleItem {
			font-weight: 400;
			font-size: 20px;
			position: relative;
			color: #1D223C;
			height: 30px;
			margin-right: 50px;
		}

		.titleItem::after {
			position: absolute;
			width: 1px;
			height: 20px;
			content: '';
			top: 2px;
			right: -25px;
			background-color: #DEDEDE;
		}

		.titleItem:last-child::after {
			width: 0px;
		}
	}

	.search {
		padding: 24px 0 0 0;

		.searchText {
			width: 100px;
			text-align: right;
			font-weight: 400;
			font-size: 14px;
			color: #1D223C;
		}

		::v-deep .popperClass {
			position: absolute !important;
			top: auto !important;
			left: auto !important;
		}

		::v-deep .el-select-dropdown {
			position: absolute !important;
			top: auto !important;
			left: auto !important;
		}

		::v-deep .el-cascader__dropdown {
			position: absolute !important;
			top: auto !important;
			left: auto !important;
		}
	}

	.cardPrice {
		font-weight: bold;
		font-size: 12px;
		color: #1D223C;
	}

	.action {
		color: #0085FF;
	}

	.mineOrder {
		border: 1px solid #DEDEDE;
		width: 1232px;
		border-radius: 8px;
		margin-top: 24px;
		margin-left: 30px;
	}

	.orderTop {
		// width: 1232px;
		padding-right: 24px;
		height: 64px;
		background: #FCFCFC;
		border-radius: 8px 8px 0px 0px;
		border-bottom: 1px solid #DEDEDE;
		justify-content: space-between;

		.credit {
			font-weight: 400;
			font-size: 16px;
			color: #A5A7B1;
			margin-right: 5px;
		}
	}

	.infoTitle {
		.infoAccount {
			font-size: 12px;
			background: #FF5C00;
			border-radius: 4px;
			color: #fff;
			padding: 4px 6px;
		}

		font-weight: 600;
		font-size: 18px;
		line-height: 30px;
		color: #000000;
		width: 450px;
	}

	.idItem {
		font-weight: 400;
		font-size: 16px;
		color: #A5A7B1;
		margin-top: 8px;
	}

	.orderBottom {
		height: 148px;
	}

	.infoItem {
		margin: 0;
		width: 130px;
		text-align: center;
	}

	.price {
		font-weight: bold;
		font-size: 20px;
		color: #1D223C;
		width: 130px;
		text-align: center;
	}

	.state {
		width: 220px;
		// display: flex;
		// flex-direction: column;
		// justify-content: flex-end;
		display: grid;
		justify-items: end;

		.btn1 {
			width: 128px;
			height: 41px;
			font-weight: 500;
			font-size: 16px;
			color: #0085FF;
			text-align: center;
			line-height: 41px;
			margin-bottom: 8px;
		}

		.btn2 {
			width: 128px;
			height: 41px;
			background: rgba(0, 133, 255, 0.06);
			border-radius: 6px 6px 6px 6px;
			font-weight: 500;
			font-size: 18px;
			color: #0085FF;
			border: 1px solid #0085FF;
			text-align: center;
			line-height: 41px;
			margin-bottom: 8px;
		}
	}

	.idItemHover:hover {
		color: #0085FF;
	}

	.operate {
		margin-left: 30px;
		margin-top: 20px;

	}

	.roleTitles {

		width: 1186px;
		border-bottom: 1px solid #ECECEC;
		height: 67px;
		// line-height: 87px;
		padding-left: 42px;
		padding-right: 42px;
		display: flex;
		align-items: center;

		.titleItem {
			font-weight: 400;
			font-size: 16px;
			position: relative;
			color: #1D223C;
			height: 20px;
			margin-right: 50px;
		}

		.titleItem::after {
			position: absolute;
			width: 1px;
			height: 20px;
			content: '';
			top: 2px;
			right: -25px;
			background-color: #DEDEDE;
		}

		.titleItem:last-child::after {
			width: 0px;
		}

		.action {
			color: #0085FF;

		}
	}

	.totleInput {

		::v-deep .el-input__inner {
			width: 85px;
			height: 30px;
			text-align: center;
		}
	}

	.formItem {
		margin-top: 20px;
		color: #000;
		display: flex;

		.item1 {
			width: 400px;
			text-align: right;
		}

		.item2 {
			min-width: 400px;
			margin-left: 10px;
			position: relative;

			::v-deep .el-select-dropdown {
				position: absolute !important;

				top: 35px !important;
				left: 0px !important;
			}


		}

		.item3 {
			font-weight: 400;
			font-size: 16px;
			color: #0085FF;
			margin-left: 20px;
		}

		.item4 {
			border: 1px solid #DEDEDE;
			border-radius: 4px 4px 4px 4px;
		}

		.item5 {
			border-top: 1px solid #DEDEDE;
			font-weight: 400;
			font-size: 16px;
			color: #A5A7B1;
			height: 56px;
			line-height: 56px;
			padding-left: 20px;
		}

	}

	.submitValue {
		::v-deep .el-input {
			width: 300px;
		}
	}

	.submitLable {
		width: 120px;
	}

	.secureList {
		display: flex;
		flex-wrap: wrap;
		width: 500px;
		align-items: flex-end;

		.secureItem {
			width: 200px;
			// height: 20px;
			background: #FFFFFF;
			border-radius: 4px 4px 4px 4px;
			border: 1px solid #DEDEDE;
			text-align: center;
			// line-height: 20px;
			font-weight: 400;
			font-size: 22px;
			color: #1D223C;
			margin-bottom: 20px;
			margin-right: 16px;
			position: relative;
			display: flex;
			// padding-top: 20px;
			padding-left: 20px;


		}

		.secureAction {
			border-color: #0085FF;
			border-width: 3px;
			color: #FF3C3C;
		}
	}

	.role {
		::v-deep .el-input__suffix {
			display: none;
		}

		::v-deep .el-input__inner {
			cursor: text;
		}
	}
</style>