<template>
  <div style="background-color: #fff; border-bottom: 1px solid #e0e0e0">
    <el-row>
      <div class="content" style="display: flex; align-items: center; background-color: #fff">
        <el-col :span="24">
          <div class="s bg-purple fl headerBox">
            <div class="leftText fl">
              欢迎来到派派代售交易平台
              <!-- <span v-if="token == undefined" style="color: #FF7F37;" @click="$router.push('/login/login');">请登录</span>
							<span  v-if="token == undefined" style="margin-left: 8px;" @click="$router.push('/login/register');">
								免费注册
							</span> -->
              <div v-if="token != undefined" class="loginType fl" @click="loginOut">
                <img :src="require('@/static/image/newImage/icon5.png')" style="width: 14px; height: 14rpx; margin-right: 2px" />
                退出登录
              </div>
            </div>
            <el-menu :default-active="defaultActive" class="el-menu-demo" style="height: 40px" mode="horizontal" @select="handleSelect" background-color="#fff" text-color="#777A8A" active-text-color="#0286FE">
              <el-menu-item v-for="(item, index) in titleList" :index="item.id" :key="index" style="position: relative">
                <a @click.prevent="DDDD" :href="'/#' + item.router" :class="{ setColor: index == 2 }" style="text-decoration: none; color: inherit">{{ item.title }}</a>
                <img class="hot" v-if="index == 6" :src="require('@/static/image/index/hot.png')" alt="" />
              </el-menu-item>
            </el-menu>
          </div>
        </el-col>
        <!--				<el-col :span="3">
					<div class="topRight">
						<div @click="openLink('/game/select/0')" class="rightItem1">我要买</div>
						<div @click="openLink('/game/add/add')" class="rightItem2">我要卖</div>
					</div>
				</el-col>-->
      </div>
    </el-row>
  </div>
</template>

<script>
  import { getToken } from '@/utils/auth.js'
  export default {
    name: 'HeadBar',
    activated() {
      let currentRoute = this.$router.currentRoute
      if (currentRoute.name == 'home') {
        this.defaultActive = '1'
      }
      this.token = getToken()
    },
    data() {
      return {
        defaultActive: '1',
        token: undefined,
        titleList: [
          {
            title: '首页',
            router: '/home',
            id: '1',
          },
          {
            title: '个人中心',
            router: '/mineIndex/1',
            id: '2',
          },
          {
            title: '订单聊天',
            router: '/chat/0',
            id: '3',
          },
          {
            title: '我的订单',
            router: '/mineIndex/13',
            id: '4',
          },
          {
            title: '买家中心',
            router: '/mineIndex/8',
            id: '5',
          },
          {
            title: '卖家中心',
            router: '/mineIndex/12',
            id: '6',
          },
          // {
          // 	title: '账户设置',
          // 	id: 7,
          // 	router: '/setting/1',
          // },
        ],
      }
    },
    created() {
      this.token = getToken()

      let currentRoute = this.$router.currentRoute
      if (currentRoute.path == 'home') {
        this.defaultActive = '1'
      }
      if (currentRoute.path == '/mineIndex/1') {
        this.defaultActive = '2'
      }
      if (currentRoute.name == 'chat') {
        this.defaultActive = '3'
      }
      if (currentRoute.path == '/mineIndex/13') {
        this.defaultActive = '4'
      }
      if (currentRoute.path == '/mineIndex/8') {
        this.defaultActive = '5'
      }
      if (currentRoute.path == '/mineIndex/12') {
        this.defaultActive = '6'
      }
      // if (currentRoute.name == 'setting') {
      // 	this.defaultActive = '7'
      // }

      if (currentRoute.name == 'serviceCenter') {
        this.defaultActive = '8'
      }
      if (currentRoute.name == 'gameLeveling') {
        this.defaultActive = '7'
      }
    },
    // mounted(){
    // 		let currentRoute = this.$router.currentRoute;
    // 		console.log(currentRoute)
    // },
    methods: {
      DDDD() {
        return false
      },
      isLogin() {
        this.token = getToken()
      },
      // 微信登录
      weixinLogin() {
        // let url = 'http://'+window.location.host+ '/#/login/login'
        let redirect_uri = encodeURIComponent(window.location.origin + '/#/login/login')
        let appid = 'wx34ce80f4fc983974'
        window.location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_login&state=123#wechat_redirect`
      },
      handleSelect(key, keyPath) {
        let currentRoute = this.$router.currentRoute
        if (currentRoute.path != this.titleList[key - 1].router) {
          this.$router.push(this.titleList[key - 1].router)
        }
        this.defaultActive = key
      },
      openLink(router) {
        window.open(
          this.$router.resolve({
            path: router,
          }).href,
          '_blank'
        )
      },
      loginOut() {
        this.$store.dispatch('loginOut')
        setTimeout(() => {
          this.$router.push('/login/login')
        }, 1000)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .grid-content {
    background-color: #fff;
  }

  ::v-deep .el-menu.el-menu--horizontal {
    border: none;
  }

  ::v-deep .el-dropdown-menu__item,
  .el-menu-item {
    font-family: 'PingFangSC';
    /* 使用你定义的字体 */
    font-size: 14px;
    padding: 0 10px;
  }

  ::v-deep .el-header {
    padding: 0;
  }

  ::v-deep .el-menu--horizontal > .el-menu-item.is-active {
    border-bottom: 4px solid #409eff;
  }

  .topRight {
    color: #fff;
    display: flex;
    // height: 100%;
    // line-height: 100%;
    justify-content: flex-end;
    background-color: #fff;
  }

  .rightItem {
    margin-right: 20px;
    font-size: 14px;
    width: 100px;
    cursor: pointer;
  }
  .headerBox {
    justify-content: space-between;
    height: 40px;
  }
  .rightItem1 {
    width: 72px;
    height: 32px;
    background: #0085ff;
    border-radius: 6px 6px 6px 6px;
    cursor: pointer;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    line-height: 32px;
  }

  .rightItem2 {
    width: 72px;
    height: 32px;
    border: 1px solid #0085ff;
    border-radius: 6px 6px 6px 6px;
    cursor: pointer;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: #0085ff;
    line-height: 32px;
    margin-left: 16px;
  }

  .noticeTitle {
    height: 32px;
    font-weight: 600;
    font-size: 22px;
    color: #1d223c;
    padding: 16px;
    border-bottom: 1px solid #ececec;
  }

  .noticeItem {
    width: 362px;
    font-weight: 400;
    font-size: 20px;
    color: #1d223c;
    padding-top: 16px;
    padding-left: 16px;
  }

  .setColor {
    color: #ff5c00;
  }

  .hot {
    // background-color:#FF5C00;
    // color: #fff;
    // border-radius: 5px;
    position: absolute;
    width: 30px;
    height: 30px;
    top: 2px;
    // right:43px;
    right: -5px;
    text-align: center;
    font-size: 12px;
  }

  .hot::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid #ff5c00;
    position: absolute;
    /* 根据需要调整位置 */
    top: 15px;
    /* 示例，调整位置 */
    left: 8px;
    /* 示例，调整位置 */
    // background-color:#FF5C00;
  }

  .leftText {
    font-weight: 400;
    font-size: 12px;
    color: #a5a7b1;
    margin-right: 20px;
  }

  .loginType {
    width: 77px;
    height: 20px;
    font-size: 12px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    justify-content: center;
    margin-left: 8px;
  }

  ::v-deep .el-menu-item {
    font-size: 14px;
  }

  ::v-deep .el-menu {
    height: 40px; /* 设置菜单高度 */
  }

  ::v-deep .el-menu-item {
    line-height: 40px; /* 设置每个菜单项的垂直居中 */
    height: 40px; /* 设置菜单高度 */
  }

  ::v-deep is-active {
    height: 40px; /* 设置菜单高度 */
  }
</style>
