import Vue from 'vue'
// import { conn } from '@/assets/utils/WebIM/initWeb.js';

import store from '@/store/index.js'
import VueRouter from 'vue-router'
// import { getToken } from '@/utils/auth.js'
import HomeView from '@/views/home.vue'
import login from '@/views/login/login.vue'
import game from '@/views/game.vue'
import gameList from '@/views/gameList.vue'
import gameDetaile from '@/views/gameDetaile.vue'
import confirmOrder from '@/views/confirmOrder.vue'
import orderPay from '@/views/orderPay.vue'
// import more from '@/views/more.vue'
import shopDetaile from '@/views/shopDetaile.vue'
import publish1 from '@/views/publish1.vue'
import publish2 from '@/views/publish2.vue'
import publishSuccess from '@/views/publishSuccess.vue'
import shoppingCart from '@/views/shoppingCart.vue'
import collect from '@/views/collect.vue'
import orderDetail from '@/views/orderDetail.vue'

import footprint from '@/views/footprint.vue'

import chat from '@/views/chat.vue' //聊天
import mineIndex from '@/views/mine/index.vue' //个人中心
import serviceCenter from '@/views/serviceCenter.vue' //个人中心
// import aboutUs from '@/views/aboutUs.vue' //个人中心
// import setting from '@/views/setting/index.vue' //账户设置
//
// import accountReclamation from '@/views/accountReclamation.vue' //回收
// import gameLeveling from '@/views/gameLeveling.vue' //代练
import assureCom from '@/views/assure/index.vue'
import Layout from '@/layout/index.vue'
import realNameResult from '@/views/realNameResult.vue' //人脸检测结果

let titleInterval = null
let _record = 0

// 处理重复路由
// 解决跳转重复路由报错问题的代码
const routerPush = VueRouter.prototype.replace
VueRouter.prototype.replace = function (location) {
  return routerPush.call(this, location).catch((err) => {})
}

Vue.use(VueRouter)

const routes = [
  {
    title: '派派代售-登录',
    name: 'login',
    path: '/login/:type',
    component: login, //指定使用Layout组件布局
    meta: {
      title: '派派代售-登录',

      content: '游戏交易平台-登录',
    },
  },
  {
    path: '/',
    name: 'homePage',
    component: Layout, //指定使用Layout组件布局
    redirect: '/home', //重定向至/home页面
    children: [
      {
        //子菜单信息
        path: '/home', //路径
        name: 'home',
        component: HomeView, //指定组件
        meta: {
          title: '派派代售-首页',
          icon: 'el-icon-location',
          keepAlive: true, //需要被缓存
          content: '游戏交易平台',
        },
      },
      {
        path: '/game/:type/:id',
        name: 'game',
        component: game, //指定使用Layout组件布局
        // redirect: '/game', //重定向至/home页面
        // children: [
        //   {
        //     //子菜单信息
        //     path: '/game/:type/:id', //路径
        //     name: 'game',
        //     component: game,
        //     // meta: {
        //     //   keepAlive: true, //需要被缓存
        //     // },
        //   },
        // ],
      },
      {
        path: '/gameList',
        component: gameList, //指定使用Layout组件布局
        name: 'gameList',
        redirect: '/gameList', //重定向至/home页面
        children: [
          {
            //子菜单信息
            path: '/gameList', //路径
            name: '/gameList',
            component: gameList,
            meta: {
              keepAlive: false, //需要被缓存
              isBack: true,
            },
            props: (route) => ({
              info: route.query.info,
            }),
          },
        ],
      },
      {
        //子菜单信息
        path: '/gameDetaile/:id', //路径
        name: 'gameDetaile',
        component: gameDetaile,
        meta: {
          keepAlive: false, //需要被缓存
          isBack: true,
        },
        // props: route => ({
        // 	id: route.query.id
        // }),
      },
      {
        path: '/confirmOrder',
        component: confirmOrder, //指定使用Layout组件布局
        redirect: '/confirmOrder', //重定向至/home页面
        children: [
          {
            //子菜单信息
            path: '/confirmOrder', //路径
            name: 'confirmOrder',
            component: confirmOrder,
            props: (route) => ({
              info: route.query.info,
            }),
          },
        ],
      },
      {
        path: '/orderPay',
        component: orderPay, //指定使用Layout组件布局
        redirect: '/orderPay', //重定向至/home页面
        children: [
          {
            //子菜单信息
            path: '/orderPay', //路径
            name: 'orderPay',
            component: orderPay,
            props: (route) => ({
              info: route.query.info,
            }),
          },
        ],
      },
      // {
      //   //子菜单信息
      //   path: '/accountReclamation/:type', //路径
      //   name: 'accountReclamation',
      //   component: accountReclamation,
      // },
      // {
      //   //子菜单信息
      //   path: '/more', //路径
      //   name: 'more',
      //   component: more,
      // },
      {
        path: '/shopDetaile/:userId/:id',
        component: shopDetaile, //指定使用Layout组件布局
        redirect: '/shopDetaile', //重定向至/home页面
        children: [
          {
            //子菜单信息
            path: '/shopDetaile/:userId/:id', //路径
            name: 'shopDetaile',
            component: shopDetaile,
          },
        ],
      },
      {
        path: '/publish1',
        component: publish1, //指定使用Layout组件布局
        redirect: '/publish1', //重定向至/home页面
        children: [
          {
            //子菜单信息
            path: '/publish1', //路径
            name: 'publish1',
            component: publish1,
            props: (route) => ({
              info: route.query.info,
            }),
          },
        ],
      },
      {
        path: '/publish2',
        component: publish2, //指定使用Layout组件布局
        redirect: '/publish2', //重定向至/home页面
        children: [
          {
            //子菜单信息
            path: '/publish2', //路径
            name: 'publish2',
            component: publish2,
            props: (route) => ({
              info: route.query.info,
            }),
          },
        ],
      },
      {
        path: '/publishSuccess',
        component: publishSuccess, //指定使用Layout组件布局
        redirect: '/publishSuccess', //重定向至/home页面
        children: [
          {
            //子菜单信息
            path: '/publishSuccess', //路径
            name: 'publishSuccess',
            component: publishSuccess,
            props: (route) => ({
              info: route.query.info,
            }),
          },
        ],
      },
      {
        path: '/shoppingCart',
        name: 'shoppingCart',
        component: shoppingCart, //指定使用Layout组件布局
      },
      {
        path: '/collect',
        name: 'collect',
        component: collect, //指定使用Layout组件布局
      },
      {
        path: '/footprint',
        name: 'footprint',
        component: footprint, //指定使用Layout组件布局
      },
      {
        path: '/mineIndex/:id',
        name: 'mineIndex',
        component: mineIndex, //指定使用Layout组件布局
      },
      {
        path: '/orderDetail/:id/:goodsId',
        name: 'orderDetails',
        component: orderDetail, //指定使用Layout组件布局
      },
      {
        path: '/serviceCenter/:index/:id',
        name: 'serviceCenter',
        component: serviceCenter, //服务中心
      },
      // {
      //   path: '/aboutUs',
      //   name: 'aboutUs',
      //   component: aboutUs, //服务中心
      // },
      // {
      //   path: '/gameLeveling',
      //   name: 'gameLeveling',
      //   component: gameLeveling, //服务中心
      //   keepAlive: true, //需要被缓存
      // },
      // {
      //   path: '/setting/:index',
      //   name: 'setting',
      //   component: setting, //账户设置
      // },
      {
        path: '/assure',
        name: 'assure',
        component: assureCom, //账户设置
      },
    ],
  },
  {
    path: '/realNameResult/:userId',
    name: 'realNameResult',
    component: realNameResult, //指定使用Layout组件布局
  },
  {
    path: '/chat/:id',
    name: 'chat',
    component: chat, //指定使用Layout组件布局
  },

  // {
  //   path: '/gameDetaile/:id',
  //   component: Layout, //指定使用Layout组件布局
  //   redirect: '/gameDetaile', //重定向至/home页面
  //   children: [
  //     {
  //       //子菜单信息
  //       path: '/gameDetaile/:id', //路径
  //       name: 'gameDetaile',
  //       component: gameDetaile,
  //       meta: {
  //         keepAlive: false, //需要被缓存
  //         isBack: true,
  //       },
  //       // props: route => ({
  //       // 	id: route.query.id
  //       // }),
  //     },
  //   ],
  // },

  // {
  //   path: '/accountReclamation/:type',
  //   component: Layout, //指定使用Layout组件布局
  //   redirect: '/accountReclamation', //重定向至/home页面
  //   children: [
  //     {
  //       //子菜单信息
  //       path: '/accountReclamation/:type', //路径
  //       name: 'accountReclamation',
  //       component: accountReclamation,
  //     },
  //   ],
  // },
  // {
  //   path: '/more',
  //   component: Layout, //指定使用Layout组件布局
  //   redirect: '/more', //重定向至/home页面
  //   children: [
  //     {
  //       //子菜单信息
  //       path: '/more', //路径
  //       name: 'more',
  //       component: more,
  //     },
  //   ],
  // },
]

const router = new VueRouter({
  // mode:'history',
  routes,
})

const loginPage = ['mineIndex', 'collect', 'footprint', 'chat']
// 全局前置守卫，用于路由拦截
router.beforeEach((to, from, next) => {
  if (loginPage.includes(to.name) && !store.state.token) {
    Vue.prototype.message('请先去登录!', 'error')
    next('/login/login')
    return
  }

  if (to.name == 'chat') {
    clearInterval(titleInterval)
    titleInterval = null
    document.title = '派派代售网络游戏服务网'
    // conn.close();
  }
  // console.log(to)
  // if(to.path == '/login'){
  // 	next(); // 继续导航
  // 	return
  // }
  // // 例如，检查用户是否登录，如果没有，则可以阻止导航，或者重定向到登录页面
  // if (getToken() == 'undefined' ||  getToken() == undefined ) {
  // 	next('/login'); // 重定向到登录页面
  // } else {
  // 	next(); // 继续导航
  // }
  next()
})

function titleBlink() {
  _record++
  if (_record == 3) {
    //当变量_record累加到3是，将其赋值为1。相当于无限循环。
    _record = 1
  }
  if (_record == 1) {
    document.title = '【您有新的消息请点击订单聊天查看】'
  }
  if (_record == 2) {
    document.title = '派派代售网络游戏服务网'
  }
  titleInterval = setTimeout(() => {
    titleBlink()
  }, 500) //调节时间，单位毫秒。
}
function isEmpty(obj, defaultValue) {
  if (obj === undefined || obj === null || obj === 'setEmptyJly' || obj === 'undefined' || obj === 'null' || obj === '' || obj === '&nbsp;' || obj.length === 0 || obj === false || Object.keys(obj).length == 0) {
    if (defaultValue !== undefined && defaultValue !== null) {
      return defaultValue
    } else {
      return true
    }
  }
  if (defaultValue !== undefined && defaultValue !== null) {
    return obj
  } else {
    return false
  }
}

export default router
